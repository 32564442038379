@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');


* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}

/* Fade In Css */

.single-solution-container.appear-up {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-in-out;
}

.single-solution-container.appear-up.active {
    opacity: 1;
    transform: translateX(0px);
}

.single-solution-container.appear-down {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 1s ease-in-out;
}

.single-solution-container.appear-down.active {
    opacity: 1;
    transform: translate(0px);
}


.single-solution-container.appear-right {
    opacity: 0;
    transform: translateX(51px);
    transition: all 1s ease-in-out;
}

.single-solution-container.appear-right.active {
    opacity: 1;
    transform: translateX(0px);
}

.single-solution-container.appear-left {
    opacity: 0;
    transform: translateX(-107px);
    transition: all 1s ease-in-out;
}

.single-solution-container.appear-left.active {
    opacity: 1;
    transform: translateX(0px);
}

.single-solution-container.appear-left-hero {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.single-solution-container.appear-left-hero.active {
    opacity: 1;
    transform: translateX(0px);
}




/* Fade In Css End */



.single-solution-container {
    background: #ffffff;
    box-shadow: 0.1875em -0.25em 1.1875em rgba(0, 0, 0, 0.11), -0.1875em 0.25em 1.1875em rgba(0, 0, 0, 0.11);
    z-index: 1;
    position: relative;
    border-radius: 0.3em;
    margin-bottom: 1.5em;
    padding: 1em;
    font-family: 'Poppins', sans-serif;
}

.single-solution-container .top-solution .solution-title {
    font-size: 1rem;

}

.single-solution-container .top-solution .description{
    font-size: .65rem;
    letter-spacing: .01rem;
    line-height: 1.1rem;
    color: #4b4b4b;
    margin: .5rem 0 1.5rem 0;
}

.single-solution-container .view-btn {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.375em;
    border: 1.5px solid var(--secondary, #2E3092);
    color: var(--black);
    padding: 0.1em 1em;
    width: fit-content;
    cursor: pointer;
}


.single-solution-container .view-btn span {
    font-size: .75rem;
    font-weight: 500;
}

.single-solution-container .view-btn .long-arrow {
    width: .8em;
    transition: transform .5s ease-in-out;
}

.single-solution-container .view-btn:hover .long-arrow {
    transform: translateX(0.2em);
}


/* MEDIA QUERIES */

@media(min-width: 400px) {
    .single-solution-container .top-solution .solution-title {
        font-size: 1.2rem;
    }
    
    .single-solution-container .top-solution .description {
        font-size: .75rem;
        line-height: 1.4rem;
    } 
}


@media(min-width: 500px) {

    .single-solution-container {
        padding: 2em;
    }

}

@media(min-width: 600px) {
    .single-solution-container {
        padding: 1em;
    }
}


@media(min-width: 650px) {
    .single-solution-container .view-btn span {
        font-size: .9rem;
    }

    .single-solution-container .view-btn .long-arrow {
        width: 1em;
    }
}

@media(min-width: 700px) {

    .single-solution-container {
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .single-solution-container .view-btn span {
        font-size: .95rem;
    }

    .single-solution-container .view-btn .long-arrow {
        width: 1em;
    }

    
    .single-solution-container .top-solution .solution-title {
        font-size: 1.3rem;
        line-height: 1.3rem;
    }
    
}

@media(min-width: 900px) {
    
    .single-solution-container .top-solution .solution-title {
        font-size: 1.3rem;
    }

    .single-solution-container .top-solution .description {
        font-size: .8rem;
        line-height: 1.4rem;
        letter-spacing: .02rem;
        margin: .7rem 0 3rem 0;
    } 
}

@media(min-width: 950px) {
    .single-solution-container .view-btn {
        padding: 0.1em 1.3em;
    }
}

@media(min-width: 1050px) {
    .single-solution-container .view-btn {
        gap: .8em;
        border: 2px solid var(--secondary, #2E3092);
    }

    .single-solution-container .view-btn span {
        font-size: 1.1rem;
    }

    .single-solution-container .view-btn .long-arrow {
        width: 1.3em;
    }
}

@media(min-width: 1100px) {

    .single-solution-container {
        padding: 1.5em 1.9em;
    }

    .single-solution-container .top-solution .solution-title {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    .single-solution-container .top-solution .description {
        font-size: 1rem;
        line-height: 1.7rem;
    }
}


@media(min-width: 1150px) {
    .single-solution-container .view-btn {
        gap: .9em;
    }

    .single-solution-container .view-btn span {
        font-size: 1.3rem;
    }

    .single-solution-container .view-btn .long-arrow {
        width: 1.4em;
    }
}


@media(min-width: 1200px) {
    .single-solution-container .view-btn span {
        font-size: 1.1rem;
    }
}

@media(min-width: 1300px) {
    .single-solution-container .top-solution .solution-title {
        font-size: 1.8rem;
        line-height: 1.8rem;
    }

    .single-solution-container .top-solution .description {
        font-size: 1.15rem;
        line-height: 1.9rem;
        margin: 1rem 0 4rem 0;
    }

    .single-solution-container .view-btn span {
        font-size: 1.3rem;
    }
}

@media(min-width: 1350px) {
    .single-solution-container .view-btn {
        gap: 1.5em;
    }

    .single-solution-container .view-btn span {
        font-size: 1.3rem;
    } 

    .solutions-container .solutions-section .abstract {
        width: 20em;
    }

    .solutions-container .solutions-section .abstract.abstract2 {
        top: 36em;
    }
}


@media(min-width: 1451px) {
    .single-solution-container .top-solution .solution-title {
        font-size: 2rem;
        line-height: 2rem;
    }

    .single-solution-container .top-solution .description {
        font-size: 1.3rem;
            line-height: 2.2rem;
    }

    .solutions-container .solutions-section .abstract {
        width: 25em;
    }

    .solutions-container .solutions-section .abstract.abstract1 {
        top: 13.7em;
    }

    .solutions-container .solutions-section .abstract.abstract2 {
        top: 37.5em;
    }
}
/* MEDIA QUERIES END */