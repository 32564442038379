@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}


.nav-container {
    background-color: #ffffff;
    color: var(--black);
    padding: 2em 1em 0 1em;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    z-index: 11;
    transition: all 0.5s ease-in-out;
}

.nav-container.active {
    width: unset;
    top: 1em;
    left: 1em;
    right: 1em;
    border-radius: 2em;
    box-shadow: 0em 0.125em 1.1875em rgb(255 255 255 / 16%);
    transition: all 0.2s ease-in-out;
    border: 0.1em solid var(--primary);
    padding: 0.7em 0.5em;
    background: #ffffff;
}

.nav-container .ribbon-container {
    margin-bottom: .3em;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.nav-container.active .ribbon-container {
    display: none;
}

.nav-container .ribbon {
    background: var(--secondary);
    height: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
}

.nav-container .ribbon::after {
    content: '';
    position: absolute;
    top: 62%;
    height: 0.35em;
    width: 100%;
    background: #ffffff;
    border-radius: 0.5em 0.5em 0 0;
    display: none;
}

.nav-container .ribbon .ribbon-h1 {
    font-size: .7rem;
    color: #FFD233;
    text-shadow:
            -1px -1px 0 #000,
            0 -1px 0 #000,
            1px -1px 0 #000,
            1px 0 0 #000,
            1px 1px 0 #000,
            0 1px 0 #000,
            -1px 1px 0 #000,
            -1px 0 0 #000;
}

.nav-container .ribbon .ribbon-p {
    font-size: .6rem;
    color: #ffffff;
    display: none;
}

.nav-container .ribbon .ribbon-p span {
    font-weight: 700;
    color: #FFD233;
    text-shadow:
            -1px -1px 0 #000,
            0 -1px 0 #000,
            1px -1px 0 #000,
            1px 0 0 #000,
            1px 1px 0 #000,
            0 1px 0 #000,
            -1px 1px 0 #000,
            -1px 0 0 #000;
}

.nav-container .ribbon .button-div {
    display: flex;
    align-items: center;
    gap: .1em;
}

.nav-container .ribbon .button-div svg {
    color: #ffffff;
    font-size: 1.2rem;
}

.nav-container .ribbon .button-div .MuiButtonBase-root {
    font-size: .5rem;
    text-transform: capitalize;
    font-family: 'Montserrat', sans-serif;
    color: #ffffff;
    padding: 0.5em 0.3em;
}


.nav-container .top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-container .top-nav .soc-burger {
    display: flex;
    gap: 1em;
}

.nav-container .top-nav .logo {
    width: 4.9em;
    animation: logo-anim 0.9s forwards;
}

@keyframes logo-anim {
    from {
        transform: translateX(-1em);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.nav-container .top-nav .tools {
    display: none;
}

.nav-container .top-nav .burger {
    width: 2em;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
    animation: burger-anim 0.9s forwards;
    color: var(--black);
}

.nav-container .top-nav .soc-btn {
    display: inline-flex;
    padding: 0.22em 0.6875em;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 2.0625em;
    border: 0.125em solid var(--primary, #27AAE1);
    background: #ffffff;
    animation: soc-anim 0.9s forwards;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.nav-container .top-nav .soc-btn img {
    width: 1.3em;
    transition: all 0.5s ease-in-out;
    -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
    0% { -webkit-transform: rotateZ(0); }
    1% { -webkit-transform: rotateZ(30deg); }
    3% { -webkit-transform: rotateZ(-28deg); }
    5% { -webkit-transform: rotateZ(34deg); }
    7% { -webkit-transform: rotateZ(-32deg); }
    9% { -webkit-transform: rotateZ(30deg); }
    11% { -webkit-transform: rotateZ(-28deg); }
    13% { -webkit-transform: rotateZ(26deg); }
    15% { -webkit-transform: rotateZ(-24deg); }
    17% { -webkit-transform: rotateZ(22deg); }
    19% { -webkit-transform: rotateZ(-20deg); }
    21% { -webkit-transform: rotateZ(18deg); }
    23% { -webkit-transform: rotateZ(-16deg); }
    25% { -webkit-transform: rotateZ(14deg); }
    27% { -webkit-transform: rotateZ(-12deg); }
    29% { -webkit-transform: rotateZ(10deg); }
    31% { -webkit-transform: rotateZ(-8deg); }
    33% { -webkit-transform: rotateZ(6deg); }
    35% { -webkit-transform: rotateZ(-4deg); }
    37% { -webkit-transform: rotateZ(2deg); }
    39% { -webkit-transform: rotateZ(-1deg); }
    41% { -webkit-transform: rotateZ(1deg); }
  
    43% { -webkit-transform: rotateZ(0); }
    100% { -webkit-transform: rotateZ(0); }
  }
  
  @-moz-keyframes ring {
    0% { -moz-transform: rotate(0); }
    1% { -moz-transform: rotate(30deg); }
    3% { -moz-transform: rotate(-28deg); }
    5% { -moz-transform: rotate(34deg); }
    7% { -moz-transform: rotate(-32deg); }
    9% { -moz-transform: rotate(30deg); }
    11% { -moz-transform: rotate(-28deg); }
    13% { -moz-transform: rotate(26deg); }
    15% { -moz-transform: rotate(-24deg); }
    17% { -moz-transform: rotate(22deg); }
    19% { -moz-transform: rotate(-20deg); }
    21% { -moz-transform: rotate(18deg); }
    23% { -moz-transform: rotate(-16deg); }
    25% { -moz-transform: rotate(14deg); }
    27% { -moz-transform: rotate(-12deg); }
    29% { -moz-transform: rotate(10deg); }
    31% { -moz-transform: rotate(-8deg); }
    33% { -moz-transform: rotate(6deg); }
    35% { -moz-transform: rotate(-4deg); }
    37% { -moz-transform: rotate(2deg); }
    39% { -moz-transform: rotate(-1deg); }
    41% { -moz-transform: rotate(1deg); }
  
    43% { -moz-transform: rotate(0); }
    100% { -moz-transform: rotate(0); }
  }
  
  @keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }
  
    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
  }


.nav-container .top-nav .soc-btn:hover img {
    transform: scale(1.1);
}

.nav-container .top-nav .soc-btn span {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.5rem;
    transition: all 0.5s ease-in-out;
    font-weight: 700;
}

.nav-container .top-nav .soc-btn:hover span {
    color: var(--primary);
}


@keyframes burger-anim {
    from {
        transform: translateX(1.5em);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes soc-anim {
    from {
        transform: translateY(1.5em);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}



.nav-container .top-nav .burger.burger-toggle {
    color: var(--primary);
}


.nav-container .top-nav .top-nav-ul {
    display: none;
}

.nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper {
    display: none;
}


.nav-container .top-nav .quote .css-109ph1f-MuiButtonBase-root-MuiButton-root {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    padding: 0.175rem 0.5rem;
    font-size: 0.625rem;
    font-weight: 700;
    text-transform: capitalize;
    box-shadow: none;
    transition: all 0.5s ease-in-out;
}

.nav-container .top-nav .quote .css-109ph1f-MuiButtonBase-root-MuiButton-root:hover {
    background: var(--secondary);
    transform: scale(0.95);
}


.nav-container ul {
    list-style-type: none;
}

.nav-container .ul-div {
    height: 0em;
}

.nav-container .ul-div .ul-div-container {
    display: none;
    height: 100%;
}

.nav-container .ul-div.active-ul-div {
    height: fit-content;
    max-height: 90vh;
    overflow-y: auto;
    transition: all 0.5s ease-in-out;
    background: #33333391;
    border-radius: 0.625em 0.625em;
    margin-top: 0.5em;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    backdrop-filter: blur(.5em);
    border: 0.5px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgb(8 7 16 / 60%);
}

.nav-container .ul-div .ul-div-container.active-ul-div-container {
    display: flex;
    align-items: center;
    padding: 0em 1em;
}

.nav-container .ul-div ul.active-nav-ul {
    transform: translateX(0%);
    z-index: 800;
    display: block;
    width: 100%;
}

@keyframes nav-link-fade {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}


.nav-container .ul-div .ul-div-container ul li {
    margin: 1em 0em;
    text-align: left;
    width: 100%;
    border-bottom: .1px solid #ffffff4a;
    padding-bottom: 0.2em;
    cursor: pointer;
}

.nav-container .ul-div .ul-div-container ul li svg {
    width: 1.5em;
    height: fit-content;
}

.nav-container .ul-div .ul-div-container ul li .white-arrow-right {
    width: 0.9em;
    margin-right: 0.3em;
}

.nav-container .ul-div .ul-div-container ul li.arrowed-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0;
}


.nav-container .ul-div .ul-div-container ul li.courses-li {
    display: block;
}

.nav-container .ul-div .ul-div-container ul li.courses-li .courses-dropdown-container {
    height: 0;
    transition: all 0.5s ease-in-out;
}

.nav-container .ul-div .ul-div-container ul li.courses-li .courses-dropdown-container .courses-dropdown {
    display: none;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.nav-container .ul-div .ul-div-container ul li.courses-li .courses-dropdown-container.active {
    height: 100%;
}

.nav-container .ul-div .ul-div-container ul li.courses-li .courses-dropdown-container.active .courses-dropdown {
    display: block;
    opacity: 1;
}

.nav-container .ul-div .ul-div-container ul li.courses-li .courses-dropdown-container.active .courses-dropdown li {
    margin: .7rem 0;
    font-size: .7rem;
    font-weight: 100;
    color: navajowhite;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-container .ul-div .ul-div-container ul li.courses-li .courses-dropdown-container.active .courses-dropdown li .arrow-right {
    width: 1.1em;
    margin-right: 0.4em;
}

.nav-container .ul-div .ul-div-container ul li.courses-li .courses-dropdown-container.active .courses-dropdown li .arrow-right-circle {
    width: 1.8em;
}



.nav-container .ul-div .ul-div-container ul li.courses-li .courses-li-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-container .ul-div .ul-div-container ul li:last-child {
    margin: 1em 0em 1.5em 0em;
}

.nav-container .ul-div .ul-div-container ul a {
    font-size: .75rem;
    text-decoration: none;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    letter-spacing: 0.02rem;
}

.nav-container .ul-div .ul-div-container ul li img {
    width: 1.3em;
}

@media(min-width: 400px) {
    .nav-container {
        padding: 2em 1.5em 0.5em 1.5em;
    }

    .nav-container.active {
        padding: 1em 1.4em 1em 1.4em;
    }

    .nav-container .ribbon .ribbon-h1 {
        font-size: .9rem;
    }

    .nav-container .ribbon .button-div svg {
        font-size: 1.5rem;
    }

    .nav-container .ribbon .button-div .MuiButtonBase-root {
        font-size: .6rem;
        padding: .5em;
    }

    .nav-container .top-nav .logo {
        width: 5.5em;
    }

    .nav-container .top-nav .soc-burger {
        gap: 1.5em;
    }
}

@media(min-width: 450px) {
    .nav-container .top-nav .quote .css-109ph1f-MuiButtonBase-root-MuiButton-root {
        padding: 0.2rem 0.8rem;
    }

    .nav-container .top-nav .logo {
        width: 6.5em;
    }

    .nav-container .ul-div .ul-div-container ul li svg {
        width: 1.7em;
    }
}


@media(min-width: 500px) {
    .nav-container .ribbon .ribbon-h1 {
        font-size: 1rem;
    }
}

@media(min-width: 550px) {
    .nav-container .ribbon .button-div .MuiButtonBase-root {
        font-size: .7rem;
        padding: 0.3em 0.7em;
    }
}

@media(min-width: 600px) {

    .nav-container {
        padding: 2em 1.875em 0.5em 1.875em;
    }

    .nav-container .ribbon .ribbon-h1 {
        font-size: 1.1rem;
        text-shadow:
            -1px -1px 0 #000,
            0 -1px 0 #000,
            1px -1px 0 #000,
            1px 0 0 #000,
            1px 1px 0 #000,
            0 1px 0 #000,
            -1px 1px 0 #000,
            -1px 0 0 #000;
    }


    .nav-container.active {
        border-radius: 3em;
        padding: 1em 1.0em 1em 1.0em;
    }

    .nav-container .top-nav .soc-btn span {
        font-size: .7rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul .main-li:hover .main-a {
        opacity: 0;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container.active .dropdown-wrapper .main-ul .main-li {
        transition: justify-content .5s ease-in;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container.active .dropdown-wrapper .main-ul .main-li:hover {
        background: var(--secondary);
        border-radius: .5em 0 0 .5em;
        display: flex;
        justify-content: center;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container.active .dropdown-wrapper .main-ul .main-li:hover {
        border-radius: .5em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container.active .dropdown-wrapper .main-ul .main-li:hover .main-a {
        opacity: 1;
        color: #ffffff;
    }
}

@media(min-width: 700px) {

    .nav-container .ribbon::after {
        border-radius: 1em 1em 0 0;
    }

    .nav-container .ribbon .ribbon-p {
        display: unset;
        font-size: .7rem;
    }

    .nav-container .ul-div.active-ul-div {
        display: none;
    }

    .nav-container.active {
        padding: 1.2em 0.8em 1.2em 0.8em;
    }

    .nav-container .ul-div .ul-div-container {
        display: none;
    }

    .nav-container .top-nav .logo {
        margin-top: 0.3em;
    }



    .nav-container .top-nav .logo-ul {
        display: flex;
        align-items: center;
        gap: 1em;
    }

    .nav-container .top-nav .top-nav-ul {
        display: flex;
        transform: translateX(-4em);
        animation: topul-anim 0.9s forwards;
    }

    .nav-container .top-nav .top-nav-ul li .dropdown-container .dropdown-wrapper {
        display: none;
    }

    .nav-container .top-nav .top-nav-ul li .dropdown-container.active .dropdown-wrapper {
        display: block;
        opacity: 1;

    }

    @keyframes topul-anim {
        from {
            transform: translateY(-1.5em);
            opacity: 0;
        }

        to {
            transform: translateY(0);
            opacity: 1;
        }
    }


    .nav-container .top-nav .top-nav-ul li {
        padding-right: .7em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li {
        position: relative;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container {
        position: absolute;
        top: 1.55em;
        background: #ffffff;
        color: #000;
        border-radius: 0.5em;
        height: 0vh;
        opacity: 0;
        transition: all .5s ease-in-out;
        box-shadow: -3px 4px 19px 0px rgba(0, 0, 0, 0.11), 3px -4px 19px 0px rgba(0, 0, 0, 0.11);
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container {
        left: -3em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.trainings-li .dropdown-container {
        left: 50%;
        transform: translateX(-50%);
    }


    .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container.active {
        opacity: 1;
        height: 85vh;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.trainings-li .dropdown-container.active {
        display: block;
        opacity: 1;
        height: 75vh;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper {
        display: none;
        position: relative;
        height: 100%;
        transition: all 1s ease-in-out;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container.active .dropdown-wrapper {
        display: block;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 0.23em;
        background: var(--secondary);
        border-radius: 0.5em 0.5em 0 0;
        z-index: 0;
        opacity: 0;
        transition: all .3s ease-in-out;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 0.23em;
        background: var(--secondary);
        border-radius: 0 0 0.5em 0.5em;
        z-index: 0;
        opacity: 0;
        transition: all .3s ease-in-out;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container.active .dropdown-wrapper::before {
        opacity: 1;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container.active .dropdown-wrapper::after {
        opacity: 1;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container .dropdown-wrapper {
        padding: 0.8em 1em 0.8em 1em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.trainings-li .dropdown-container .dropdown-wrapper {
        padding: 0.8em 0em 0.8em 1em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul {
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: all 1s ease-in-out;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container .dropdown-wrapper .main-ul {
        width: 17em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.trainings-li .dropdown-container .dropdown-wrapper .main-ul {
        width: 11em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul .main-li:hover .main-a {
        margin-left: 0.4em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li a.long-a:hover {
        margin-left: 0;
        text-align: center;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li a::before {
        transition: all 0.5s ease-in-out;
        width: 0;
        height: 0;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul .main-li .sub-dropdown ul li a::before {
        transition: all 0.5s ease-in-out;
        width: 0;
        height: 0;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul .main-li .sub-dropdown ul li:hover a::before {
        content: '';
        transition: width 0.2s ease-in-out;
        width: 1em;
        height: 0.25em;
        border-radius: 2em;
        display: block;
        position: absolute;
        left: -1.2em;
        top: 50%;
        transform: translateY(-50%);
        background: #ffffff;
    }


    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li a::after {
        display: none;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li {
        margin: auto 0;
        padding-right: 0;
        cursor: pointer;
        font-size: 0.75rem;
        letter-spacing: 0.01rem;
        padding: .5em;
    }



    .nav-container .top-nav .top-nav-ul li.courses-li.trainings-li .dropdown-container .dropdown-wrapper .main-ul li {
        margin: 0;
        height: calc(100% / 12);
        display: flex;
        align-items: center;
        position: relative;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li a {
        font-weight: 500;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown {
        display: none;
        position: absolute;
        top: 0em;
        bottom: 0;
        left: 100%;
        background: var(--secondary);
        border-radius: 0 0.5em 0.5em 0;
        width: 0em;
        height: 0;
        transition: all 1s ease-in-out;
        z-index: 1;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li.li-3 .sub-dropdown {
        top: -5em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li.li-9 .sub-dropdown {
        top: -6em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li.li-12 .sub-dropdown {
        top: -9em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown.active {
        display: block;
        transition: all 1s ease-in-out;
        width: 17em;
        height: fit-content;

    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown .sub-ul {
        padding: 0.5em 1em;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown ul li {
        margin: auto 0;
        height: unset;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown .sub-ul li a {
        color: #ffffff;
        font-size: .61rem;
        display: block;
        transition: all 0.5s ease-in-out;
        letter-spacing: 0.01rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown .sub-ul li:hover a {
        margin-left: 0.4em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown .sub-ul li a:hover::before {
        background: #ffffff;
    }

    .nav-container .top-nav .top-nav-ul li a {
        text-decoration: none;
        color: var(--black);
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 0.68rem;
        position: relative;
        transition: margin-left 0.3s ease-in-out;
    }

    .nav-container .top-nav .top-nav-ul li a.major-a {
        font-size: .6rem;
    }

    .nav-container .top-nav .soc-btn span {
        font-size: 0.7rem;
    }

    .nav-container .top-nav .soc-btn img {
        width: 1.5em;
    }

    .nav-container .top-nav .soc-btn {
        padding: 0.22em 1em;
    }

    .nav-container .top-nav .top-nav-ul li a::after {
        content: '';
        position: absolute;
        bottom: -0.3em;
        left: 50%;
        transform: translateX(-50%);
        height: 0.2em;
        background: var(--secondary);
        transition: all 0.3s ease-in-out;
        width: 0%;
        border-radius: 1em;
    }

    .nav-container .top-nav .top-nav-ul li a:hover::after {
        width: 100%;
    }

    .nav-container .top-nav .burger {
        display: none;
    }

    .nav-container .top-nav .tools {
        display: flex;
        gap: 1em;
        background: #333333;
        padding: 0.3em 1em;
        align-items: center;
        border-radius: 0.7em;
    }

    .nav-container .top-nav .tools svg {
        width: 1.5em;
        height: fit-content;
    }

    .nav-container .top-nav .tools svg.heart {
        width: 1.4em;
    }

    .nav-container .top-nav .tools .cart-a {
        position: relative;
    }

    .nav-container .top-nav .tools .wishlist-a {
        position: relative;
    }

    .nav-container .top-nav .tools .profile-a {
        position: relative;
    }




    .nav-container .top-nav .tools .cart-dropdown-container {
        position: absolute;
        top: 1.7em;
        left: -4em;
        background: #ffffff;
        padding: 0.5em;
        width: fit-content;
        border: 1px solid #ddd;
        border-radius: 0.5em;
        display: none;
    }

    .nav-container .top-nav .tools .cart-dropdown-container.active {
        display: block;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .cart-row {
        display: flex;
        gap: 0.5em;
        padding: 0.5em 0;
        border-bottom: 1px solid #ddd;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .cart-row .img-container {
        width: 3em;
        max-height: 3.5em;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .cart-row .img-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .cart-row .cart-row-right {
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .cart-row .cart-row-right h4 {
        font-size: 0.65rem;
        line-height: 0.7rem;
        color: #333;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .cart-row .cart-row-right span {
        font-size: 0.65rem;
        color: #333;
        font-weight: 500;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 0;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .total p {
        color: #333;
        font-size: 0.8rem;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .total .total-price {
        font-weight: bold;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .css-1kx6okn-MuiButtonBase-root-MuiButton-root {
        font-size: 0.65rem;
        padding: 0.3rem 0.3125rem;
        width: 100%;
        font-weight: bold;
        border: 2px solid rgba(94, 165, 29, 0.5);
    }


    .nav-container .top-nav .tools .wishlist-dropdown-container {
        position: absolute;
        top: 1.7em;
        left: -5em;
        background: #ffffff;
        padding: 0.5em;
        width: fit-content;
        border: 1px solid #ddd;
        border-radius: 0.5em;
        width: 11em;
        display: none;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container.active {
        display: block;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .wishlist-row {
        display: flex;
        gap: 0.5em;
        padding: 0.5em 0;
        border-bottom: 1px solid #ddd;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .wishlist-row:last-child {
        border-bottom: none;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .wishlist-row .img-container {
        width: 4em;
        max-height: 4.5em;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .wishlist-row .img-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .wishlist-row .wishlist-row-right {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 0.3em;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .wishlist-row .wishlist-row-right h4 {
        font-size: 0.65rem;
        line-height: 0.7rem;
        color: #333;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .wishlist-row .wishlist-row-right span {
        font-size: 0.65rem;
        color: #333;
        font-weight: 500;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .wishlist-row .wishlist-row-right .css-1tt0sti-MuiButtonBase-root-MuiButton-root {
        font-size: 0.45rem;
        padding: 0.25rem;
        width: 6em;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 0;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .total p {
        color: #333;
        font-size: 0.8rem;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .total .total-price {
        font-weight: bold;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .css-1kx6okn-MuiButtonBase-root-MuiButton-root {
        font-size: 0.65rem;
        padding: 0.3rem 0.3125rem;
        width: 100%;
        font-weight: bold;
        border: 2px solid rgba(94, 165, 29, 0.5);
    }



    .nav-container .top-nav .tools .profile-dropdown-container {
        position: absolute;
        top: 1.7em;
        right: -1em;
        background: #ffffff;
        padding: 0.5em;
        border: 1px solid #ddd;
        border-radius: 0.5em;
        display: none;

    }

    .nav-container .top-nav .tools .profile-dropdown-container.active {
        display: block;
        min-width: 7em;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .top-profile {
        display: flex;
        align-items: center;
        gap: 0.5em;
        margin-bottom: 0.3em;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .top-profile .img-container {
        width: 3em;
        height: 3em;
        border-radius: 50%;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .top-profile .img-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .top-profile .top-profile-right .profile-name {
        color: #333;
        font-size: 0.8rem;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .top-profile .top-profile-right .profile-mail {
        color: #333;
        font-size: 0.7rem;
    }


    .nav-container .top-nav .tools .profile-dropdown-container .middle-ul {
        margin: 0.5em 0;
        display: flex;
        flex-direction: column;
        gap: 0.3em;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .middle-ul li a {
        text-decoration: none;
        color: #333;
        font-size: 0.8rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .middle-ul li a span.number-span {
        font-weight: bold;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .logout-p {
        color: #333;
        font-size: 0.8rem;
        margin: 0.5em 0;
    }

}

@media(min-width: 650px) {
    .nav-container .top-nav .tools .cart-dropdown-container {
        width: 10em;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .cart-row .img-container {
        width: 4em;
    }
}


@media(min-width: 700px) {
    .nav-container .top-nav .logo-ul {
        gap: 1.5em;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container {
        width: 12em;
        left: -7em;
    }
}


@media(min-width: 800px) {
    .nav-container {
        padding: 1.9em 3.125em 0.5em 3.125em;
    }

    .nav-container .ribbon .ribbon-h1 {
        font-size: 1.2rem;
    }

    .nav-container .ribbon .ribbon-p {
        font-size: .8rem;
    }

    .nav-container .ribbon .button-div svg {
        font-size: 1.7rem;
    }

    .nav-container .ribbon .button-div .MuiButtonBase-root {
        font-size: .8rem;
        padding: 0.1em 0.7em;
    }

    .nav-container .ribbon .button-div {
        gap: 0.3em;
    }

    

    .nav-container.active {
        padding: 1.2em 2em 1.5em 2em;
    }

    .nav-container .top-nav .logo {
        margin-top: 0.2em;
        width: 8.5em;
    }

    .nav-container .top-nav .logo-ul {
        gap: 2.5em;
    }

    .nav-container .top-nav .tools .cart-dropdown-container {
        width: 12em;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .wishlist-row .wishlist-row-right {
        width: 58%;
    }

}

@media(min-width: 850px) {
    .nav-container .top-nav .logo-ul {
        gap: 3em;
    }
}

@media(min-width: 900px) {

    .nav-container .top-nav .logo-ul {
        gap: 4.5em;
    }

    .nav-container .top-nav .tools {
        gap: 2em;
    }

    .nav-container .top-nav .top-nav-ul li {
        padding-right: .9em;
    }
}

@media(min-width: 950px) {

    .nav-container .top-nav .top-nav-ul li a.major-a {
        font-size: .7rem;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .cart-row .cart-row-right {
        width: 59%;
    }

    .nav-container .top-nav .soc-btn {
        padding: 0.4em 1.5em;
    }

    .nav-container .top-nav .soc-btn span {
        font-size: .7rem;
    }

    .nav-container .top-nav .soc-btn img {
        width: 1.8em;
    }
}


@media(min-width: 1000px) {
    .nav-container {
        padding: 1.7em 5em 0.5em 5em;
    }

    .nav-container .ribbon .ribbon-h1 {
        font-size: 1.5rem;
    }

    .nav-container .ribbon .ribbon-p {
        font-size: .95rem;
    }

    .nav-container .ribbon .button-div .MuiButtonBase-root {
        font-size: .9rem;
    }

    .nav-container .ribbon .button-div svg {
        font-size: 2rem;
    }

    .nav-container.active {
        padding: 1.2em 4em 1.5em 4em;
    }

    .nav-container .top-nav .top-nav-ul {
        transform: translateX(-6.5em);
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .main-a {
        font-size: .7rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container .dropdown-wrapper .main-ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 30em;
    }

    .nav-container .top-nav .top-nav-ul li.solutions-li .dropdown-container .dropdown-wrapper .main-ul li:last-child {
        grid-column: 1 /span 2;
        text-align: center;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container {
        left: -9em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown .sub-ul li a {
        font-size: .7rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container.active {
        height: 75vh;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.trainings-li .dropdown-container.active {
        height: 75vh;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li.li-12 .sub-dropdown {
        top: -10em;
    }
}

@media(min-width: 1050px) {

    .nav-container .top-nav .top-nav-ul li a.major-a {
        font-size: .75rem;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .wishlist-row .wishlist-row-right h4 {
        font-size: 0.7rem;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .wishlist-row .wishlist-row-right span {
        font-size: 0.7rem;
    }
}

@media(min-width: 1100px) {

    .nav-container .top-nav .logo {
        width: 9.5em;
        margin-top: 0;
    }

    .nav-container .top-nav .top-nav-ul li a {
        font-size: 0.75rem;
        letter-spacing: 0.02rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container .dropdown-wrapper .main-ul {
        width: 40em;
        gap: 1.2em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .main-a {
        font-size: 0.8rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown .sub-ul li a {
        font-size: .8rem;
    }

    /* .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container.active {
        height: 85vh;
    } */

    .nav-container .top-nav .soc-btn span {
        font-size: .85rem;
    }

    .nav-container .top-nav .soc-btn img {
        width: 1.7em;
    }

    .nav-container .top-nav .tools {
        gap: 3em;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .cart-row .cart-row-right h4 {
        font-size: 0.7rem;
    }

    .nav-container .top-nav .tools .cart-dropdown-container .cart-row .cart-row-right span {
        font-size: 0.7rem;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .top-profile .top-profile-right .profile-name {
        font-size: 0.9rem;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .top-profile .top-profile-right .profile-mail {
        font-size: 0.8rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li.li-12 .sub-dropdown {
        top: -10.5em;
    }
}

@media(min-width: 1150px) {
    .nav-container .top-nav .tools .wishlist-dropdown-container {
        width: 13em;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .wishlist-row .wishlist-row-right .css-1tt0sti-MuiButtonBase-root-MuiButton-root {
        font-size: 0.5rem;
        padding: 0.25rem 0.2rem;
        width: 10em;
    }

    .nav-container .top-nav .top-nav-ul li {
        padding-right: 1em;
    }

    .nav-container .top-nav .top-nav-ul li a {
        font-size: .85rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown.active {
        width: 24em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.trainings-li .dropdown-container .dropdown-wrapper .main-ul {
        width: 13em;
    }

    /* .nav-container .top-nav .top-nav-ul li.courses-li.trainings-li .dropdown-container.active {
        height: 82vh;
    } */
}

@media(min-width: 1200px) {
    .nav-container {
        padding: 1.5em 6.25em 0.5em 6.25em;
    }

    .nav-container .ribbon {
        padding: 0.5em 1.5em;
    }

    .nav-container .ribbon .ribbon-h1 {
        font-size: 1.8rem;
    }

    .nav-container .ribbon .ribbon-p {
        font-size: 1.2rem;
    }

    .nav-container .ribbon .button-div {
        gap: 0.6em;
    }

    .nav-container .ribbon .button-div .MuiButtonBase-root {
        font-size: 1.1rem;
    }

    .nav-container .ribbon .button-div svg {
        font-size: 2.5rem;
    }

    .nav-container.active {
        padding: 1.2em 4.8em 1.5em 4.8em;
    }

    .nav-container .top-nav .logo {
        width: 10em;
    }

    .nav-container .top-nav .quote .css-109ph1f-MuiButtonBase-root-MuiButton-root {
        padding: 0.4rem 1rem;
        font-size: 0.825rem;
    }

    .nav-container .top-nav .top-nav-ul {
        transform: translateX(-7.5em);
    }

    .nav-container .top-nav .top-nav-ul li a.major-a {
        font-size: .9rem;
    }

    .nav-container .top-nav .tools .cart-dropdown-container {
        left: -5.5em;
    }

}

@media(min-width: 1250px) {
    .nav-container .top-nav .top-nav-ul li a {
        font-size: 1rem;
    }
}

@media(min-width: 1300px) {

    .nav-container .top-nav .top-nav-ul li a.major-a {
        font-size: .9rem;
    }

    .nav-container .top-nav .soc-btn {
        padding: 0.6em 2em;
    }

    .nav-container .top-nav .soc-btn span {
        font-size: 1rem;
    }

    .nav-container .top-nav .soc-btn img {
        width: 2.1em;
    }
}

@media(min-width: 1350px) {
    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .main-a {
        font-size: 1rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.trainings-li .dropdown-container .dropdown-wrapper {
        padding: 1em 0em 1em 1.5em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown .sub-ul li a {
        font-size: .9rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.trainings-li .dropdown-container .dropdown-wrapper .main-ul {
        width: 17em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown.active {
        width: 28em;
    }
}

@media(min-width: 1400px) {


    .nav-container .top-nav .logo {
        width: 12em;
    }

    .nav-container .top-nav .top-nav-ul li {
        padding-right: 1.2em;
    }

    .nav-container .top-nav .top-nav-ul li a {
        font-size: 1.2rem;
    }

    .nav-container .top-nav .top-nav-ul li a.major-a {
        font-size: 1rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container {
        top: 1.8em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container .dropdown-wrapper {
        padding: 0.8em 1em 0.8em 1.5em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container .dropdown-wrapper .main-ul {
        width: 30em;
    }

}


@media(min-width: 1440px) {
    .nav-container.active {
        left: 3em;
        right: 3em;
    }

    .nav-container.active {
        padding: 1.2em 3em 1.5em 3em;
    }
}

@media(min-width: 1451px) {
    .nav-container {
        padding: 2.7em 9.375em 0.5em 9.375em;
    }

    .nav-container .ribbon .ribbon-h1 {
        font-size: 2rem;
    }

    .nav-container .ribbon .ribbon-p {
        font-size: 1.35rem;
    }

    .nav-container .ribbon .button-div .MuiButtonBase-root {
        font-size: 1.3rem;
    }

    .nav-container .ribbon .button-div svg {
        font-size: 3rem;
    }

    .nav-container.active {
        padding: 1.2em 6.3em 1.5em 6.3em;
    }

    .nav-container .top-nav .logo {
        width: 9em;
    }

    .nav-container .top-nav .top-nav-ul {
        transform: translateX(-8.5em);
    }

    .nav-container .top-nav .top-nav-ul li a {
        font-size: 1.1rem;
    }


    .nav-container .top-nav .quote .css-109ph1f-MuiButtonBase-root-MuiButton-root {
        font-size: 1rem;
    }
}

@media(min-width: 1500px) {
    .nav-container .top-nav .tools .profile-dropdown-container .top-profile .top-profile-right .profile-name {
        font-size: 1rem;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .top-profile .top-profile-right .profile-mail {
        font-size: 0.9rem;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .top-profile .img-container {
        width: 4em;
        height: 4em;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .middle-ul li a {
        font-size: 1rem;
    }

    .nav-container .top-nav .tools .profile-dropdown-container .logout-p {
        font-size: 1rem;
    }

    .nav-container .top-nav .logo {
        width: 12em;
    }

    .nav-container .top-nav .top-nav-ul li a {
        font-size: 1.3rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .main-a {
        font-size: 1.1rem;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown .sub-ul li a {
        font-size: 1rem;
    }
}


@media(min-width: 1550px) {

    .nav-container .top-nav .top-nav-ul li a.major-a {
        font-size: 1.1rem;
    }

    .nav-container .top-nav .soc-btn span {
        font-size: 1.15rem;
    }

    .nav-container .top-nav .tools .wishlist-dropdown-container .css-1kx6okn-MuiButtonBase-root-MuiButton-root {
        font-size: 0.8rem;
    }
}

@media(min-width: 1600px) {
    .nav-container .top-nav .top-nav-ul li.courses-li.solutions-li .dropdown-container .dropdown-wrapper .main-ul {
        width: 35em;
    }
}

@media(min-width: 1650px) {
    .nav-container .top-nav .top-nav-ul li.courses-li.trainings-li .dropdown-container .dropdown-wrapper .main-ul {
        width: 23em;
    }

    .nav-container .top-nav .top-nav-ul li.courses-li .dropdown-container .dropdown-wrapper .main-ul li .sub-dropdown.active {
        width: 31em;
    }
}

@media(min-width: 1700px) {

    .nav-container .top-nav .top-nav-ul li {
        padding-right: 1.6em;
    }

    .nav-container .top-nav .top-nav-ul li a.major-a {
        font-size: 1.2rem;
    }

    .nav-container .top-nav .soc-btn img {
        width: 2.5em;
    }

    .nav-container .top-nav .soc-btn span {
        font-size: 1.25rem;
    }
}


@media(min-width: 1728px) {
    .nav-container .top-nav .logo {
        width: 12em;
    }

    .nav-container .top-nav .top-nav-ul {
        transform: translateX(-11.5em);
    }

    .nav-container .top-nav .top-nav-ul li a {
        font-size: 1.4rem;
    }

    .nav-container .top-nav .quote .css-109ph1f-MuiButtonBase-root-MuiButton-root {
        font-size: 1.4rem;
    }
}