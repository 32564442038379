@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;900&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}



/* Fade In Css */

.soc-container .appear-up {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-in-out;
}

.soc-container .appear-up.active {
    opacity: 1;
    transform: translateX(0px);
}

.soc-container .appear-down {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 1s ease-in-out;
}

.soc-container .appear-down.active {
    opacity: 1;
    transform: translateY(0px);
}


.soc-container .appear-right {
    opacity: 0;
    transform: translateX(100px);
    transition: all 1s ease-in-out;
}

.soc-container .appear-right.active {
    opacity: 1;
    transform: translateX(0px);
}

.soc-container .appear-left {
    opacity: 0;
    transform: translateX(-160px);
    transition: all 1s ease-in-out;
}

.soc-container .appear-left.active {
    opacity: 1;
    transform: translateX(0px);
}

.soc-container .appear-left-hero {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.soc-container .appear-left-hero.active {
    opacity: 1;
    transform: translateX(0px);
}




/* Fade In Css End */

html {
    scroll-behavior: smooth;
}


.soc-container {
    overflow-x: hidden;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    background: #171D34;
}

.soc-container .section-title {
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: normal;
}

.soc-container .hero-section {
    position: relative;
    padding: 8em 1em 1em 1em;
    display: flex;

}

.soc-container .hero-section .hero-left .blur-circle {
    width: 5em;
    position: absolute;
    left: 1em;
    top: 5em;
}

.soc-container .hero-section .hero-left .hero-title {
    font-weight: 900;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    line-height: 1.7rem;
    position: relative;
    z-index: 1;
}

.soc-container .hero-section .hero-left .hero-p {
    font-size: .8rem;
    letter-spacing: .01rem;
    margin: 1rem 0;
    width: 78%;
    color: #CCCCCC;
}

.soc-container .hero-section .hero-left .demo-btn {
    display: flex;
    align-items: center;
    gap: .5em;
    border-radius: 2em;
    border: 2px solid var(--primary);
    transition: all .5s ease-in-out;
}

.soc-container .hero-section .hero-left .demo-btn:hover {
    transform: scale(.98);
}


.soc-container .hero-section .hero-left .demo-btn span {
    font-family: 'Poppins', sans-serif;
    font-size: .8rem;
    text-transform: capitalize;
    font-weight: 600;
    color: #ffffff;
}

.soc-container .hero-section .hero-left .demo-btn .lock-icon {
    color: #ffffff;
    font-size: 1.4em;
}

.soc-container .hero-section .hero-right .cyber-abstract {
    position: absolute;
    right: -3em;
    top: 4em;
    width: 11em;
    animation: abstract-anim 10s infinite linear;
}

@keyframes abstract-anim {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.05);
    }

    50% {
        transform: scale(1);
    }

    75% {
        transform: scale(.95);
    }

    100% {
        transform: scale(1);
    }
}

.soc-container .hero-section .hero-right .soc-padlock {
    position: absolute;
    top: 9.5em;
    width: 5em;
    right: -1em;
}

.soc-container .ensure-section {
    padding: 2em 1em;
}

.soc-container .ensure-section {
    display: flex;
    align-items: center;
    gap: .5em;
}

.soc-container .ensure-section .soc-serious-face {
    width: 3em;
}

.soc-container .ensure-section .ensure-h2 {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 800;
    font-size: .9rem;
}

.soc-container .dream-section {
    padding: 1em;
}

.soc-container .dream-section .dream-left {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.soc-container .dream-section .dream-left .dream {
    text-align: center;
}

.soc-container .dream-section .dream-left .dream .dream-h3 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.1rem;
    margin-top: .3rem;
    font-family: 'Montserrat', sans-serif;
}

.soc-container .dream-section .dream-left .dream .dream-p {
    color: #CCCCCC;
    font-size: .75rem;
    letter-spacing: .01rem;
}   

.soc-container .dream-section .dream-right {
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.soc-container .dream-section .dream-right .soc-report-img {
    width: 15em;
    transform: rotate(-19.611deg);
    z-index: 1;
}

.soc-container .dream-section .dream-right .waves-img {
    width: 10em;
    position: absolute;
    top: 2em;
    right: 1em;
    z-index: 0;
}

.soc-container .capabilities-section {
    margin-top: 4em;
    padding: 1em;
    text-align: center;
}

.soc-container .capabilities-section .capabilities-wrapper {
    margin-top: 3.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.soc-container .capabilities-section .capabilities-wrapper .capability {
    position: relative;
}

.soc-container .capabilities-section .capabilities-wrapper .capability .watermark {
    transform: rotate(32.778deg);
    color: rgba(255, 255, 255, 0.025);
    text-align: center;
    font-family: Montserrat;
    font-weight: 800;
    position: absolute;
    font-size: 1.7rem;
    user-select: none;
    z-index: -1;
}

.soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark1 {
    top: 2em;
    right: -4em;
}

.soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark2 {
    width: 13em;
    top: 2em;
    left: -5em;
}

.soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark3 {
    top: 4em;
    right: -3em;
}

.soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.binoculars {
    width: 5.5em;
}

.soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.checklist {
    width: 2.8em;
}

.soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.clock {
    width: 3em;
}

.soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.calendar {
    width: 3.5em;
}

.soc-container .capabilities-section .capabilities-wrapper .capability .capability-h3 {
    line-height: 1.1rem;
    font-size: 1rem;
    font-weight: 600;
    margin-top: .3rem;
    font-family: 'Montserrat', sans-serif;
}

.soc-container .capabilities-section .capabilities-wrapper .capability .capability-p {
    color: #CCCCCC;
    font-size: .75rem;
    letter-spacing: .01rem;
    margin-top: .3rem;
}

.soc-container .book-section {
    margin-top: 1em;
    padding: 1em;
    text-align: center;
} 

.soc-container .book-section .book-p {
    color: #CCCCCC;
    font-size: .75rem;
    letter-spacing: .01rem;
    margin-top: .3rem;
}

.soc-container .book-section .book-right {
    margin-top: 1em;
}

.soc-container .book-section .book-right form {
    display: flex;
    flex-direction: column;
    gap: .5em;
}

.soc-container .book-section .book-right form .css-zhbd6m-MuiFormLabel-root-MuiInputLabel-root {
    color: #ffffff;
    font-size: .8rem;
}

.soc-container .book-section .book-right form .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: .8rem;
    color: #ffffff;
}

.soc-container .book-section .book-right form .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
}

.soc-container .book-section .book-right  .book-btn {
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.soc-container .book-section .book-right .calendly-container {
    height: 0;
    transition: .6s ease-in-out;
}

.soc-container .book-section .book-right .calendly-container .calendly-inline-widget {
    min-width: unset !important;
    height: 0 !important;
    transition: .6s ease-in-out;
}

.soc-container .book-section .book-right .calendly-container.active {
    height: 100%;
    margin-top: 2em;
}

.soc-container .book-section .book-right .calendly-container.active .calendly-inline-widget {
    height: 55em !important;
    width: 100%;
}

.soc-container .book-section .book-right .calendly-container.active .calendly-inline-widget iframe {
    border-radius: .5em;
}




/* MEDIA QUERIES */

@media(min-width: 400px) {
    .soc-container .hero-section {
        padding: 9em 1.5em 2em 1.5em;
    }

    .soc-container .hero-section .hero-left {
        width: 83%;
    }

    .soc-container .hero-section .hero-left .blur-circle {
        width: 6em;
        top: 5.5em;
    }

    .soc-container .hero-section .hero-left .hero-title {
        font-size: 1.9rem;
        letter-spacing: .01rem;
        line-height: 1.9rem;
    }

    .soc-container .hero-section .hero-left .demo-btn span {
        font-size: 1rem;
    }

    .soc-container .hero-section .hero-left .demo-btn .lock-icon {
        font-size: 1.6em;
    }

    .soc-container .hero-section .hero-right .cyber-abstract {
        width: 14em;
    }

    .soc-container .hero-section .hero-right .soc-padlock {
        width: 9em;
        right: 0em;
        top: 9em;
    }

    .soc-container .ensure-section {
        padding: 2em 1.5em;
    }

    .soc-container .ensure-section .soc-serious-face {
        width: 4em;
    }

    .soc-container .ensure-section .ensure-h2 {
        font-size: 1.1rem;
    }

    .soc-container .dream-section {
        padding: 1em 1.5em;
    }

    .soc-container .dream-section .dream-left .dream .dream-h3 {
        font-size: 1.1rem;
        line-height: 1.1rem;
    }

    .soc-container .dream-section .dream-left .dream .dream-p {
        font-size: .8rem;
        margin-top: .3rem;
    }

    .soc-container .dream-section .dream-right .soc-report-img {
        width: 17em;
    }

    .soc-container .dream-section .dream-right .waves-img {
        width: 12em;
        top: 1em;
        right: 2em;
    }

    .soc-container .capabilities-section {
        margin-top: 5em;
        padding: 1em 1.5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark {
        font-size: 2.5rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark3 {
        top: 5em;
        right: -5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper {
        gap: 2em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.binoculars {
        width: 6.5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.checklist {
        width: 3em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.calendar {
        width: 4em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.clock {
        width: 3.3em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-h3 {
        font-size: 1.1rem;
        line-height: 1.1rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-p {
        font-size: .8rem;
    }

    .soc-container .book-section {
        padding: 1em 1.5em;
    }

    .soc-container .book-section .book-p {
        font-size: .8rem;
    }

    .soc-container .book-section .book-right  .book-btn {
        font-size: 1rem;
    }

}

@media(min-width: 500px) {

    .soc-container .hero-section {
        padding: 10em 1.5em 2em 1.5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark1 {
        width: 14em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark3 {
        top: 7em;
        width: 14em;
    }
}

@media(min-width: 550px) {
    .soc-container .hero-section {
        padding: 10em 1.5em 2em 1.5em;
    }

    .soc-container .hero-section .hero-left .hero-p {
        margin: 1.5rem 0;
        font-size: .95rem;
    }

    .soc-container .hero-section .hero-left .demo-btn {
        padding: 0.5em 2em;
    }

    .soc-container .hero-section .hero-right .soc-padlock {
        width: 10em;
    }

    .soc-container .hero-section .hero-right .cyber-abstract {
        width: 16em;
        right: 0;
    }

    .soc-container .dream-section .dream-right .soc-report-img {
        width: 20em;
    }

    .soc-container .dream-section .dream-right .waves-img {
        width: 13em;
        top: 2em;
        right: 4em;
    }

    .soc-container .capabilities-section {
        margin-top: 6em;
    }

    .soc-container .book-section {
        margin-top: 3em;
    }
    
}

@media(min-width: 600px) {
    .soc-container .hero-section {
        padding: 11em 1.875em 2em 1.875em;
    }

    .soc-container .hero-section .hero-left .hero-title {
        font-size: 2rem;
        line-height: 2rem;
    }

    .soc-container .hero-section .hero-left .blur-circle {
        width: 7em;
        top: 6em;
    }

    .soc-container .hero-section .hero-right .soc-padlock {
        width: 12em;
        right: 1em;
    }

    .soc-container .hero-section .hero-right .cyber-abstract {
        width: 17em;
    }

    .soc-container .ensure-section {
        padding: 3em 1.875em 2em 1.875em;
        gap: 1em;
    }

    .soc-container .ensure-section .soc-serious-face {
        width: 5em;
    }

    .soc-container .ensure-section .ensure-h2 {
        font-size: 1.3rem;
    }

    .soc-container .dream-section {
        display: flex;
        padding: 1em 1.875em;
    }
    .soc-container .dream-section .dream-left .dream {
        text-align: left;
    }

    .soc-container .dream-section .dream-right {
        margin-top: 2em;
    }

    .soc-container .dream-section .dream-right .waves-img {
        width: 14em;
        right: 0;
    }

    .soc-container .section-title {
        text-align: center;
    }

    .soc-container .capabilities-section {
        margin-top: 3em;
        text-align: left;
        padding: 1em 1.875em;
    }

    .soc-container .capabilities-section .capabilities-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability {
        text-align: center;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark2 {
        left: unset;
        top: 3em;
        width: 9em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark1 {
        right: -3em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark3 {
        top: 3em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-h3 {
        margin: .5rem 0;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.binoculars {
        width: 7.5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.checklist {
        width: 3.5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.clock {
        width: 3.7em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.calendar {
        width: 4.2em;
    }

    .soc-container .book-section {
        padding: 1em 1.875em;
    }
}

@media(min-width: 650px) {
    .soc-container .hero-section .hero-left {
        width: 80%;
    }
}

@media(min-width: 700px) {
    .soc-container .hero-section {
        padding: 12em 1.875em 2em 1.875em;
    }

    .soc-container .hero-section .hero-left {
        width: 70%;
    }

    .soc-container .hero-section .hero-left .blur-circle {
        width: 9em;
    }

    .soc-container .hero-section .hero-left .hero-title {
        font-size: 2.3rem;
        line-height: 2.3rem;
    }

    .soc-container .hero-section .hero-left .hero-p {
        font-size: 1rem;
        max-width: 22em;
    }

    .soc-container .hero-section .hero-left .demo-btn span {
        font-size: 1.2rem;
    }

    .soc-container .hero-section .hero-left .demo-btn .lock-icon {
        font-size: 1.9em;
    }

    .soc-container .hero-section .hero-right .cyber-abstract {
        width: 20em;
    }

    .soc-container .hero-section .hero-right .soc-padlock {
        width: 14em;
        right: -1em;
    }

    .soc-container .ensure-section .ensure-h2 {
        font-size: 1.5rem;
    }

    .soc-container .dream-section .dream-left .dream .dream-h3 {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }

    .soc-container .dream-section .dream-left .dream .dream-p {
        font-size: .9rem;
        margin-top: .5rem;
    }

    .soc-container .dream-section .dream-right .soc-report-img {
        width: 23em;
    }

    .soc-container .dream-section .dream-right .waves-img {
        width: 15em;
    }

    .soc-container .section-title {
        font-size: 1.7rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper {
        margin-top: 3em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-h3 {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-p {
        font-size: .9rem;
    }

    .soc-container .book-section .book-p {
        font-size: .9rem;
    }

    .soc-container .book-section .book-right  .book-btn {
        padding: 0.5em 3em;
    }

}  

@media(min-width: 750px) {
    .soc-container .book-section .book-right .calendly-container.active .calendly-inline-widget {
        height: 60em !important;
    }
}

@media(min-width: 800px) {
    .soc-container .hero-section {
        padding: 12em 3.125em 2em 3.125em;
    }

    .soc-container .hero-section .hero-left .hero-title {
        font-size: 2.5rem;
        line-height: 2.5rem;
    }

    .soc-container .hero-section .hero-left .blur-circle {
        width: 11em;
        top: 7em;
    }

    .soc-container .hero-section .hero-right .cyber-abstract {
        width: 23em;
    }
    
    .soc-container .hero-section .hero-left .hero-p {
        font-size: 1.1rem;
    }

    .soc-container .hero-section .hero-left .demo-btn {
        padding: 0.5em 4em;
    }

    .soc-container .ensure-section {
        padding: 6em 3.125em 2em 3.125em;
    }

    .soc-container .dream-section {
        padding: 1em 3.125em;
    }

    .soc-container .dream-section .dream-left {
        gap: 2em;
    }

    .soc-container .dream-section .dream-right .soc-report-img {
        width: 24em;
    }

    .soc-container .dream-section .dream-right .waves-img {
        width: 17em;
    }

    .soc-container .capabilities-section {
        margin-top: 5em;
        padding: 1em 3.125em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark {
        font-size: 3rem;
        line-height: 2.9rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark1 {
        top: 1em;
        right: -2em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark2 {
        right: -1em;
    }

    .soc-container .book-section {
        padding: 1em 3.125em;
    }
}

@media(min-width: 900px) {

    .soc-container .hero-section .hero-left .hero-title {
        font-size: 2.8rem;
        line-height: 2.8rem;
    }

    .soc-container .hero-section .hero-right .soc-padlock {
        right: 5em;
    }

    .soc-container .ensure-section .ensure-h2 {
        font-size: 1.8rem;
    }

    .soc-container .ensure-section .soc-serious-face {
        width: 7em;
    }

    .soc-container .dream-section .dream-left .dream .dream-h3 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }

    .soc-container .dream-section .dream-left .dream .dream-p {
        font-size: 1rem;
        margin-top: .6rem;
    }

    .soc-container .section-title {
        font-size: 2rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.binoculars {
        width: 8.5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.checklist {
        width: 4em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.clock {
        width: 4em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.calendar {
        width: 4.7em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-h3 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-p {
        font-size: 1rem;
    }

    .soc-container .book-section .book-p {
        font-size: 1rem;
        margin: .5rem 0;
    }

    .soc-container .book-section .book-right  .book-btn {
        padding: 0.5em 5em;
        font-size: 1.1rem;
    }
}

@media(min-width: 1000px) {
    .soc-container .hero-section {
        padding: 14em 5em 2em 5em;
    }

    .soc-container .hero-section .hero-right .soc-padlock {
        width: 16em;
    }

    .soc-container .dream-section .dream-left {
        gap: 3em;
    }

    .soc-container .dream-section .dream-right .soc-report-img {
        width: 30em;
    }

    .soc-container .dream-section .dream-right .waves-img {
        width: 20em;
        right: -3em;
    }

    .soc-container .dream-section .dream-right {
        margin-top: 1em;
    }

    .soc-container .capabilities-section {
        padding: 1em 5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark {
        font-size: 4rem;
        line-height: 3.5rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark2 {
        right: -3em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark3 {
        top: 2em;
        right: -3em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.binoculars {
        width: 9.5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.checklist {
        width: 5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.clock {
        width: 5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.calendar {
        width: 5.2em;
    }

    .soc-container .book-section .book-left {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .soc-container .book-section .book-p {
        max-width: 60vw;
    }
}

@media(min-width: 1050px) {
    .soc-container .hero-section {
        padding: 14em 5em 2em 5em;
    }

    .soc-container .hero-section .hero-left .blur-circle {
        width: 13em;
    }

    .soc-container .hero-section .hero-left .hero-title {
        font-size: 3rem;
        line-height: 3rem;
    }

    .soc-container .hero-section .hero-left .hero-p {
        margin: 2rem 0;
    }

    .soc-container .hero-section .hero-right .soc-padlock {
        width: 18em;
        top: 10em;
    }

    .soc-container .hero-section .hero-right .cyber-abstract {
        width: 28em;
        top: 0em;
    }

    .soc-container .ensure-section {
        padding: 6em 5em 2em 5em;
    }

    .soc-container .dream-section {
        padding: 2em 5em;
    }
}

@media(min-width: 1100px) {

    .soc-container .hero-section {
        padding: 15em 5em 2em 5em;
    }

    .soc-container .dream-section .dream-right .soc-report-img {
        width: 37em;
    }

    .soc-container .dream-section .dream-right .waves-img {
        width: 23em;
    }

    .soc-container .capabilities-section {
        margin-top: 8em;
    }

    .soc-container .capabilities-section .capabilities-wrapper {
        gap: 3em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.calendar {
        width: 5.7em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark2 {
        right: -2em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark3 {
        top: 2em;
    }
}

@media(min-width: 1150px) {
    .soc-container .dream-section .dream-right {
        margin-top: 0;
    }
}

@media(min-width: 1200px) {
    .soc-container .hero-section {
        padding: 16em 6.25em 2em 6.25em;
    }

    .soc-container .hero-section .hero-left .hero-title {
        font-size: 3.5rem;
        line-height: 3.5rem;
    }

    .soc-container .hero-section .hero-left .hero-p {
        font-size: 1.4rem;
    }

    .soc-container .hero-section .hero-left .demo-btn {
        gap: 1em;
    }

    .soc-container .hero-section .hero-left .demo-btn span {
        font-size: 1.5rem;
    }

    .soc-container .hero-section .hero-left .demo-btn .lock-icon {
        font-size: 2.3em;
    }

    .soc-container .hero-section .hero-right .cyber-abstract {
        width: 35em;
    }

    .soc-container .ensure-section {
        padding: 6em 6.25em 2em 6.25em;
    }

    .soc-container .dream-section {
        padding: 2em 6.25em;
    }

    .soc-container .dream-section .dream-left .dream .dream-h3 {
        font-size: 1.7rem;
        line-height: 1.7rem;
    }

    .soc-container .dream-section .dream-left .dream .dream-p {
        font-size: 1.2rem;
    }

    .soc-container .capabilities-section {
        padding: 1em 6.25em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark1 {
        right: -1em;
    }

    .soc-container .section-title {
        font-size: 2.5rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark2 {
        right: -1em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark3 {
        right: -1em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-h3 {
        font-size: 1.7rem;
        line-height: 1.7rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-p {
        font-size: 1.2rem;
    }

    .soc-container .book-section {
        margin-top: 5em;
    }

    .soc-container .book-section .book-p {
        max-width: 65vw;
    }

    .soc-container .book-section .book-p {
        font-size: 1.2rem;
        margin: .7rem 0;
    }

    .soc-container .book-section .book-right  .book-btn {
        font-size: 1.3rem;
    }
    
}

@media(min-width: 1300px) {
    .soc-container .hero-section {
        padding: 17em 6.25em 2em 6.25em;
    }
}

@media(min-width: 1350px) {

    .soc-container .hero-section {
        padding: 18em 6.25em 2em 6.25em;
    }

    .soc-container .hero-section .hero-left .blur-circle {
        width: 15em;
        top: 10em;
    }

    .soc-container .hero-section .hero-left .hero-title {
        font-size: 4rem;
        line-height: 4rem;
    }

    .soc-container .hero-section .hero-left .hero-p {
        font-size: 1.6rem;
    }

    .soc-container .hero-section .hero-left .demo-btn span {
        font-size: 1.7rem;
    }

    .soc-container .hero-section .hero-left .demo-btn .lock-icon {
        font-size: 2.7em;
    }

    .soc-container .hero-section .hero-right .soc-padlock {
        width: 20em;
        top: 13em;
    }

    .soc-container .hero-section .hero-right .cyber-abstract {
        width: 38em;
    }

    .soc-container .ensure-section {
        padding: 9em 6.25em 4em 6.25em;
    }

    .soc-container .ensure-section .ensure-h2 {
        font-size: 2.1rem;
    }

    .soc-container .ensure-section .soc-serious-face {
        width: 8em;
    }

    .soc-container .dream-section .dream-left .dream .dream-h3 {
        font-size: 1.9rem;
        line-height: 1.9rem;
    }

    .soc-container .dream-section .dream-left .dream .dream-p {
        font-size: 1.4rem;
        margin-top: .8rem;
    }

    .soc-container .dream-section .dream-right .soc-report-img {
        width: 38em;
    }

    .soc-container .dream-section .dream-right .waves-img {
        width: 26em;
    }

    .soc-container .section-title {
        font-size: 2.9rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper {
        grid-gap: 3em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark {
        font-size: 5rem;
        line-height: 4.5rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark2 {
        right: -2em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark.watermark3 {
        right: -2em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.binoculars {
        width: 11.5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.clock {
        width: 6em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.calendar {
        width: 7em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-h3 {
        font-size: 1.9rem;
        line-height: 1.9rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-p {
        font-size: 1.4rem;
    }

    .soc-container .book-section .book-p {
        font-size: 1.4rem;
        max-width: 67vw;
    }

    .soc-container .book-section .book-right  .book-btn {
        font-size: 1.6rem;
    }
}


@media(min-width: 1451px) {
    .soc-container .hero-section {
        padding: 18em 9.375em 2em 9.375em;
    }

    .soc-container .hero-section .hero-left .hero-title {
        font-size: 4.2rem;
        line-height: 4.2rem;
    }

    .soc-container .hero-section .hero-left .hero-p {
        font-size: 1.8rem;
        margin: 3rem 0;
    }

    .soc-container .hero-section .hero-left .demo-btn {
        gap: 1.5em;
        padding: 0.9em 5em;
        border-radius: 4em;
    }

    .soc-container .hero-section .hero-left .demo-btn span {
        font-size: 1.9rem;
    }

    .soc-container .hero-section .hero-left .demo-btn .lock-icon {
        font-size: 3em;
    }

    .soc-container .hero-section .hero-right .soc-padlock {
        width: 22em;
    }

    .soc-container .hero-section .hero-right .cyber-abstract {
        width: 41em;
    }

    .soc-container .ensure-section {
        padding: 9em 9.375em 4em 9.375em;
    }

    .soc-container .ensure-section .ensure-h2 {
        font-size: 2.5rem;
    }

    .soc-container .dream-section {
        padding: 2em 9.375em;
    }

    .soc-container .capabilities-section {
        padding: 1em 9.375em;
    }

    .soc-container .book-section {
        padding: 1em 9.375em;
        margin-top: 7em;
    }
}

@media(min-width: 1500px) {
    .soc-container .hero-section {
        padding: 19em 9.375em 2em 9.375em;
    }
}

@media(min-width: 1550px) {
    .soc-container .hero-section .hero-left .hero-title {
        font-size: 4.6rem;
        line-height: 4.6rem;
    }

    .soc-container .hero-section .hero-left .hero-p {
        font-size: 2rem;
    }

    .soc-container .hero-section .hero-left .demo-btn span {
        font-size: 2rem;
    }

    .soc-container .hero-section .hero-left .demo-btn .lock-icon {
        font-size: 3.6em;
    }

    .soc-container .hero-section .hero-right .soc-padlock {
        width: 24em;
    }

    .soc-container .ensure-section .ensure-h2 {
        font-size: 2.8rem;
    }

    .soc-container .ensure-section .soc-serious-face {
        width: 9em;
    }

    .soc-container .dream-section .dream-left .dream .dream-h3 {
        font-size: 2.3rem;
        line-height: 2.3rem;
    }

    .soc-container .dream-section .dream-left .dream .dream-p {
        font-size: 1.6rem;
    }

    .soc-container .dream-section .dream-right .soc-report-img {
        width: 40em;
    }

    .soc-container .section-title {
        font-size: 3.3rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-h3 {
        font-size: 2.3rem;
        line-height: 2.3rem;
        margin: 1rem 0;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .capability-p {
        font-size: 1.6rem;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.binoculars {
        width: 13.5em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.checklist {
        width: 6em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.clock {
        width: 7em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .cap-img.calendar {
        width: 8em;
    }

    .soc-container .book-section .book-p {
        font-size: 1.6rem;
        max-width: 70vw;
        margin: 1.1rem 0;
    }

    .soc-container .book-section .book-right  .book-btn {
        font-size: 1.9rem;
    }
}


@media(min-width: 1600px) {

    .soc-container .hero-section {
        padding: 20em 9.375em 2em 9.375em;
    }

    .soc-container .capabilities-section .capabilities-wrapper .capability .watermark {
        font-size: 6rem;
        line-height: 5rem;
    }
}

/* MEDIA QUERIES END */