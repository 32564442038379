@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800;900&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}

.groupoffers-container {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    color: var(--black);
}



.groupoffers-container .blur-div {
    border-radius: 2em 0 0 1em;
}

.groupoffers-container .blur-div.loaded {
    border-radius: unset;
}


/* Fade In Css */

.groupoffers-container .appear-up {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-in-out;
}

.groupoffers-container .appear-up.active {
    opacity: 1;
    transform: translateX(0px);
}

.groupoffers-container .appear-down {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 1s ease-in-out;
}

.groupoffers-container .appear-down.active {
    opacity: 1;
    transform: translateY(0px);
}


.groupoffers-container .appear-right {
    opacity: 0;
    transform: translateX(52px);
    transition: all 1s ease-in-out;
}

.groupoffers-container .appear-right.active {
    opacity: 1;
    transform: translateX(0px);
}

.groupoffers-container .appear-left {
    opacity: 0;
    transform: translateX(-107px);
    transition: all 1s ease-in-out;
}

.groupoffers-container .appear-left.active {
    opacity: 1;
    transform: translateX(0px);
}

.groupoffers-container .appear-left-hero {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.groupoffers-container .appear-left-hero.active {
    opacity: 1;
    transform: translateX(0px);
}




/* Fade In Css End */


/* POPUP STYLES */

.groupoffers-container .popup-wrapper {
    position: fixed;
    inset: 0;
    background: #000000e8;
    z-index: 20;
    transition: all .5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
}

.groupoffers-container .popup-wrapper.disappear {
    opacity: 0;
    z-index: -1;
}

.groupoffers-container .popup-wrapper .exit-icon {
    color: white;
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.groupoffers-container .popup-wrapper .exit-icon:hover {
    transform: scale(.95);
}

.groupoffers-container .popup-wrapper .illustrations img {
    position: absolute;
    width: 3em;
    top: 86vh;
}

.groupoffers-container .popup-wrapper .illustrations img.img1 {
    left: 1em;
}

.groupoffers-container .popup-wrapper .illustrations img.img2 {
    right: 1em;
}

.groupoffers-container .popup-wrapper form {
    display: flex;
    flex-direction: column;
    gap: .7em;
    width: 100%;
}

.groupoffers-container .popup-wrapper form h3 {
    color: white;
    margin-bottom: 0.5em;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    text-align: center;
}

.groupoffers-container .popup-wrapper form .MuiInputBase-input {
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    font-size: .8rem;
}

.groupoffers-container .popup-wrapper form .MuiFormLabel-root {
    font-family: 'Poppins', sans-serif;
    font-size: .75rem;
    color: #ffffff;
}

.groupoffers-container .popup-wrapper form .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
}

.groupoffers-container .popup-wrapper form .contact-btn {
    margin-top: unset;
    background: var(--primary);
    color: #ffffff;
    padding: 0.5em 1em;
    border-radius: 0.3em;
    box-shadow: -0.4em 0.4em 0 var(--secondary);
    gap: .8em;
    border: none;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.groupoffers-container .popup-wrapper form .contact-btn.Mui-disabled {
    background: #ddd;
    box-shadow: none;
}

.groupoffers-container .popup-wrapper form .contact-btn.Mui-disabled .MuiLoadingButton-endIconLoadingEnd {
    display: none;
}

.groupoffers-container .popup-wrapper form .contact-btn.Mui-disabled:hover {
    box-shadow: none;
}

.groupoffers-container .popup-wrapper form .contact-btn .MuiLoadingButton-loadingIndicator {
    position: relative;
    margin-left: 2em;
}

.groupoffers-container .popup-wrapper form .contact-btn span {
    font-size: .8rem;
}

.groupoffers-container .popup-wrapper form .contact-btn:hover {
    box-shadow: -0.6em 0.6em 0 var(--secondary);
}

.groupoffers-container .popup-wrapper form .contact-btn img {
    width: 1.25em;
}

.groupoffers-container .popup-wrapper form .contact-btn .send-icon {
    font-size: 1.6em;
}

.groupoffers-container .popup-wrapper form .disclaimer {
    margin-top: 1rem;
    font-size: .65rem;
    color: #bcbcbc;
    text-align: center;
    letter-spacing: .01rem;
}



/* POPUP STYLES END */



.groupoffers-container .offers-section {
    padding: 11em 1em 1em 1em;
}

.groupoffers-container .offers-section .abstract {
    display: none;
}

.groupoffers-container .offers-section .offer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8em;
}

.groupoffers-container .offers-section .offer-wrapper .offer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.groupoffers-container .offers-section .offer-wrapper .offer .offer-left {
    display: flex;
    width: fit-content;
    position: relative;
    margin-bottom: 1em;
    height: 100%;
}

.groupoffers-container .offers-section .offer-wrapper .offer .offer-left .discount-tag {
    position: absolute;
    top: -2.6em;
    background: var(--secondary);
    padding: 0.5em 1em;
    border-radius: 3em 3em 0 3em;
    font-weight: 600;
    font-size: .8rem;
    right: 0;
    border: 4px solid black;
    color: #FFD233;
    text-shadow:
            -1px -1px 0 #000,
            0 -1px 0 #000,
            1px -1px 0 #000,
            1px 0 0 #000,
            1px 1px 0 #000,
            0 1px 0 #000,
            -1px 1px 0 #000,
            -1px 0 0 #000;
}

.groupoffers-container .offers-section .offer-wrapper .offer .offer-left .discount-tag span {
    animation: pulse 1s infinite alternate;
    display: inline-block;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}

.groupoffers-container .offers-section .offer-wrapper .offer .offer-left img {
    width: 10em;
    height: 100%;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

}

.groupoffers-container .offers-section .offer-wrapper .offer .offer-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5em;
}

.groupoffers-container .offers-section .offer-wrapper .offer .offer-right .offer-h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    text-align: center;
    font-weight: 900;
}

.groupoffers-container .offers-section .offer-wrapper .offer .offer-right .offer-p {
    text-align: center;
    color: #4b4b4b;
    font-size: .8rem;
    letter-spacing: .01rem;
    line-height: 1.3rem;
}

.groupoffers-container .offers-section .offer-wrapper .offer .offer-right .MuiButton-root {
    color: var(--black);
    border: 2px solid var(--black);
    text-transform: capitalize;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    box-shadow: -6px 6px var(--primary);
    font-size: .75rem;
    transition: all .5s ease-in-out;
}

.groupoffers-container .offers-section .offer-wrapper .offer .offer-right .MuiButton-root:hover {
    box-shadow: -3px 3px var(--primary);
}

.groupoffers-container .offers-section .warning-p {
    text-align: center;
    color: var(--black);
    font-size: .75rem;
    letter-spacing: .01rem;
    line-height: 1.3rem;
    margin-top: 4em;
    font-weight: 500;
}


@media(min-width: 400px) {

    .groupoffers-container .popup-wrapper {
        padding: 0 1.5em;
    }

    .groupoffers-container .offers-section {
        padding: 10em 1.5em 1em 1.5em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer {
        max-width: 17em;
    }
}

@media(min-width: 500px) {

    .groupoffers-container .offers-section {
        padding: 11em 1.5em 1em 1.5em;
    }

    .groupoffers-container .popup-wrapper form {
        max-width: 20em;
    }

    .groupoffers-container .popup-wrapper .exit-icon {
        font-size: 2rem;
    }
}

@media(min-width: 600px) {

    .groupoffers-container .offers-section {
        padding: 12em 1.875em 1em 1.875em;
    }

    .groupoffers-container .popup-wrapper form h3 {
        font-size: 1.6rem;
    }

    .groupoffers-container .popup-wrapper form .MuiFormLabel-root {
        font-size: .85rem;
    }

    .groupoffers-container .popup-wrapper form .MuiInputBase-input {
        padding: 1em 1em .7em 1em;
    }

    .groupoffers-container .popup-wrapper .illustrations img {
        width: 5em;
    }

    .groupoffers-container .popup-wrapper .illustrations img {
        top: 77vh;
    }

    .groupoffers-container .offers-section {
        padding: 10em 1.875em 1em 1.875em;
    }

    .groupoffers-container .offers-section .offer-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 4em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer {
        max-width: unset;
        height: 100%;
    }

}

@media(min-width: 650px) {
    .groupoffers-container .popup-wrapper form .disclaimer {
        font-size: .7rem;
    }
}

@media(min-width: 700px) {

    .groupoffers-container .offers-section {
        padding: 12em 1.875em 1em 1.875em;
    }

    .groupoffers-container .popup-wrapper .illustrations img {
        top: 69vh;
        width: 7em;
    }
}

@media(min-width: 800px) {
    .groupoffers-container .offers-section {
        padding: 12em 3.125em 1em 3.125em;
    }
}

@media(min-width: 850px) {

    .groupoffers-container .offers-section {
        padding: 13em 3.125em 1em 3.125em;
    }

    .groupoffers-container .offers-section .offer-wrapper {
        gap: 5em;
    }
}

@media(min-width: 900px) {

    .groupoffers-container .popup-wrapper .illustrations img {
        top: 62vh;
        width: 8em;
    }

    .groupoffers-container .offers-section {
        position: relative;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer {
        flex-direction: row;
        gap: 1em;
        align-items: center;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-left {
        margin-bottom: unset;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right {
        align-items: unset;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .offer-h2 {
        text-align: left;
        font-size: 1rem;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .offer-p {
        text-align: left;
        font-size: .75rem;
        line-height: 1.2rem;
    }

    .groupoffers-container .offers-section .abstract {
        display: unset;
        position: absolute;
        z-index: 0;
        width: 20em;
        left: -5em;
        top: 4em;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    .groupoffers-container .offers-section .warning-p {
        font-size: .9rem;
        line-height: 1.4rem;
    }
}

@media(min-width: 1000px) {

    .groupoffers-container .popup-wrapper form {
        max-width: 25em;
    }

    .groupoffers-container .popup-wrapper form h3 {
        font-size: 1.8rem;
        line-height: 1.8rem;
    }

    .groupoffers-container .popup-wrapper form .MuiFormLabel-root {
        font-size: 1rem;
    }

    .groupoffers-container .popup-wrapper form .MuiInputBase-input {
        padding: 1rem;
        font-size: .9rem;
    }

    .groupoffers-container .popup-wrapper form .contact-btn span {
        font-size: 1rem;
    }

    .groupoffers-container .popup-wrapper form .contact-btn .send-icon {
        font-size: 1.5em;
    }

    .groupoffers-container .popup-wrapper form .disclaimer {
        font-size: .8rem;
        line-height: 1.4rem;
    }


    .groupoffers-container .offers-section {
        padding: 14em 5em 1em 5em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .offer-h2 {
        font-size: 1.1rem;
        text-transform: uppercase;
        font-weight: 800;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .offer-p {
        font-size: .8rem;
        line-height: 1.3rem;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .MuiButton-root {
        font-size: 0.9rem;
        width: 10em;
    }

    .groupoffers-container .offers-section .warning-p {
        max-width: 49em;
        margin: 4em auto;
    }
}

@media(min-width: 1050px) {
    .groupoffers-container .offers-section {
        padding: 15em 5em 1em 5em;
    }
}

@media(min-width: 1100px) {

    .groupoffers-container .offers-section {
        padding: 16em 5em 1em 5em;
    }

    .groupoffers-container .popup-wrapper .illustrations img {
        top: 62vh;
        width: 10em;
    }


    .groupoffers-container .offers-section .abstract {
        width: 25em;
        left: -8em;
    }

    .groupoffers-container .offers-section .offer-wrapper {
        gap: 4em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer.offer3 {
        margin-top: 2em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer.offer4 {
        margin-top: 2em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-left img {
        width: 11em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-left .discount-tag {
        font-size: 1rem;
        border: 5px solid black;
    }

    .groupoffers-container .offers-section .warning-p {
        margin: 4em auto 0em auto;
    }
}

@media(min-width: 1200px) {

    .groupoffers-container .popup-wrapper .exit-icon {
        font-size: 2.5rem;
    }

    .groupoffers-container .offers-section {
        padding: 17em 6.25em 1em 6.25em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer.offer3 {
        margin-top: 3em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer.offer4 {
        margin-top: 3em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-left img {
        width: 13em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-left .discount-tag {
        font-size: 1.2rem;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right {
        gap: .6em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .offer-h2 {
        font-size: 1.2rem;
        line-height: 1.3rem;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .offer-p {
        font-size: .9rem;
        line-height: 1.5rem;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .MuiButton-root {
        font-size: 1rem;
    }
}

@media(min-width: 1300px) {

    .groupoffers-container .offers-section {
        padding: 19em 6.25em 1em 6.25em;
    }

    .groupoffers-container .popup-wrapper form {
        max-width: 34em;
        gap: 1em;
    }

    .groupoffers-container .popup-wrapper form h3 {
        font-size: 2.2rem;
        line-height: 2.2rem;
    }
    
    .groupoffers-container .popup-wrapper form .MuiFormLabel-root {
        font-size: 1.2rem;
    }

    .groupoffers-container .popup-wrapper form .MuiInputBase-input {
        font-size: 1.1rem;
    }

    .groupoffers-container .popup-wrapper form .contact-btn {
        box-shadow: -0.6em 0.6em 0 var(--secondary);
    }

    .groupoffers-container .popup-wrapper form .contact-btn span {
        font-size: 1.3rem;
    }

    .groupoffers-container .popup-wrapper form .disclaimer {
        font-size: 1rem;
        line-height: 1.6rem;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer {
        gap: 1.5em;
    }


}

@media(min-width: 1400px) {

    .groupoffers-container .popup-wrapper .illustrations img.img2 {
        right: 6em;
    }

    .groupoffers-container .popup-wrapper .illustrations img.img1 {
        left: 6em;
    }

    .groupoffers-container .offers-section {
        padding: 18em 6.25em 1em 6.25em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .offer-h2 {
        font-size: 1.3rem;
        line-height: 1.4rem;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .offer-p {
        font-size: 1rem;
        line-height: 1.6rem;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right {
        gap: 0.8em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .MuiButton-root {
        font-size: 1.1rem;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-left img {
        width: 15em;
    }

    .groupoffers-container .offers-section .warning-p {
        font-size: 1.1rem;
        line-height: 1.7rem;
    }
}

@media(min-width: 1451px) {
    .groupoffers-container .offers-section {
        padding: 19em 9.375em 1em 9.375em;
    }
}

@media(min-width: 1500px) {

    .groupoffers-container .offers-section {
        padding: 20em 9.375em 1em 9.375em;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .offer-h2 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right .offer-p {
        font-size: 1.1rem;
        line-height: 1.7rem;
    }

    .groupoffers-container .offers-section .offer-wrapper .offer .offer-right {
        gap: 1em;
    }
}

@media(min-width: 1550px) {
    .groupoffers-container .offers-section {
        padding: 20em 9.375em 1em 9.375em;
    }
}

@media(min-width: 1600px) {
    .groupoffers-container .popup-wrapper .exit-icon {
        font-size: 3.5rem;
    }
}