@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@500&display=swap');


* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}

.coursepage-container {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    color: var(--black);
}


/* Fade In Css */

.coursepage-container .appear-up {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-in-out;
}

.coursepage-container .appear-up.active {
    opacity: 1;
    transform: translateX(0px);
}

.coursepage-container .appear-down {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 1s ease-in-out;
}

.coursepage-container .appear-down.active {
    opacity: 1;
    transform: translateY(0px);
}


.coursepage-container .appear-right {
    opacity: 0;
    transform: translateX(51px);
    transition: all 1s ease-in-out;
}

.coursepage-container .appear-right.active {
    opacity: 1;
    transform: translateX(0px);
}

.coursepage-container .appear-left {
    opacity: 0;
    transform: translateX(-107px);
    transition: all 1s ease-in-out;
}

.coursepage-container .appear-left.active {
    opacity: 1;
    transform: translateX(0px);
}

.coursepage-container .appear-left-hero {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.coursepage-container .appear-left-hero.active {
    opacity: 1;
    transform: translateX(0px);
}

/* Fade In Css End */


/* OFFER POPUP START */


.coursepage-container .offer-popup {
    position: fixed;
    inset: 0;
    background: var(--black);
    z-index: 15;
    opacity: 1;
    transition: all .2s ease-in-out;
}

.coursepage-container .offer-popup .exit-icon {
    position: absolute;
    top: 1em;
    z-index: 19;
    color: #ffffff;
    right: 1em;
    cursor: pointer;
}

.coursepage-container .offer-popup .offer-wrapper .centre-div {
    position: relative;
    display: flex;
    width: 100%;
    padding-top: 7em;
    flex-direction: column;
    align-items: center;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div {
    position: relative;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .dot-frame {
    position: absolute;
    width: 3em;
    left: 1em;
    top: -2em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle {
    position: absolute;
    width: 4em;

}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle1 {
    top: 5em;
    left: -4em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle2 {
    top: 2em;
    left: -5em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle3 {
    top: 8em;
    left: -5em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle4 {
    top: 0em;
    right: -4.5em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle5 {
    top: 3em;
    right: -4em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle6 {
    top: 6em;
    right: -4.5em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle7 {
    display: none;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse {
    position: absolute;
    width: .45em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse1 {
    top: 3.5em;
    left: -1em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse2 {
    top: 7em;
    left: 0em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse3 {
    top: 5em;
    right: -1em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse4 {
    top: 1.5em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse5,
.coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse6,
.coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse7,
.coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse8,
.coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse9,
.coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse10,
.coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse11 {
    display: none;
}



.coursepage-container .offer-popup .offer-wrapper .offer-title-div .x {
    position: absolute;
    width: .7em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x1 {
    top: 1em;
    left: 0em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x2 {
    top: 5.5em;
    left: -1em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x3 {
    top: 0em;
    right: -1em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x4 {
    top: 2.7em;
    right: -1em;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x5,
.coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x6,
.coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x7,
.coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x8,
.coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x9,
.coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x10,
.coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x11 {
    display: none;
}

.coursepage-container .offer-popup .offer-wrapper .offer-title {
    width: 14em;
    animation: vibrate 1s infinite alternate 1s;
}

@keyframes vibrate {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(10deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-10deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.coursepage-container .offer-popup .offer-wrapper .centre-div .discount-div {
    padding: 1.25em 0.7em;
    background: #082733;
    box-shadow: 6px -4px 67px 0px rgba(0, 0, 0, 0.25), -6px 4px 67px 0px #000;
    transform: rotate(-11.996deg);
    width: 14em;
    display: flex;
    justify-content: center;
}

.coursepage-container .offer-popup .offer-wrapper .centre-div .discount-div .discount-h1 {
    font-family: 'Black Ops One', sans-serif;
    text-shadow: -2px 4px 4px rgba(1, 27, 37, 0.62);
    font-weight: 400;
    background: linear-gradient(180deg, #CFB53B 31.7%, #FFDF00 74.11%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.1rem;
    width: fit-content;
}

.coursepage-container .offer-popup .offer-wrapper .centre-div .product-div {
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coursepage-container .offer-popup .offer-wrapper .centre-div .product-div .product-title {
    color: #D9D9D9;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Black Ops One', sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

.coursepage-container .offer-popup .offer-wrapper .centre-div .product-div .warning-p {
    color: #ffffff;
    font-family: 'JetBrains Mono', sans-serif;
    font-size: .7rem;
    width: fit-content;
    margin-top: .1rem;
}

.coursepage-container .offer-popup .offer-wrapper .watermarks .watermark {
    position: absolute;
    color: var(--black);
    opacity: 2%;
    font-family: 'Poppins', sans-serif;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    transform: rotate(-11.94deg);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ffffff;
    font-size: 4rem;
}

.coursepage-container .offer-popup .offer-wrapper .watermarks .watermark.watermark1 {
    top: 0em;
    left: -1em;
}

.coursepage-container .offer-popup .offer-wrapper .watermarks .watermark.watermark2 {
    top: 4.3em;
    right: 0;
}

.coursepage-container .offer-popup .offer-wrapper .watermarks .watermark.watermark3 {
    top: 6.2em;
    left: -2em;
}

.coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame {
    position: absolute;
    width: 3em;
}

.coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame1 {
    top: 2em;
    left: 1em;
    display: none;
}

.coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame2,
.coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame3,
.coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame4 {
    display: none;
}

.coursepage-container .offer-popup .buttons-div {
    position: absolute;
    margin-top: 90vh;
    display: flex;
    gap: 1em;
    width: 100%;
    justify-content: center;
    padding: 0 1em;
}

.coursepage-container .offer-popup .buttons-div .cancel-btn {
    font-size: .7rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    text-transform: capitalize;
    width: 50%;
    letter-spacing: .03rem;
}

.coursepage-container .offer-popup .buttons-div .proceed-btn {
    font-size: .7rem;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-weight: 800;
    width: 50%;
    color: #ffffff;
    letter-spacing: .03rem;
}

.coursepage-container .offer-popup.disappear {
    opacity: 0;
    z-index: -1;
}


.coursepage-container .offer-popup .img-container img {
    width: 100%;
}

/* OFFER POPUP END */


.coursepage-container .hero-section {
    color: var(--black);
    padding: 11em 1em 0em 1em;
    position: relative;
}

.coursepage-container .hero-section .triangle {
    width: 10em;
    height: 10em;
    transform: rotate(-46.897deg);
    flex-shrink: 0;
    border-radius: 2.36356rem;
    background: rgba(39, 170, 225, 0.51);
    position: absolute;
    top: 9em;
    left: -7em;
    z-index: -1;
}

.coursepage-container .detailed-section .triangle {
    width: 10em;
    height: 10em;
    transform: rotate(-46.897deg);
    flex-shrink: 0;
    border-radius: 2.36356rem;
    background: rgba(39, 170, 225, 0.51);
    position: absolute;
    top: 6em;
    right: -6em;
    z-index: -1;
}

.coursepage-container .hero-section .hero-left {
    position: relative;
}

.coursepage-container .hero-section .hero-left .back-arrow {
    position: absolute;
    top: -4em;
    width: 2em;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.coursepage-container .hero-section .hero-left .back-arrow:hover {
    transform: scale(.95);
}

.coursepage-container .hero-section .hero-left .hero-h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 900;
}

.coursepage-container .hero-section .hero-left .hero-h1 span.trademark {
    font-size: 1rem;
    display: inline-block;
    transform: translateY(-.5em);
}

.coursepage-container .hero-section .hero-left .hero-ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    margin: 1.5em 0 2em 0;
}

.coursepage-container .hero-section .hero-left .hero-ul li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.coursepage-container .hero-section .hero-left .hero-ul li .bullet-div {
    height: .7em;
    width: .7em;
    background: var(--secondary);
    border-radius: 0.15em;
    transform: translateY(4px);
}

.coursepage-container .hero-section .hero-left .hero-ul li .point-span {
    font-size: 0.65rem;
    width: 91%;
    letter-spacing: 0.01rem;
    font-weight: 500;
    letter-spacing: .01rem;
    color: #4b4b4b;
    line-height: 1.1rem;
}

.coursepage-container .hero-section .hero-left .buttons-div {
    display: flex;
    gap: 1em;
    align-items: center;
    margin-bottom: 3em;
}

.coursepage-container .enquire-btn {
    padding: 0.3125rem 0.625rem;
    font-size: 0.7rem;
    font-family: 'Poppins', sans-serif;
    border-radius: 0;
    letter-spacing: 0.04rem;
    color: var(--secondary);
    border: 1px solid var(--secondary);
    font-weight: 600;
    width: 100%;
}

.coursepage-container .hero-section .hero-right img.course-hero-img {
    display: none;
}


.coursepage-container .overview-section {
    padding: 1em 1em 2em 1em;
}

.coursepage-container .section-title {
    font-size: 1.1rem;
    text-align: left;
    color: var(--black);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    line-height: 1.4rem;
    font-family: 'Montserrat', sans-serif;
}


.coursepage-container .overview-section .overview-p {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 0.7rem;
    line-height: 1.1rem;
    color: #4b4b4b;
}

.coursepage-container .detailed-section {
    margin: 1em 0 2em 0;
    position: relative;
}


.coursepage-container .detailed-section .detailed-nav {
    background: rgba(39, 170, 225, 0.51);
    padding: 1em;
}

.coursepage-container .detailed-section .detailed-nav ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    gap: 1em;
}

.coursepage-container .detailed-section .detailed-nav ul li {
    font-size: 0.7rem;
    cursor: pointer;
    position: relative;
    width: fit-content;
}

.coursepage-container .detailed-section .detailed-nav ul li.active {
    font-weight: bold;
    color: var(--secondary);
}

.coursepage-container .detailed-section .detailed-nav ul li.active::before {
    width: 100%;
}

.coursepage-container .detailed-section .detailed-nav ul li::before {
    content: '';
    position: absolute;
    top: -0.5em;
    left: 50%;
    width: 0;
    transform: translateX(-50%);
    height: 0.2em;
    background: var(--secondary);
    transition: all 0.3s ease-in-out;
    border-radius: 1em;
}

.coursepage-container .detailed-section .detailed-nav ul li:hover::before {
    width: 100%;
}


.coursepage-container .detailed-section .detailed-body {
    padding: 1em;
}

.coursepage-container .detailed-section .detailed-body .detail-div {
    display: none;
}

.coursepage-container .detailed-section .detailed-body .detail-div.active {
    display: block;
}

.coursepage-container .detailed-section .detailed-body .syllabus-div .accordion-title {
    font-size: 0.75rem;
    letter-spacing: 0.01rem;
}

.coursepage-container .detailed-section .detailed-body .syllabus-div .accordion-ul {
    list-style-type: auto;
    padding: 0 0.5em;
}


.coursepage-container .detailed-section .detailed-body .syllabus-div .accordion-ul .accordion-li {
    font-size: 0.7rem;
    letter-spacing: 0.01rem;
}

.coursepage-container .detailed-section .detailed-body .audience-div .audience-p {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 0.7rem;
    line-height: 1.1rem;
    color: #4b4b4b;
}

.coursepage-container .detailed-section .detailed-body .audience-div .audience-ul {
    list-style-type: auto;
    padding: 0 1em;
}

.coursepage-container .detailed-section .detailed-body .audience-div .audience-ul li {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 0.7rem;
    line-height: 1.1rem;
    color: #4b4b4b;
}


.coursepage-container .detailed-section .detailed-body .objectives-div .objectives-p {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 0.7rem;
    line-height: 1.1rem;
    color: #4b4b4b;
}

.coursepage-container .detailed-section .detailed-body .objectives-div .objectives-ul {
    list-style-type: auto;
    padding: 0 1em;
}

.coursepage-container .detailed-section .detailed-body .objectives-div .objectives-ul li {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 0.7rem;
    line-height: 1.1rem;
    color: #4b4b4b;
}

.coursepage-container .detailed-section .detailed-body .exams-div .exams-p {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 0.7rem;
    line-height: 1.1rem;
    color: #4b4b4b;
}

.coursepage-container .detailed-section .detailed-body .exams-div .exams-ul {
    list-style-type: auto;
    padding: 0 1em;
}

.coursepage-container .detailed-section .detailed-body .exams-div .exams-ul li {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 0.7rem;
    line-height: 1.1rem;
    color: #4b4b4b;
}


.coursepage-container .why-section {
    position: relative;
    padding: 0em 1em 1em 1em;
}


.coursepage-container .why-section .reasons {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 2em;
}

.coursepage-container .why-section .reasons .reason {
    display: flex;
    gap: 0.6em;
}

.coursepage-container .why-section .reasons .reason img {
    width: 3.5em;
}

.coursepage-container .why-section .reasons .reason .reason-content .reason-title {
    font-size: .8rem;
    letter-spacing: 0.01rem;
    color: var(--black);
    text-align: left;
}

.coursepage-container .why-section .reasons .reason .reason-content .reason-p {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 0.1rem;
    line-height: 1.1rem;
    color: #4b4b4b;
}



.coursepage-container .similar-section {
    padding: 2em 1em;
}

.coursepage-container .similar-section .similar-courses-container {
    margin: 1.5em 0 0 0;
}

.coursepage-container .similar-section .similar-courses-container .similar-course {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    background: #F1F1F1;
    margin-bottom: 0.5em;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.coursepage-container .similar-section .similar-courses-container .similar-course:hover {
    background: #d7d7d7;
}

.coursepage-container .similar-section .similar-courses-container .similar-course .similar-p {
    font-size: 0.7rem;
    letter-spacing: 0.01rem;
}

.coursepage-container .similar-section .similar-courses-container .similar-course a {
    display: flex;
}

.coursepage-container .similar-section .similar-courses-container .similar-course .arrow-right {
    width: 1.3em;
    transition: all 0.3s ease-in-out;
}

.coursepage-container .similar-section .similar-courses-container .similar-course .arrow-right:hover {
    transform: scale(0.94);
}

.coursepage-container .button-divend {
    padding: 1em;
}

.coursepage-container .button-divend button {
    width: 100%;
}




/* WHY SECTION MEDIA QUERIES */

@media(min-width: 400px) {

    .coursepage-container .why-section {
        padding: 0em 1.5em 1.5em 1.5em;
    }
}

@media(min-width: 450px) {
    .coursepage-container .why-section .reasons {
        gap: 1.5em;
    }
}

@media(min-width: 500px) {
    .coursepage-container .why-section {
        padding: 0em 1.7em 1.7em 1.7em;
    }
}

@media(min-width: 600px) {
    .coursepage-container .why-section {
        padding: 2em 1.875em 1.7em 1.875em;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-title {
        font-size: .9rem;
    }


}

@media(min-width: 650px) {
    .coursepage-container .why-section .reasons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 2.5em;
    }
}

@media(min-width: 800px) {
    .coursepage-container .why-section {
        padding: 4em 3.125em 1.7em 3.125em;
    }

    .coursepage-container .hero-section .hero-left .back-arrow {
        top: -3.7em;
    }
}

@media(min-width: 1000px) {
    .coursepage-container .why-section {
        padding: 3em 5em 4em 5em;
    }
}

@media(min-width: 1200px) {
    .coursepage-container .why-section {
        padding: 4em 6.25em 4em 6.25em;
    }

    .coursepage-container .hero-section .hero-left .back-arrow {
        width: 2.5em;
        top: -4.7em;
    }
}

@media(min-width: 1451px) {
    .coursepage-container .why-section {
        padding: 5em 9.375em 5em 9.375em;
    }
}



/* END WHY SECTION MEDIA QUERIES */


/* ALL OTHER MEDIA QUERIES */

@media(min-width: 400px) {

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle7 {
        top: 12em;
        left: -6em;
        display: unset;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse5,
    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse6,
    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse7,
    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse8,
    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse9,
    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse10,
    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse11 {
        display: unset;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse5 {
        top: 10em;
        left: -2.5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse6 {
        top: 4em;
        left: -4em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse7 {
        top: 8em;
        right: -3em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse8 {
        top: 2.5em;
        right: -3em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse9 {
        top: 1.3em;
        left: -3em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse10 {
        top: 7em;
        left: -3em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse11 {
        top: -1em;
        right: -3em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x5,
    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x6,
    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x7,
    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x8,
    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x9,
    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x10,
    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x11 {
        display: unset;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x5 {
        top: 6em;
        left: -5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x6 {
        top: 5em;
        right: -3em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x7 {
        top: 1em;
        left: -5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x8 {
        top: 1.2em;
        right: -2.3em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x9 {
        top: -1em;
        right: -1.1em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x10 {
        top: 8em;
        right: -1.1em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x11 {
        top: 9.5em;
        left: -5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .discount-div {
        width: 17em;
        transform: rotate(-11.996deg) translateY(-0.5em);
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .discount-div .discount-h1 {
        font-size: 1.3rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title {
        width: 16em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div {
        padding-top: 6em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .product-div {
        margin-top: 2em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .product-div .product-title {
        font-size: 1.2rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .product-div .warning-p {
        font-size: .8rem;
    }

    .coursepage-container .offer-popup .buttons-div .proceed-btn,
    .coursepage-container .offer-popup .buttons-div .cancel-btn {
        width: 35%;
        font-size: .8rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .watermarks .watermark {
        font-size: 5rem;
    }

    .coursepage-container .section-title {
        font-size: 1.2rem;
    }

    .coursepage-container .hero-section {
        padding: 12em 1.5em 0em 1.5em;
    }

    .coursepage-container .hero-section .triangle {
        top: 10em;
        left: -6.5em;
    }

    .coursepage-container .hero-section .hero-left .hero-h1 span.trademark {
        font-size: 1.3rem;
        display: inline-block;
    }

    .coursepage-container .hero-section .hero-left .hero-ul li .point-span {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .coursepage-container .enquire-btn {
        border: 2px solid var(--secondary);
    }

    .coursepage-container .overview-section {
        padding: 2em 1.5em;
    }

    .coursepage-container .overview-section .overview-p {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .coursepage-container .detailed-section .detailed-nav {
        padding: 1em 1.5em;
    }

    .coursepage-container .detailed-section .detailed-body {
        padding: 1em 1.5em;
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-p {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-ul li {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .coursepage-container .detailed-section .detailed-body .objectives-div .objectives-p {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .coursepage-container .detailed-section .detailed-body .objectives-div .objectives-ul li {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .coursepage-container .detailed-section .detailed-body .exams-div .exams-p {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .coursepage-container .detailed-section .detailed-body .exams-div .exams-ul li {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .coursepage-container .why-section .reasons {
        gap: 1.5em;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-title {
        font-size: .9rem;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-p {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .coursepage-container .similar-section {
        padding: 2em 1.5em;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course .similar-p {
        font-weight: 500;
    }

    .coursepage-container .button-divend {
        padding: 1em 1.5em;
    }

}

@media(min-width: 450px) {
    .coursepage-container .hero-section .hero-left .hero-ul li {
        justify-content: unset;
        gap: 0.5em;
    }
}

@media(min-width: 500px) {
    .coursepage-container .detailed-section .detailed-nav ul li {
        font-size: .8rem;
        letter-spacing: .01rem;
    }

    .coursepage-container .hero-section .triangle {
        top: 7em;
    }
}

@media(min-width: 550px) {

    .coursepage-container .offer-popup .exit-icon {
        font-size: 2rem;
        top: .4em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title {
        width: 18em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div {
        padding-top: 5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .discount-div {
        width: 20em;
        transform: rotate(-11.996deg) translateY(-1em);
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .discount-div .discount-h1 {
        font-size: 1.5rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .product-div .product-title {
        font-size: 1.4rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle {
        width: 6em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle2 {
        left: -7em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle3 {
        left: -7em;
        top: 8.5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle1 {
        left: -5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle6 {
        right: -7em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle4 {
        right: -7em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle7 {
        top: 15em;
        left: -9em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x {
        width: .9em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse {
        width: .55em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse8 {
        right: -6em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse10 {
        left: -4em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x3 {
        top: -1em;
        right: -1.3em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x8 {
        right: -4em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x5 {
        left: -7em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x9 {
        right: -6em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .dot-frame {
        width: 4em;
        top: -2.5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame1 {
        display: unset;
        width: 4em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame3,
    .coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame4 {
        display: unset;
        width: 4em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame3 {
        top: 17em;
        left: 1em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame4 {
        top: 14em;
        right: 1em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .product-div .warning-p {
        font-size: .9rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .watermarks .watermark.watermark3 {
        top: 4.5em;
        left: -1.5em;
    }

    .coursepage-container .hero-section .triangle {
        width: 29em;
        height: 29em;
        top: 10em;
        left: -16.5em;
    }

    .coursepage-container .detailed-section .triangle {
        width: 29em;
        height: 29em;
        top: 10em;
        right: -17em;
    }
}

@media(min-width: 600px) {

    .coursepage-container .section-title {
        font-size: 1.4rem;
    }

    .coursepage-container .hero-section {
        padding: 13em 1.875em 0em 1.875em;
    }

    .coursepage-container .hero-section .hero-left .hero-h1 {
        font-size: 1.75rem;
    }

    .coursepage-container .enquire-btn {
        width: 50vw;
    }

    .coursepage-container .overview-section {
        padding: 3em 1.875em;
    }

    .coursepage-container .overview-section .overview-p {
        line-height: 1.5rem;
        font-size: 0.7rem;
    }


    .coursepage-container .detailed-section {
        display: flex;
    }

    .coursepage-container .detailed-section .detailed-nav {
        padding: 2em 1em 2em 1.875em;
        height: 25em;
        width: 30%;
    }

    .coursepage-container .detailed-section .detailed-nav ul {
        display: flex;
        flex-direction: column;
        gap: unset;
        justify-content: space-between;
        height: 100%;
    }

    .coursepage-container .detailed-section .detailed-nav ul li {
        font-size: 0.75rem;
    }

    .coursepage-container .detailed-section .detailed-nav ul li::before {
        width: 0.22em;
        height: 0;
        top: 0;
        left: -0.8em;
    }

    .coursepage-container .detailed-section .detailed-nav ul li.active::before {
        width: 0.22em;
    }

    .coursepage-container .detailed-section .detailed-nav ul li:hover::before {
        height: 100%;
        width: 0.22em;
    }

    .coursepage-container .detailed-section .detailed-nav ul li.active::before {
        height: 100%;
    }

    .coursepage-container .detailed-section .detailed-body {
        padding: 0.5em 1.875em 0 1em;
        width: 70%;
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-p {
        font-size: 0.7rem;
        line-height: 1.5rem;
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-ul li {
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
    }

    .coursepage-container .detailed-section .detailed-body .objectives-div .objectives-p {
        font-size: 0.7rem;
        line-height: 1.5rem;
    }

    .coursepage-container .detailed-section .detailed-body .objectives-div .objectives-ul li {
        font-size: 0.7rem;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .coursepage-container .detailed-section .detailed-body .exams-div .exams-p {
        font-size: 0.7rem;
        line-height: 1.5rem;
    }

    .coursepage-container .detailed-section .detailed-body .exams-div .exams-ul li {
        font-size: 0.7rem;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-title {
        font-size: 1.1rem;
    }

    .coursepage-container .similar-section {
        padding: 2em 1.875em;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course .similar-p {
        font-size: .8rem;
    }

    .coursepage-container .button-divend {
        padding: 1em 1.875em;
    }

}


@media(min-width: 650px) {

    .coursepage-container .hero-section .hero-left .hero-ul li .point-span {
        font-size: .75rem;
        line-height: 1.5rem;
    }

    .coursepage-container .hero-section .hero-left .buttons-div {
        margin-bottom: 2em;
    }

    .coursepage-container .enquire-btn {
        font-size: .9rem;
        font-weight: 700;
    }

    .coursepage-container .overview-section .overview-p {
        font-size: .75rem;
        line-height: 1.5rem;
    }

    .coursepage-container .detailed-section .detailed-nav ul li {
        font-size: .85rem;
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-p {
        font-size: .75rem;
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-ul li {
        font-size: .75rem;
    }

    .coursepage-container .detailed-section .detailed-body .objectives-div .objectives-p {
        font-size: .75rem;
    }

    .coursepage-container .detailed-section .detailed-body .objectives-div .objectives-ul li {
        font-size: .75rem;
    }

    .coursepage-container .detailed-section .detailed-body .exams-div .exams-p {
        font-size: .75rem;
    }

    .coursepage-container .detailed-section .detailed-body .exams-div .exams-ul li {
        font-size: .75rem;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-p {
        font-size: .75rem;
        line-height: 1.5rem;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course {
        margin-bottom: .8em;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course .similar-p {
        font-size: 0.85rem;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course .arrow-right {
        width: 1.57em;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course {
        padding: 1em;
    }
}

@media(min-width: 700px) {

    .coursepage-container .offer-popup .buttons-div .proceed-btn,
    .coursepage-container .offer-popup .buttons-div .cancel-btn {
        width: 13em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title {
        width: 20em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle2 {
        left: -11em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle3 {
        left: -11em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle1 {
        left: -6em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle4 {
        right: -11em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle6 {
        right: -11em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle5 {
        right: -7em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x1 {
        left: -3.5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x2 {
        top: 7.5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x7 {
        left: -8em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x8 {
        right: -1.5em;
        top: 6em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x5 {
        left: -10em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x6 {
        top: 4em;
        right: -8em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x10 {
        top: 10em;
        right: -2em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x11 {
        top: 10em;
        left: -7em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse1 {
        left: -2em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse3 {
        top: 5em;
        right: -4em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse6 {
        left: -7em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse8 {
        right: -11em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse5 {
        top: 14em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse2 {
        top: 10em;
        left: -3em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse4 {
        top: 1em;
        right: -3.5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse7 {
        right: -5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse9 {
        top: 14em;
        left: -9em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse11 {
        top: -1em;
        right: -9em;
    }

    .coursepage-container .hero-section {
        display: flex;
    }

    .coursepage-container .hero-section .hero-left {
        width: 59vw;
    }

    .coursepage-container .hero-section .hero-left .hero-h1 {
        font-size: 1.85rem;
    }

    .coursepage-container .hero-section .hero-left .hero-h1 span.trademark {
        font-size: 1.5rem;
    }

    .coursepage-container .enquire-btn {
        width: 90%;
    }

    .coursepage-container .hero-section .hero-right img.course-hero-img {
        display: block;
        width: 17em;
        position: absolute;
        top: 9em;
        right: -1em;
    }

    .coursepage-container .similar-section {
        padding: 3em 1.875em 2em 1.875em;
    }


}



@media(min-width: 750px) {
    .coursepage-container .similar-section {
        padding: 3em 1.875em;
    }

    .coursepage-container .hero-section .triangle {
        width: 32em;
        height: 32em;
        top: 12em;
        left: -18.5em;
    }

    .coursepage-container .detailed-section .triangle {
        width: 32em;
        height: 32em;
        right: -18em;
    }

}


@media(min-width: 800px) {
    .coursepage-container .hero-section {
        padding: 12em 3.125em 2em 3.125em;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .coursepage-container .hero-section .hero-left .buttons-div {
        margin-bottom: 0;
    }

    .coursepage-container .hero-section .hero-right img.course-hero-img {
        width: 19em;
    }

    .coursepage-container .overview-section {
        padding: 3em 3.125em;
    }

    .coursepage-container .detailed-section .detailed-nav {
        width: 25%;
        padding: 2em 1em 2em 3.125em;
    }

    .coursepage-container .detailed-section .detailed-body {
        padding: 0.5em 3.125em 0 1.5em;
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-ul li {
        margin-left: .2rem;
        padding-right: 1em;
    }

    .coursepage-container .similar-section {
        padding: 3em 3.125em;
    }

    .coursepage-container .button-divend {
        padding: 1em 3.125em;
    }


}


@media(min-width: 850px) {

    .coursepage-container .hero-section {
        padding: 12em 3.125em 2em 3.125em;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-title {
        font-size: 1rem;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-p {
        font-size: .8rem;
        letter-spacing: 0.02rem;
        margin-top: 0.1rem;
    }

    .coursepage-container .similar-section .similar-courses-container {
        margin: 2em 0;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course .similar-p {
        font-size: 0.8rem;
    }


}


@media(min-width: 900px) {

    .coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame3 {
        left: 4em;
    }

    .coursepage-container .offer-popup .offer-wrapper .watermarks .watermark {
        font-size: 7rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame1 {
        left: 2em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle2 {
        left: -14em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle4 {
        right: -13em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle5 {
        top: 4em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .narrow-circle.narrow-circle6 {
        top: 8em;
        right: -13em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x1 {
        left: -5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x7 {
        left: -13em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x10 {
        top: 12em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x5 {
        left: -14em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x6 {
        top: 6em;
        right: -8em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x8 {
        right: -3.5em;
        top: 8em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .x.x9 {
        right: -8em;
        top: 12em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse4 {
        right: -4.5em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse6 {
        left: -7em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse7 {
        top: 6em;
        right: -15em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse9 {
        top: 15em;
        left: -13em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse10 {
        left: -10em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse5 {
        left: -7em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title-div .ellipse.ellipse3 {
        top: 5.6em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame4 {
        right: 12em;
    }

    .coursepage-container .hero-section .hero-left .hero-h1 {
        font-size: 2.1rem;
    }

    .coursepage-container .hero-section .hero-left .hero-h1 span.trademark {
        font-size: 1.7rem;
    }

    .coursepage-container .hero-section .hero-left .hero-ul li .point-span {
        font-size: .9rem;
        letter-spacing: .015rem;
        line-height: 1.5rem;
        font-weight: unset;
    }

    .coursepage-container .hero-section .hero-left .hero-ul li .bullet-div {
        height: .9em;
        width: .9em;
        transform: translateY(9px);
    }

    .coursepage-container .hero-section .hero-right img.course-hero-img {
        width: 21em;
    }

    .coursepage-container .hero-section .triangle {
        width: 35em;
        height: 35em;
        top: 13em;
        left: -19.5em;
    }

    .coursepage-container .detailed-section .triangle {
        width: 35em;
        height: 35em;
        right: -20em;
    }

    .coursepage-container .section-title {
        font-size: 2rem;
        line-height: 2rem;
    }

    .coursepage-container .overview-section .overview-p {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: .015rem;
        margin-top: 1rem;
    }


    .coursepage-container .detailed-section .detailed-nav ul li {
        font-size: 1rem;
        letter-spacing: 0.02rem;
    }


    .coursepage-container .detailed-section .detailed-body .audience-div .audience-p {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: .015rem;
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-ul li {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: .015rem;
    }

    .coursepage-container .detailed-section .detailed-body .prerequisites-div .requisites-p {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: .015rem;
    }

    .coursepage-container .detailed-section .detailed-body .prerequisites-div .requisites-ul li {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: .015rem;
    }

    .coursepage-container .detailed-section .detailed-body .objectives-div .objectives-p {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: .015rem;
    }

    .coursepage-container .detailed-section .detailed-body .objectives-div .objectives-ul li {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: .015rem;
    }

    .coursepage-container .detailed-section .detailed-body .exams-div .exams-p {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: .015rem;
    }

    .coursepage-container .detailed-section .detailed-body .exams-div .exams-ul li {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: .015rem;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-title {
        font-size: 1.3rem;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-p {
        font-size: 1rem;
        line-height: 1.8rem;
        letter-spacing: .015rem;
    }

    .coursepage-container .similar-section {
        padding: 3.5em 3.125em 3em 3.125em;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course .similar-p {
        font-size: 1.1rem;
    }
}

@media(min-width: 950px) {

    .coursepage-container .hero-section {
        padding: 13em 3.125em 2em 3.125em;
    }

    .coursepage-container .hero-section .hero-left {
        width: 60%;
    }

    .coursepage-container .enquire-btn {
        padding: 0.4rem 1rem;
    }

    .coursepage-container .overview-section .overview-p {
        margin-top: .9rem;
    }
}

@media(min-width: 1000px) {
    .coursepage-container .hero-section {
        padding: 13em 5em 4em 5em;
    }

    .coursepage-container .hero-section .hero-right img.course-hero-img {
        width: 24em;
    }

    .coursepage-container .overview-section {
        padding: 3em 5em;
    }

    .coursepage-container .detailed-section .detailed-nav {
        padding: 2em 1em 2em 5em;
    }

    .coursepage-container .detailed-section .detailed-body {
        padding: 0.5em 5em 0 2em;
    }

    .coursepage-container .similar-section {
        padding: 1.5em 5em;
    }

    .coursepage-container .button-divend {
        padding: 1em 5em;
    }
}

@media(min-width: 1100px) {


    .coursepage-container .hero-section .hero-right img.course-hero-img {
        top: 16em;
    }

    .coursepage-container .offer-popup .buttons-div .proceed-btn,
    .coursepage-container .offer-popup .buttons-div .cancel-btn {
        font-size: 1rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame3 {
        left: 10em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .discount-div {
        width: 23em;
        transform: rotate(-11.996deg) translateY(-2em);
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .discount-div .discount-h1 {
        font-size: 2rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title {
        width: 24em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .product-div {
        margin-top: 1em;
    }

    .coursepage-container .offer-popup .offer-wrapper .watermarks .watermark {
        font-size: 9rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .watermarks .watermark.watermark2 {
        top: 2em;
    }

    .coursepage-container .offer-popup .offer-wrapper .watermarks .watermark.watermark3 {
        top: 2.2em;
    }

    .coursepage-container .hero-section {
        padding: 14em 5em 4em 5em;
    }

    .coursepage-container .hero-section .triangle {
        width: 38em;
        height: 38em;
        top: 16em;
    }

    .coursepage-container .detailed-section .triangle {
        width: 38em;
        height: 38em;
        right: -22em;
    }

    .coursepage-container .hero-section .hero-left {
        width: 66%;
    }

    .coursepage-container .hero-section .hero-left .hero-h1 {
        font-size: 2.5rem;
    }

    .coursepage-container .hero-section .hero-left .hero-ul {
        gap: 1.3em;
    }

    .coursepage-container .hero-section .hero-left .hero-ul li {
        gap: .8em;
    }

    .coursepage-container .hero-section .hero-left .hero-ul li .bullet-div {
        height: 1em;
        width: 1em;
    }

    .coursepage-container .hero-section .hero-left .hero-ul li .point-span {
        font-size: 1.1rem;
        line-height: 1.8rem;
        letter-spacing: .02rem;
    }

    .coursepage-container .enquire-btn {
        padding: 0.6rem 1rem;
        font-size: 1.15rem;
        width: 60%;
    }

    .coursepage-container .hero-section .hero-right img.course-hero-img {
        width: 26em;
    }

    .coursepage-container .section-title {
        font-size: 2.3rem;
        font-weight: 900;
        line-height: 2.5rem;
    }

    .coursepage-container .overview-section .overview-p {
        font-size: 1.15rem;
        line-height: 2.2rem;
    }

    .coursepage-container .detailed-section .detailed-nav {
        width: 30%;
    }

    .coursepage-container .detailed-section .detailed-nav ul li {
        font-size: 1.35rem;
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-p {
        font-size: 1.15rem;
        line-height: 2.2rem;
        margin-top: .9rem;
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-ul li {
        font-size: 1.15rem;
        line-height: 2.2rem;
    }

    .coursepage-container .detailed-section .detailed-body .objectives-div .objectives-p {
        font-size: 1.15rem;
        line-height: 2.2rem;
        margin-top: .9rem;

    }

    .coursepage-container .detailed-section .detailed-body .objectives-div .objectives-ul li {
        font-size: 1.15rem;
        line-height: 2.2rem;
    }

    .coursepage-container .detailed-section .detailed-body .exams-div .exams-p {
        font-size: 1.15rem;
        line-height: 2.2rem;
        margin-top: .9rem;

    }

    .coursepage-container .detailed-section .detailed-body .exams-div .exams-ul li {
        font-size: 1.15rem;
        line-height: 2.2rem;
    }

    .coursepage-container .why-section .reasons {
        margin-top: 3em;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-title {
        font-size: 1.5rem;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-p {
        font-size: 1.15rem;
        line-height: 1.9rem;
    }

    .coursepage-container .why-section .reasons .reason img {
        width: 5em;
    }

    .coursepage-container .similar-section .similar-courses-container {
        margin: 2.5em 0;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course .similar-p {
        font-size: 1.3rem;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course .arrow-right {
        width: 2.3em;
    }
}

@media(min-width: 1150px) {
    .coursepage-container .overview-section {
        padding: 3em 5em 5em 5em;
    }

    .coursepage-container .why-section {
        padding: 4.5em 5em 4em 5em;
    }

    .coursepage-container .similar-section {
        padding: 2.5em 5em 1.5em 5em;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course {
        padding: 1em 1.5em;
    }
}

@media(min-width: 1200px) {

    .coursepage-container .offer-popup .offer-wrapper .centre-div .product-div .product-title {
        font-size: 1.6rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .product-div .warning-p {
        font-size: 1rem;
        margin-top: .3rem;
    }

    .coursepage-container .hero-section {
        padding: 16em 6.25em 4em 6.25em;
    }

    .coursepage-container .hero-section .hero-left .hero-h1 span.trademark {
        font-size: 2rem;
        transform: translateY(-.3em);
    }

    .coursepage-container .hero-section .hero-right img.course-hero-img {
        width: 30em;
    }

    .coursepage-container .enquire-btn {
        width: 80%;
    }

    .coursepage-container .overview-section {
        padding: 3em 6.25em;
    }

    .coursepage-container .detailed-section .detailed-nav {
        padding: 2em 1em 2em 6.25em;
    }

    .coursepage-container .detailed-section .detailed-body {
        padding: 0.5em 6.25em 0 2em;
    }

    .coursepage-container .similar-section {
        padding: 3em 6.25em;
    }

    .coursepage-container .button-divend {
        padding: 1em 6.25em;
    }

}

@media(min-width: 1250px) {
    .coursepage-container .why-section .reasons .reason .reason-content .reason-p {
        width: 80%;
        margin-top: 0.4rem;
    }
}

@media(min-width: 1300px) {
    .coursepage-container .hero-section .triangle {
        width: 42em;
        height: 42em;
        top: 18em;
        left: -21em;
    }

    .coursepage-container .detailed-section .triangle {
        width: 42em;
        height: 42em;
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-ul {
        gap: .3em;
    }
}

@media(min-width: 1350px) {

    .coursepage-container .hero-section {
        padding: 16em 6.25em 6em 6.25em;
    }

    .coursepage-container .hero-section .hero-left .hero-h1 {
        font-size: 2.5rem;
    }

    .coursepage-container .detailed-section {
        margin: 4em 0 3em 0;
    }

    .coursepage-container .similar-section {
        padding: 4em 6.25em;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course .arrow-right {
        width: 2em;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course {
        margin-bottom: .8em;
    }

}

@media(min-width: 1400px) {

    .coursepage-container .offer-popup .offer-wrapper .offer-title {
        width: 27em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .discount-div {
        width: 28em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .discount-div .discount-h1 {
        font-size: 2.4rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .product-div .product-title {
        font-size: 1.9rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .product-div .warning-p {
        font-size: 1.2rem;
        margin-top: .5rem;
    }

    .coursepage-container .offer-popup .buttons-div .proceed-btn,
    .coursepage-container .offer-popup .buttons-div .cancel-btn {
        font-size: 1.3rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame4 {
        right: 17em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .elements .dot-frame.dot-frame3 {
        left: 16em;
    }

    .coursepage-container .hero-section .triangle {
        top: 19em;
    }

    .coursepage-container .hero-section .hero-left {
        width: 70%;
    }

    .coursepage-container .hero-section .hero-left .hero-h1 {
        font-size: 3rem;
    }

    .coursepage-container .hero-section .hero-left .hero-ul {
        gap: 1.3em;
    }

    .coursepage-container .hero-section .hero-left .hero-ul li .point-span {
        font-size: 1.3rem;
    }

    .coursepage-container .hero-section .hero-right img.course-hero-img {
        width: 34em;
    }

    .coursepage-container .section-title {
        font-size: 2.8rem;
        line-height: 3rem;
    }

    .coursepage-container .overview-section .overview-p {
        font-size: 1.3rem;
        letter-spacing: .02rem;
        line-height: 2.5rem;
    }

    .coursepage-container .detailed-section .detailed-body .audience-div .audience-p {
        font-size: 1.3rem;
        letter-spacing: .02rem;
        line-height: 2.5rem;
    }


    .coursepage-container .detailed-section .detailed-body .audience-div .audience-ul li {
        font-size: 1.3rem;
        letter-spacing: .02rem;
        line-height: 2.5rem;
    }


    .coursepage-container .detailed-section .detailed-body .objectives-div .objectives-p {
        font-size: 1.3rem;
        letter-spacing: .02rem;
        line-height: 2.5rem;
    }

    .coursepage-container .detailed-section .detailed-body .objectives-div .objectives-ul li {
        font-size: 1.3rem;
        letter-spacing: .02rem;
        line-height: 2.5rem;
    }

    .coursepage-container .detailed-section .detailed-body .exams-div .exams-p {
        font-size: 1.3rem;
        letter-spacing: .02rem;
        line-height: 2.5rem;
    }

    .coursepage-container .detailed-section .detailed-body .exams-div .exams-ul li {
        font-size: 1.3rem;
        letter-spacing: .02rem;
        line-height: 2.5rem;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-title {
        font-size: 1.8rem;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-p {
        font-size: 1.3rem;
        letter-spacing: .02rem;
        line-height: 2.3rem;
    }

    .coursepage-container .similar-section {
        padding: 5em 6.25em;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course {
        margin-bottom: 1.5em;
        padding: 1.5em;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course .similar-p {
        font-size: 1.5rem;
    }

    .coursepage-container .similar-section .similar-courses-container .similar-course .arrow-right {
        width: 3em;
    }
}


@media(min-width: 1451px) {
    .coursepage-container .hero-section {
        padding: 16em 9.375em 9em 9.375em;
    }

    .coursepage-container .hero-section .triangle {
        width: 47em;
        height: 47em;
        top: 19em;
        left: -23em;
    }

    .coursepage-container .detailed-section .triangle {
        width: 47em;
        height: 47em;
        right: -26em;
    }

    .coursepage-container .overview-section {
        padding: 4em 9.375em;
    }

    .coursepage-container .detailed-section .detailed-nav {
        padding: 2em 1em 2em 9.375em;
    }

    .coursepage-container .detailed-section .detailed-body {
        padding: 0.5em 9.375em 0 2em;
    }

    .coursepage-container .similar-section {
        padding: 3em 9.375em;
    }

    .coursepage-container .button-divend {
        padding: 1em 9.375em;
    }

}

@media(min-width: 1500px) {

    .coursepage-container .hero-section {
        padding: 18em 9.375em 9em 9.375em;
    }

    .coursepage-container .hero-section .triangle {
        top: 21em;
    }

    .coursepage-container .offer-popup .offer-wrapper .offer-title {
        width: 34em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .discount-div {
        width: 31em;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .discount-div .discount-h1 {
        font-size: 2.7rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .product-div .product-title {
        font-size: 2.3rem;
    }

    .coursepage-container .offer-popup .offer-wrapper .centre-div .product-div .warning-p {
        font-size: 1.5rem;
    }

    .coursepage-container .offer-popup .buttons-div {
        gap: 2em;
    }

    .coursepage-container .offer-popup .buttons-div .proceed-btn,
    .coursepage-container .offer-popup .buttons-div .cancel-btn {
        font-size: 1.5rem;
    }

    .coursepage-container .hero-section .hero-left .hero-h1 span.trademark {
        font-size: 2.7rem;
    }

    .coursepage-container .detailed-section .detailed-body {
        padding: 0.5em 9.375em 0 4em;
    }

    .coursepage-container .why-section .reasons .reason .reason-content .reason-p {
        width: 82%;
    }
}

@media(min-width: 1600px) {
    .coursepage-container .hero-section .triangle {
        top: 21em;
    }
}

/* ALL OTHER MEDIA QUERIES END */