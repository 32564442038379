@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prosto+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rozha+One&display=swap');


* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}

/* LAZY LOAD BLUR */

.blur-div {
    position: relative;
}

.blur-div::before {
    content: '';
    position: absolute;
    inset: 0;
    animation: pulse 5s infinite;
}

.blur-div.loaded::before {
    content: none;
}

.blur-div img {
    opacity: 0;
    transition: all .5s ease-in-out;
}

.blur-div.loaded {
    background: none !important;
}

.blur-div.loaded img {
    opacity: 1;
}

/* LAZY LOAD BLUR END*/

/* Fade In Css */

.about-container .appear-up {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-in-out;
}

.about-container .appear-up.active {
    opacity: 1;
    transform: translateX(0px);
}

.about-container .appear-down {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 1s ease-in-out;
}

.about-container .appear-down.active {
    opacity: 1;
    transform: translateY(0px);
}


.about-container .appear-right {
    opacity: 0;
    transform: translateX(100px);
    transition: all 1s ease-in-out;
}

.about-container .appear-right.active {
    opacity: 1;
    transform: translateX(0px);
}

.about-container .appear-left {
    opacity: 0;
    transform: translateX(-160px);
    transition: all 1s ease-in-out;
}

.about-container .appear-left.active {
    opacity: 1;
    transform: translateX(0px);
}

.about-container .appear-left-hero {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.about-container .appear-left-hero.active {
    opacity: 1;
    transform: translateX(0px);
}




/* Fade In Css End */

html {
    scroll-behavior: smooth;
}


.about-container {
    overflow-x: hidden;
    color: var(--black);
    font-family: 'Poppins', sans-serif;
}

.about-container .section-title {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: normal;
}

.about-container .hero-section {
    padding: 8em 1em 6em 1em;
    position: relative;
}

.about-container .hero-section .hero-left .hero-h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    position: relative;
    width: fit-content;
    line-height: 1.4rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.20);
}

.about-container .hero-section .hero-left .hero-h1 .transformation-span {
    color: var(--secondary);
}

.about-container .hero-section .hero-right .img-container {
    display: none;
}

.about-container .hero-section .hero-right .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-container .hero-section .hero-right .background-div {
    height: 9em;
    width: 50vw;
    position: absolute;
    background: #90D3F0;
    top: 6em;
    right: 0;
    z-index: -1;
    border-radius: 0.7em 0 0 0.7em;
}

.about-container .hero-section .hero-right .right-content {
    display: none;
}

.about-container .wedo-section .wedo-right {
    padding: 1em;
}

.about-container .wedo-section .wedo-right .wedo-p {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 0.5rem;
    line-height: 1.1rem;
    color: #4b4b4b;
}

.about-container .principles-section {
    padding: 3em 1em;
}


.about-container .principles-section .principles-abstract {
    display: none;
}

.about-container .principles-section .principles-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.about-container .principles-section .principles-container .principle {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-container .principles-section .principles-container .principle .principle-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-container .principles-section .principles-container .principle .principle-right .principle-right-title {
    text-align: center;
    margin: 0.3em 0;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.20);
}

.about-container .principles-section .principles-container .principle .principle-right .principle-right-p {
    text-align: center;
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    line-height: 1.1rem;
    color: #4b4b4b;
}

.about-container .principles-section .principles-container .principle.principle1 img {
    width: 4em;
}

.about-container .principles-section .principles-container .principle.principle2 img {
    width: 4.5em;
}

.about-container .principles-section .principles-container .principle.principle3 img {
    width: 5.1em;
}

.about-container .objectives-section {
    background: #ffffff;
    padding: 1.5em 1em 4em 1em;
    position: relative;
}

.about-container .objectives-section .objectives-title {
    color: var(--black);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: normal;
    text-decoration: underline;
    text-align: center;
}

.about-container .objectives-section .canvas-wrapper {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    position: relative;
    z-index: 2;
}

.about-container .objectives-section .canvas-wrapper .canvas {
    position: relative;
    display: flex;
    justify-content: center;
    transition: all .5s ease-in-out;
}

.about-container .objectives-section .canvas-wrapper .canvas:hover {
    cursor: pointer;
    transform: translateY(-.3em);
}

.about-container .objectives-section .canvas-wrapper .canvas img {
    width: 100%;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.about-container .objectives-section .canvas-wrapper .canvas .objective-h3 {
    font-size: .7rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18%;
    font-family: 'Poppins', sans-serif;
    line-height: .8rem;
    font-weight: 600;
    letter-spacing: .01rem;
    color: #ffffff;
}

.about-container .objectives-section .canvas-wrapper .canvas.canvas1 .objective-h3 {
    width: 60%;
}

.about-container .objectives-section .doodles-div .page-doodle {
    width: 7em;
    position: absolute;
    top: 84%;
    left: -2em;
}

.about-container .objectives-section .doodles-div .welcome-doodle {
    width: 7em;
    position: absolute;
    top: 85%;
    right: -2em;
}

.about-container .values-section {
    padding: 3.5em 1em 0em 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-container .values-section .img-container {
    transform: translateY(6px);
}

.about-container .values-section .section-title {
    text-align: center;
}

.about-container .values-section .core-values {
    width: 18em;
}

.about-container .footer-container {
    margin-top: 0;
}





/* MEDIA QUERIES */

@media(min-width: 400px) {
    .about-container .hero-section {
        padding: 9.5em 1.5em 14em 1.5em;
    }

    .about-container .hero-section .hero-left .hero-h1 {
        font-size: 1.4rem;
        line-height: 1.5rem;
    }

    .about-container .hero-section .hero-right .img-container {
        display: block;
        width: 45vw;
        margin-top: unset;
        height: 9em;
        position: absolute;
        left: 0;
        top: 14em;
        border-radius: 0 0.7em 0.7em 0;
    }

    .about-container .hero-section .hero-right .img-container img {
        border-radius: 0 0.7em 0.7em 0;
    }

    .about-container .hero-section .hero-right .background-div {
        height: 9em;
        width: 45vw;
        top: 7em;
    }

    .about-container .hero-section .hero-right .right-content {
        position: absolute;
        right: 0;
        width: 50vw;
        top: 17em;
        padding-right: 1.5em;
        display: block;
    }

    .about-container .hero-section .hero-right .right-content .about-p {
        font-size: 0.7rem;
        letter-spacing: 0.01rem;
        line-height: 1.3rem;
        color: #4b4b4b;
    }



    .about-container .wedo-section {
        display: none;
    }

    .about-container .appear-left {
        transform: translateX(-132px);
    }

    .about-container .principles-section {
        padding: 2.5em 1.5em 3em 1.5em;
    }

    .about-container .principles-section .principles-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .about-container .principles-section .principles-container .principle.principle3 {
        grid-column: 1 /span 2;
    }

    .about-container .principles-section .principles-container .principle .principle-right .principle-right-title {
        font-size: 1.2rem;
    }

    .about-container .principles-section .principles-container .principle.principle1 img {
        width: 6em;
    }

    .about-container .principles-section .principles-container .principle.principle2 img {
        width: 5em;
        margin-top: -1.5em;
    }

    .about-container .principles-section .principles-container .principle.principle3 img {
        width: 5.8em;
    }

    .about-container .principles-section .principles-container .principle .principle-right .principle-right-p {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .about-container .principles-section .principles-container .principle.principle3 .principle-right .principle-right-p {
        width: 70vw;
    }

    .about-container .objectives-section {
        padding: 1.9em 1.5em 6em 1.5em;
    }

    .about-container .objectives-section .objectives-title {
        font-size: 1.2rem;
    }

    .about-container .objectives-section .canvas-wrapper .canvas .objective-h3 {
        font-size: .85rem;
        line-height: 1rem;
    }

    .about-container .objectives-section .doodles-div .page-doodle {
        width: 9em;
        top: 79%;
    }

    .about-container .objectives-section .doodles-div .welcome-doodle {
        width: 9em;
        top: 79%;
        right: -2.5em;
    }

    .about-container .values-section .section-title {
        font-size: 1.2rem;
    }

    .about-container .values-section .core-values {
        width: 27em;
    }
}

@media(min-width: 450px) {
    .about-container .hero-section {
        padding: 10.5em 1.5em 13em 1.5em;
    }

    .about-container .hero-section .hero-right .background-div {
        top: 5em;
    }

    .about-container .hero-section .hero-right .right-content {
        top: 17.5em;
    }

    .about-container .hero-section .hero-right .img-container {
        top: 13em;
    }

    .about-container .hero-section .hero-right .background-div {
        top: 8em;
    }

    .about-container .hero-section .hero-right .img-container {
        top: 15em;
    }
}

@media(min-width: 500px) {
    .about-container .hero-section {
        padding: 10.5em 1.5em 14em 1.5em;
    }

    .about-container .hero-section .hero-right .img-container {
        top: 15em;
        height: 10em;
    }

    .about-container .hero-section .hero-right .background-div {
        height: 10em;
    }

    .about-container .hero-section .hero-left .hero-h1 {
        font-size: 1.7rem;
        line-height: 1.7rem;
    }

    .about-container .hero-section .hero-right .right-content {
        top: 19em;
    }


    .about-container .principles-section {
        padding: 0.5em 1.5em 3em 1.5em;
    }

    .about-container .objectives-section .canvas-wrapper .canvas img {
        width: 90%;
    }

    .about-container .objectives-section .canvas-wrapper .canvas .objective-h3 {
        left: 21%;
    }

    .about-container .objectives-section .doodles-div .page-doodle {
        width: 11em;
        top: 73%;
        left: -3em;
    }

    .about-container .objectives-section .doodles-div .welcome-doodle {
        width: 11em;
        top: 71%;
        right: -3em;
    }
}

@media(min-width: 550px) {
    .about-container .principles-section .principles-container .principle.principle2 img {
        width: 5.6em;
        margin-top: -2.1em;
    }

    .about-container .principles-section .principles-container .principle.principle3 img {
        width: 6.5em;
    }
}

@media(min-width: 600px) {
    .about-container .hero-section {
        padding: 10.5em 1.875em 17em 1.875em;
    }

    .about-container .hero-section .hero-right .right-content {
        width: 48vw;
        padding-right: 1.875em;
    }

    .about-container .hero-section .hero-right .right-content .about-p {
        line-height: 1.5rem;
    }

    .about-container .hero-section .hero-left .hero-h1 {
        font-weight: 900;
        text-align: center;
    }

    .about-container .principles-section {
        padding: 0.5em 1.875em 3em 1.875em;
    }

    .about-container .principles-section .principles-container .principle .principle-right .principle-right-title {
        font-size: 1.4rem;
    }

    .about-container .objectives-section {
        padding: 1.9em 1.875em 6em 1.875em;
    }

    .about-container .objectives-section .objectives-title {
        font-size: 1.4rem;
    }

    .about-container .objectives-section .canvas-wrapper .canvas .objective-h3 {
        font-size: 1rem;
    }

    .about-container .values-section .core-values {
        width: 33em;
    }

    .about-container .values-section .section-title {
        font-size: 1.4rem;
    }
}

@media(min-width: 650px) {
    .about-container .hero-section .hero-right .right-content .about-p {
        font-size: .75rem;
    }

    .about-container .principles-section .principles-container .principle .principle-right .principle-right-p {
        font-size: .75rem;
    }
}

@media(min-width:700px) {
    .about-container .hero-section {
        padding: 11em 1.875em 16em 1.875em;
    }

    .about-container .hero-section .hero-left .hero-h1 {
        font-size: 2.2rem;
        line-height: 2.2rem;
    }

    .about-container .hero-section .hero-right .img-container {
        top: 17em;
        height: 11em;
    }

    .about-container .hero-section .hero-right .background-div {
        height: 11em;
    }

    .about-container .hero-section .hero-right .right-content {
        top: 20em;
    }

    .about-container .principles-section {
        padding: 2em 1.875em 3em 1.875em;
    }

    .about-container .principles-section .principles-container {
        gap: 2em;
    }

    .about-container .principles-section .principles-container .principle.principle1 img {
        width: 8em;
    }

    .about-container .principles-section .principles-container .principle.principle2 img {
        width: 7.2em;
        margin-top: -2.6em;
    }

    .about-container .principles-section .principles-container .principle.principle3 img {
        width: 8em;
    }

    .about-container .objectives-section .canvas-wrapper {
        gap: 1.5em;
    }

    .about-container .objectives-section .canvas-wrapper .canvas {
        width: fit-content;
    }

    .about-container .objectives-section .canvas-wrapper .canvas img {
        width: 36em;
    }

    .about-container .objectives-section .canvas-wrapper .canvas .objective-h3 {
        left: 17%;
        font-size: 1.1rem;
    }

    .about-container .objectives-section .canvas-wrapper .canvas.canvas1 .objective-h3 {
        width: unset;
    }

}

@media(min-width: 750px) {
    .about-container .hero-section {
        padding: 11em 1.875em 18em 1.875em;
    }

    .about-container .hero-section .hero-right .img-container {
        top: 17em;
    }

    .about-container .hero-section .hero-right .background-div {
        top: 8em;
    }

    .about-container .hero-section .hero-left .hero-h1 {
        font-size: 2.4rem;
        line-height: 2.4rem;
    }

    .about-container .hero-section .hero-right .right-content .about-p {
        line-height: 1.7rem;
    }

    .about-container .principles-section {
        padding: 0.5em 1.875em 4em 1.875em;
    }

    .about-container .principles-section .principles-container .principle.principle1 .principle-right .principle-right-p {
        width: 70%;
    }

    .about-container .principles-section {
        position: relative;
    }

    .about-container .principles-section .principles-abstract {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 45vw;
        height: 100%;
        object-fit: cover;
        user-select: none;
        z-index: -1;
    }

    .about-container .objectives-section {
        padding: 1.9em 1.875em 8em 1.875em;
    }

    .about-container .objectives-section .operate-h3 {
        font-size: 1.5rem;
    }

    .about-container .objectives-section .delight-h3 {
        font-size: 1.5rem;
    }

    .about-container .objectives-section .develop-h3 {
        font-size: 1.5rem;
    }

    .about-container .objectives-section .profitable-h3 {
        font-size: 1rem;
    }

    .about-container .objectives-section .grow-h3 {
        font-size: 1.7rem;
    }

    .about-container .objectives-section .doodles-div .page-doodle {
        width: 13em;
    }

    .about-container .objectives-section .doodles-div .welcome-doodle {
        width: 13em;
    }

    .about-container .values-section .core-values {
        width: 37em;
    }
}

@media(min-width: 800px) {
    .about-container .hero-section {
        padding: 13em 3.125em 18em 3.125em;
    }

    .about-container .hero-section .hero-right .right-content {
        width: 47vw;
        padding-right: 3.125em;
    }

    .about-container .hero-section .hero-right .img-container {
        top: 18em;
    }

    .about-container .hero-section .hero-right .background-div {
        top: 9em;
    }

    .about-container .hero-section .hero-right .right-content {
        top: 21em;
    }

    .about-container .principles-section {
        padding: 0.5em 3.125em 4em 3.125em;
    }

    .about-container .objectives-section {
        padding: 1.9em 3.125em 8em 3.125em;
    }

    .about-container .values-section {
        padding: 3.5em 3.125em 0em 3.125em;
    }
}

@media(min-width: 900px) {


    .about-container .hero-section .hero-left .hero-h1 {
        font-size: 2.7rem;
        line-height: 2.7rem;
    }

    .about-container .hero-section .hero-right .right-content .about-p {
        font-size: 1rem;
        letter-spacing: .015rem;
        line-height: 2rem;
    }

    .about-container .hero-section .hero-right .img-container {
        top: 19em;
        height: 12em;
    }

    .about-container .hero-section .hero-right .background-div {
        height: 12em;
        top: 9em;
    }

    .about-container .hero-section .hero-right .right-content {
        top: 21em;
        width: 49vw;
    }

    .about-container .principles-section {
        padding: 3em 3.125em 4em 3.125em;
    }

    .about-container .principles-section .principles-container .principle .principle-right .principle-right-p {
        font-size: 1rem;
        letter-spacing: .015rem;
        line-height: 2rem;
        width: 88%;
    }

    .about-container .principles-section .principles-container .principle .principle-right .principle-right-title {
        font-size: 2rem;
    }

    .about-container .objectives-section .objectives-title {
        font-size: 2rem;
    }

    .about-container .objectives-section {
        padding: 2em 3.125em 2em 3.125em;
    }

    .about-container .objectives-section .operate-h3 {
        font-size: 1.7rem;
        width: 40%;
        text-align: center;
    }

    .about-container .objectives-section .delight-h3 {
        font-size: 1.7rem;
        margin-top: -3rem;
    }

    .about-container .objectives-section .develop-h3 {
        font-size: 1.7rem;
        margin-top: 4.5rem;
        width: 25%;
        margin-left: 13vw;
    }

    .about-container .objectives-section .profitable-h3 {
        font-size: 1.1rem;
        width: 30%;
        margin-top: -3.2em;
        margin-right: 12vw;
        line-height: 1.7rem;
    }

    .about-container .objectives-section .grow-h3 {
        font-size: 1.9rem;
        width: fit-content;
        margin: 3.5rem auto;
    }

    .about-container .objectives-section .doodles-div .page-doodle {
        width: 15em;
        top: 65%;
    }

    .about-container .objectives-section .doodles-div .welcome-doodle {
        width: 17em;
        top: 59%;
        right: -4em;
    }

    .about-container .values-section .section-title {
        font-size: 2rem;
    }
}

@media(min-width: 950px) {
    .about-container .principles-section .principles-container .principle.principle3 .principle-right .principle-right-p {
        width: 51vw;
    }
}

@media(min-width: 1000px) {
    .about-container .hero-section {
        padding: 13em 5em 20em 5em;
    }

    .about-container .hero-section .hero-right .right-content {
        padding-right: 5em;
        top: 22em;
    }

    .about-container .principles-section {
        padding: 3em 5em 4em 5em;
    }

    .about-container .objectives-section {
        padding: 3em 5em 2em 5em;
    }

    .about-container .objectives-section .objectives-title {
        margin-top: 1em;
    }

    .about-container .objectives-section .canvas-wrapper {
        margin-top: 3.5em;
    }

    .about-container .objectives-section .canvas-wrapper .canvas img {
        width: 41em;
    }

    .about-container .objectives-section .canvas-wrapper .canvas .objective-h3 {
        font-size: 1.3rem;
    }

    .about-container .objectives-section .doodles-div .welcome-doodle {
        width: 18em;
        top: 57%;
        right: -4em;
    }

    .about-container .objectives-section .doodles-div .page-doodle {
        width: 16em;
        top: 62%;
    }
}

@media(min-width: 1100px) {

    .about-container .hero-section {
        padding: 15em 5em 20em 5em;
    }

    .about-container .hero-section .hero-left .hero-h1 {
        font-size: 3.5rem;
        line-height: 3.4rem;
        font-weight: 900;
        letter-spacing: .05rem;
    }

    .about-container .hero-section .hero-right .img-container {
        top: 23em;
        height: 13em;
    }

    .about-container .hero-section .hero-right .right-content {
        top: 25em;
    }

    .about-container .hero-section .hero-right .right-content .about-p {
        font-size: 1.15rem;
        line-height: 2.2rem;
    }

    .about-container .hero-section .hero-right .background-div {
        height: 13em;
        top: 12em;
    }

    .about-container .principles-section .principles-container .principle .principle-right .principle-right-title {
        font-size: 2.3rem;
        font-weight: 900;
    }

    .about-container .principles-section .principles-container .principle .principle-right .principle-right-p {
        font-size: 1.15rem;
    }

    .about-container .objectives-section .objectives-title {
        font-size: 2.3rem;
        font-weight: 900;
    }

    .about-container .objectives-section .operate-h3 {
        font-size: 2rem;
    }

    .about-container .objectives-section .delight-h3 {
        font-size: 2rem;
    }

    .about-container .objectives-section .develop-h3 {
        font-size: 1.9rem;
    }

    .about-container .objectives-section .profitable-h3 {
        font-size: 1.3rem;
    }

    .about-container .objectives-section .grow-h3 {
        font-size: 2.2rem;
    }

    .about-container .objectives-section .doodles-div .page-doodle {
        width: 18em;
    }

    .about-container .objectives-section .doodles-div .welcome-doodle {
        width: 20em;
    }

    .about-container .values-section .section-title {
        font-size: 2.3rem;
        font-weight: 900;
    }
}

@media(min-width: 1200px) {
    .about-container .hero-section {
        padding: 15em 6.25em 22em 6.25em;
    }

    .about-container .hero-section .hero-right .right-content {
        padding-right: 6.25em;
        top: 26em;
    }

    .about-container .principles-section {
        padding: 3em 6.25em 4em 6.25em;
    }

    .about-container .principles-section .principles-container .principle.principle2 img {
        width: 9em;
    }

    .about-container .principles-section .principles-container .principle.principle1 img {
        width: 10em;
    }

    .about-container .principles-section .principles-container .principle.principle3 img {
        width: 11em;
    }

    .about-container .objectives-section {
        padding: 3em 6.25em 2em 6.25em;
    }

    .about-container .objectives-section .doodles-div .page-doodle {
        width: 20em;
    }

    .about-container .objectives-section .doodles-div .welcome-doodle {
        width: 21em;
        top: 55%;
    }

    .about-container .values-section .core-values {
        width: 45em;
    }
}

@media(min-width: 1250px) {
    .about-container .hero-section .hero-right .background-div {
        height: 14em;
    }

    .about-container .hero-section .hero-right .img-container {
        height: 14em;
        top: 24em;
    }

    .about-container .hero-section .hero-right .right-content {
        top: 27em;
    }

    .about-container .hero-section .hero-left .hero-h1 {
        font-size: 3.8rem;
        line-height: 3.6rem;
    }

    .about-container .principles-section .principles-abstract {
        width: 50vw;
    }
}

@media(min-width: 1350px) {
    .about-container .hero-section {
        padding: 15em 6.25em 22em 6.25em;
    }

    .about-container .hero-section .hero-left .hero-h1 {
        font-size: 4rem;
        line-height: 3.8rem;
    }

    .about-container .hero-section .hero-right .img-container {
        height: 15em;
        top: 24em;
        width: 48vw;
    }

    .about-container .hero-section .hero-right .background-div {
        height: 15em;
        top: 11em;
        width: 48vw;
    }
}

@media(min-width: 1400px) {

    .about-container .hero-section {
        padding: 16em 6.25em 22em 6.25em;
    }

    .about-container .hero-section .hero-right .background-div {
        top: 12em;
    }

    .about-container .hero-section .hero-right .img-container {
        top: 25em;
    }

    .about-container .hero-section .hero-right .right-content {
        top: 29em;
    }

    .about-container .principles-section {
        padding: 3em 6.25em 6em 6.25em;
    }

    .about-container .principles-section .principles-container .principle .principle-right .principle-right-title {
        font-size: 2.8rem;
    }

    .about-container .hero-section .hero-right .right-content .about-p {
        font-size: 1.3rem;
        line-height: 2.7rem;
    }


    .about-container .principles-section .principles-container .principle .principle-right .principle-right-p {
        font-size: 1.3rem;
        line-height: 2.4rem;
        width: 75%;
    }

    .about-container .objectives-section .objectives-title {
        font-size: 2.8rem;
    }

    .about-container .objectives-section .canvas-wrapper .canvas img {
        width: 50em;
    }

    .about-container .objectives-section .canvas-wrapper .canvas .objective-h3 {
        font-size: 1.6rem;
    }

    .about-container .objectives-section .doodles-div .page-doodle {
        width: 22em;
    }

    .about-container .objectives-section .doodles-div .welcome-doodle {
        width: 23em;
    }

    .about-container .values-section {
        padding: 4.5em 3.125em 0em 3.125em;
    }

    .about-container .values-section .section-title {
        font-size: 2.8rem;
    }

    .about-container .values-section .core-values {
        width: 54em;
    }
}

@media(min-width: 1451px) {
    .about-container .hero-section {
        padding: 17em 9.375em 27em 9.375em;
    }

    .about-container .hero-section .hero-right .img-container {
        height: 16em;
        top: 26em;
    }

    .about-container .hero-section .hero-right .background-div {
        height: 16em;
        top: 12em;
    }

    .about-container .hero-section .hero-right .right-content {
        top: 30em;
        padding-right: 9.375em;
    }

    .about-container .principles-section {
        padding: 3em 9.375em 6em 9.375em;
    }
}

@media(min-width: 1600px) {
    .about-container .objectives-section .canvas-wrapper {
        margin-top: 5em;
    }
}

/* MEDIA QUERIES END */