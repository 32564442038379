@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}

.createblog-container {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    color: var(--black);
}

.createblog-container .create-section {
    padding: 7em 1em 1em 1em;
}

.createblog-container .create-section .section-title {
    color: var(--black, #011B25);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: normal;
}

.createblog-container .create-section .create-right {
    margin-top: 2em;
}

.createblog-container .create-section .create-left .components-wrapper {
    margin-top: 1.5em;
}

.createblog-container .create-section .create-left .components-wrapper .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root .MuiAccordionSummary-content p {
    color: var(--black, #011B25);
    font-family: 'Poppins', sans-serif;
    font-size: .85rem;
}

.createblog-container .create-section .create-left .components-wrapper .css-v84d5j-MuiSvgIcon-root {
    width: .9em;
}

.createblog-container .create-section .create-left .components-wrapper .css-u2dpkp-MuiFormLabel-root-MuiInputLabel-root {
    font-family: "Poppins", sans-serif;
    font-size: .9rem;
}

.createblog-container .create-section .create-left .components-wrapper input {
    font-family: "Poppins", sans-serif;
    font-size: .9rem;
}

.createblog-container .create-section .create-left .components-wrapper form .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}

.createblog-container .create-section .create-left .components-wrapper form button {
    margin-top: 1em;
    width: 100%;
    font-size: .8rem;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
}

@media(min-width: 400px) {
    .createblog-container .create-section {
        padding: 7em 1.5em 1em 1.5em;
    }

    .createblog-container .create-section .section-title {
        font-size: 1.2rem;
    }
}

@media(min-width: 600px) {
    .createblog-container .create-section {
        padding: 9em 1.875em 1em 1.875em;
    }

    .createblog-container .create-section .section-title {
        font-size: 1.4rem;
    }

    .createblog-container .create-section .create-left .components-wrapper .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root .MuiAccordionSummary-content p {
        font-size: .95rem;
    }

    .createblog-container .create-section .create-left .components-wrapper input {
        font-size: 1rem;
    }

    .createblog-container .create-section .create-left .components-wrapper .css-u2dpkp-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 1rem;
    }
}

@media(min-width: 800px) {
    .createblog-container .create-section {
        padding: 12em 3.125em 1em 3.125em;
        display: flex;
        gap: 1em;
    }

    .createblog-container .create-section .create-right {
        margin-top: unset;
    }
}

@media(min-width: 900px) {
    .createblog-container .create-section .create-left {
        width: 50%;
    }

    .createblog-container .create-section .section-title {
        font-size: 1.5rem;
    }

    .createblog-container .create-section .create-left .components-wrapper form button {
        font-size: .95rem;
    }
}

@media(min-width: 1000px) {
    .createblog-container .create-section {
        padding: 12em 5em 1em 5em;
    }
}

@media(min-width: 1100px) {
    .createblog-container .create-section .section-title {
        font-size: 2rem;
    }

    .createblog-container .create-section .create-left .components-wrapper .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root .MuiAccordionSummary-content p {
        font-size: 1.1rem;
    }

    .createblog-container .create-section .create-left .components-wrapper input {
        font-size: 1.1rem;
    }

    .createblog-container .create-section .create-left .components-wrapper .css-u2dpkp-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 1.1rem;
    }
}

@media(min-width: 1200px) {
    .createblog-container .create-section {
        padding: 13em 6.25em 1em 6.25em;
        gap: 2em;
    }
}

@media(min-width: 1400px) {
    .createblog-container .create-section .section-title {
        font-size: 2.1rem;
    }

    .createblog-container .create-section .create-left .components-wrapper .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root .MuiAccordionSummary-content p {
        font-size: 1.3rem    ;
    }

    .createblog-container .create-section .create-left .components-wrapper input {
        font-size: 1.3rem;
    }

    .createblog-container .create-section .create-left .components-wrapper .css-u2dpkp-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 1.3rem;
    }

    .createblog-container .create-section .create-left .components-wrapper form button {
        font-size: 1.2rem;
    }

    .createblog-container .create-section .create-left .components-wrapper .css-v84d5j-MuiSvgIcon-root {
        font-size: 1.1rem;
    }
}

@media(min-width: 1451px) {

    .createblog-container .create-section .section-title {
        font-size: 2.3rem;
    }

    .createblog-container .create-section {
        padding: 13em 9.375em 1em 9.375em;
    }
}