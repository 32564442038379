@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}

.trainings-container {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    color: var(--black);
}

/* Fade In Css */

.trainings-container .appear-up {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-in-out;
}

.trainings-container .appear-up.active {
    opacity: 1;
    transform: translateX(0px);
}

.trainings-container .appear-down {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 1s ease-in-out;
}

.trainings-container .appear-down.active {
    opacity: 1;
    transform: translateY(0px);
}


.trainings-container .appear-right {
    opacity: 0;
    transform: translateX(52px);
    transition: all 1s ease-in-out;
}

.trainings-container .appear-right.active {
    opacity: 1;
    transform: translateX(0px);
}

.trainings-container .appear-left {
    opacity: 0;
    transform: translateX(-107px);
    transition: all 1s ease-in-out;
}

.trainings-container .appear-left.active {
    opacity: 1;
    transform: translateX(0px);
}

.trainings-container .appear-left-hero {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.trainings-container .appear-left-hero.active {
    opacity: 1;
    transform: translateX(0px);
}




/* Fade In Css End */





.trainings-container .hero-section {
    padding: 8em 1em 2em 1em;
    display: flex;
    position: relative;
}

.trainings-container .hero-section .hero-left .title-div {
    position: relative;
}

.trainings-container .hero-section .hero-left .hero-title {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.6rem;
    position: relative;
}

.trainings-container .hero-section .hero-left .hero-title span {
    color: var(--secondary);
}

.trainings-container .hero-section .hero-left .hero-title .scribble {
    position: absolute;
    width: 4.5em;
    top: 3.3em;
    left: 0;
}

.trainings-container .hero-section .hero-left .hero-p {
    margin: 2rem 0 1rem 0;
    font-size: .9rem;
    width: 70%;
    letter-spacing: .01rem;
    color: #4b4b4b;
}

.trainings-container .hero-section .hero-left .checkout {
    display: flex;
    align-items: center;  
    gap: .4em; 
    cursor: pointer;
    transition: transform .5s ease-in-out;
    transform-origin: center;
    text-decoration: none;
    color: var(--black);
}

.trainings-container .hero-section .hero-left .checkout:hover {
    transform: scale(.98);
}


.trainings-container .hero-section .hero-left .checkout span {
    font-size: .95rem;
    font-weight: 700;
}

.trainings-container .hero-section .hero-left .checkout img {
    width: 1.5em;
}

.trainings-container .hero-section .hero-right .hero-img-container {
    display: none;
}

.trainings-container .hero-section .hero-right .hero-small {
    width: 6em;
    position: absolute;
    top: 11em;
    right: 0;
}

.trainings-container .hero-section .hero-right .bottom-line {
    background: #D0F0E4;
    height: 0.3em;
    width: 100vw;
    position: absolute;
    left: 0;
    top: 22em;
    left: 0;
}

.trainings-container .courses-section {
    padding: 2em 1em 0 1em;
    position: relative;
}

.trainings-container .courses-section .courses-nav {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}

.trainings-container .courses-section .courses-nav .search-div {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--black);
    height: fit-content;
    padding-bottom: 0.3em;
    position: relative;
}

.trainings-container .courses-section .courses-nav .search-div input {
    font-family: 'Poppins', sans-serif;
    outline: none;
    border: none;
    width: 6.5rem;
    font-size: 0.65rem;
    background: transparent;
    font-size: .75rem;
}

.trainings-container .courses-section .courses-nav .search-div .search-icon {
    width: 0.8em;
}

.trainings-container .courses-section .courses-nav .search-div .search-list {
    position: absolute;
    top: 120%;
    background: #ffffff;
    box-shadow: -3px 4px 19px 0px rgba(0, 0, 0, 0.11), 3px -4px 19px 0px rgba(0, 0, 0, 0.11);
    padding: 1em;
    display: flex;
    flex-direction: column;
    width: 80vw;
    gap: .5em;
    border-radius: .3em;
    display: none;
}

.trainings-container .courses-section .courses-nav .search-div .search-list.active {
    display: block;
}

.trainings-container .courses-section .courses-nav .search-div .search-list a {
    text-decoration: none;
    color: var(--black);
    cursor: pointer;
    display: block;
    margin-bottom: .5em;
}

.trainings-container .courses-section .courses-nav .search-div .search-list .no-match {
    display: flex;
    align-items: center;
    gap: .5em;
}

.trainings-container .courses-section .courses-nav .search-div .search-list .no-match img {
    width: 2em;
}

.trainings-container .courses-section .courses-nav .search-div .search-list .no-match p {
    font-size: .65rem;
}

.trainings-container .courses-section .courses-nav .search-div .search-list a .single-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.trainings-container .courses-section .courses-nav .search-div .search-list a .single-search .coursename {
    font-size: .75rem;
    letter-spacing: .01rem;
    transition: all .5s ease-in-out;
}

.trainings-container .courses-section .courses-nav .search-div .search-list a:hover .single-search .coursename {
    transform: translateX(.2em);
}

.trainings-container .courses-section .courses-nav .search-div .search-list a .single-search .arrow-right {
    width: .8em;
}

.trainings-container .courses-section .courses-nav .courses-nav-ul {
    list-style-type: none;
    display: flex;
    gap: .5em;
}

.trainings-container .courses-section .courses-nav .courses-nav-ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 1.3em;
} 



.trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li {
    position: relative;
    width: 1em;
    height: 1em;
}

.trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li img {
    position: absolute;
    top: 0;
    width: .7em;
    cursor: pointer;
    transition: all .5s ease-in-out;
    opacity: 0;
}

.trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li img.delete.active {
    opacity: 1;
}

.trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li img.delete-red.active {
    opacity: 1;
}

.trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li span{
    display: none;
}


.trainings-container .courses-section .courses-wrapper {
    margin: 1.5em 0;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.trainings-container .courses-section .courses-wrapper .cartegory-div {
    padding: 1em;
    background: #ffffff;
    position: relative;
    box-shadow: 0px 0px 0px 2px var(--black) inset;
    border-radius: .4em;
    transition: all .5s ease-in-out;
}

.trainings-container .courses-section .courses-wrapper .cartegory-div:hover {
    background: var(--secondary);
    color: #ffffff;
}

.trainings-container .courses-section .courses-wrapper .cartegory-div.active {
    background: var(--secondary);
    color: #ffffff;
    box-shadow: 0.1875em -0.25em 1.1875em rgba(0, 0, 0, 0.11), -0.1875em 0.25em 1.1875em rgba(0, 0, 0, 0.11);
}


.trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}


.trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .cartegory-title {
    font-size: .75rem;
    font-weight: 600;
}

.trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .arrows {
    position: relative;
    width: 1em;
    height: 1em;
}

.trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .arrow {
    font-size: 1.5em;
    transition: all .1s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .arrow.active {
    opacity: 1;
}


.trainings-container .courses-section .courses-wrapper .cartegory-div.active {
    border-bottom: none;
    border-radius: 0.4em 0.4em 0 0;
}

.trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-courses {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #ffffff;
    z-index: 2;
    border-radius: 0 0 .4em .4em;
    height: 0;
    transition: all .5s ease-in-out;
    border: 0px solid #ddd;
}

.trainings-container .courses-section .courses-wrapper .cartegory-div.active .cartegory-courses {
    padding: 1em;
    border: 1px solid #ddd;
    border-top: 0px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: .5em;
    box-shadow: 0.1875em -0.25em 1.1875em rgba(0, 0, 0, 0.11), -0.1875em 0.25em 1.1875em rgba(0, 0, 0, 0.11);

}

.trainings-container .courses-section .courses-wrapper .cartegory-div.active .cartegory-courses a {
    text-decoration: none;
    color: var(--black);
}

.trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-courses .single-cartegory-course {
    display: none;
}

.trainings-container .courses-section .courses-wrapper .cartegory-div.active .cartegory-courses .single-cartegory-course {
    display: flex;
    justify-content: space-between;
    gap: 1em;
}

.trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-courses .single-cartegory-course .course-title {
    transition: all .5s ease-in-out;
    cursor: pointer;
    font-size: .7rem;
}

.trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-courses .single-cartegory-course:hover .course-title{
    color: var(--primary);
    transform: translateX(.3em);
}

.trainings-container .courses-section .courses-wrapper .cartegory-div.active .cartegory-courses .single-cartegory-course .arrow-right {
    width: 1em;
    cursor: pointer;
}








.trainings-container .help-section {
    padding: 6em 1em 1em 1em;
}

.trainings-container .help-section .help-h1 {
    font-size: 1.25rem;
    line-height: 1.3rem;
    color: var(--black);
}

.trainings-container .help-section .help-p {
    font-size: 0.85rem;
    line-height: 1rem;
    letter-spacing: 0.01rem;
    margin: 0.5rem 0;
}

.trainings-container .help-section .enquire-btn {
    font-size: 0.7rem;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: 0.03rem;
}


/* MEDIA QUERIES */

@media(min-width: 287px) {
    .trainings-container .hero-section .hero-left .hero-title .scribble {
        left: unset;
        right: 0;
        top: 2.2em;
    }

    .trainings-container .hero-section .hero-right .hero-small {
        width: 5em;
        top: 12em;
        right: 1em;
    }


    .trainings-container .hero-section .hero-left .hero-p {
        margin: 1rem 0;
    }

    .trainings-container .hero-section .hero-right .bottom-line {
        top: 20em;
    }
}

@media(min-width: 307px) {
    .trainings-container .hero-section .hero-left .hero-title .scribble {
        right: unset;
        left: 4em;
    }

}

@media(min-width: 336px) {
    .trainings-container .courses-section {
        padding: 3em 1em 0 1em;
    }  
}

@media(min-width: 350px) {

    .trainings-container .hero-section .hero-right .hero-small {
        width: 6em;
    }
}

@media(min-width: 375px) {
    .trainings-container .hero-section .hero-left .hero-title .scribble {
        left: 1em;
        top: 2em;
    }

}

@media(min-width: 400px) {
    .trainings-container .hero-section {
        padding: 9em 1.5em 2em 1.5em;
    }

    .trainings-container .hero-section .hero-right .bottom-line {
        top: 23em;
    } 


    .trainings-container .hero-section .hero-left .hero-p {
        margin: 1.2rem 0;
    }

    .trainings-container .courses-section {
        padding: 3em 1.5em 0 1.5em;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list .no-match p {
        font-size: .7rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .cartegory-title {
        font-size: .8rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .arrow {
        font-size: 1.7em;
    }
    .trainings-container .help-section {
        padding: 7em 1.5em 1em 1.5em;
    }   
}


@media(min-width: 450px) {
    .trainings-container .hero-section .hero-left .hero-title .scribble {
        left: 0;
        top: 2.1em;
    }

    .trainings-container .hero-section .hero-right .hero-small {
        width: 7.5em;
        right: 2em;
    }
}

@media(min-width: 500px) {

    .trainings-container .hero-section {
        padding: 9em 1.5em 2em 1.5em;
    }

    .trainings-container .hero-section .hero-left {
        width: 70%;
    }

    .trainings-container .hero-section .hero-left .hero-title {
        font-size: 1.8rem;
        line-height: 1.8rem;
    }

    .trainings-container .hero-section .hero-left .hero-title .scribble {
        left: 6.4em;
        width: 4em;
        top: 1.9em;
    }

    .trainings-container .hero-section .hero-right .bottom-line {
        top: 22em;
    }

    .trainings-container .hero-section .hero-right .hero-small {
        display: none;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        position: absolute;
        display: block;
        top: 7.5em;
        right: -2em;
        width: 16em;
    }

    .trainings-container .hero-section .hero-right .hero-img-container img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .trainings-container .courses-section {
        padding: 2.4em 1.5em 0 1.5em;
    }

    .trainings-container .courses-section .courses-nav .courses-nav-ul {
        gap: 2em;
    }

    .trainings-container .courses-section .courses-nav .search-div input {
        width: 8.5rem;
    }


    .trainings-container .courses-section .courses-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1em;
    }

}

@media(min-width: 518px) {
    .trainings-container .hero-section .hero-left .hero-title .scribble {
        left: 4em;
    }
}


@media(min-width: 550px) {
    .trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li img {
        width: .85em;
    }

    .trainings-container .courses-section .courses-wrapper {
        gap: 1.5em;
    }
}

@media(min-width: 600px) {
    .trainings-container .hero-section {
        padding: 10em 1.875em 3em 1.875em;
    }

    .trainings-container .hero-section .hero-right .bottom-line {
        top: 24em;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        top: 4.99em;
        width: 21em;
    }

    .trainings-container .courses-section {
        padding: 2.4em 1.875m 0 1.875em;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list .no-match {
        gap: .7em;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list .no-match p {
        font-size: .8rem;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list .no-match img {
        width: 3.5em;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .arrow {
        font-size: 1.5em;
    }

    .trainings-container .help-section {
        padding: 7em 1.875em 1em 1.875em;
    }

    .trainings-container .help-section .help-h1 {
        font-size: 1.4rem;
    }

    .trainings-container .help-section .help-p {
        font-size: .95rem;
    }

    .trainings-container .help-section .enquire-btn {
        font-size: .9rem;
    }

    .trainings-container .help-section .help-p {
        margin: 0.8rem 0;
    }
}

@media(min-width: 650px) {
    .trainings-container .hero-section .hero-left .hero-title {
        font-size: 2.2rem;
        font-weight: 900;
        line-height: 2rem;
    }

    .trainings-container .hero-section .hero-left .hero-title .scribble {
        top: 1.9em;
    }

    .trainings-container .hero-section .hero-left .hero-p {
        margin: 1.9rem 0;
    }

    .trainings-container .hero-section .hero-left .checkout {
        gap: .6em;
    }

    .trainings-container .hero-section .hero-left .checkout span {
        font-size: 1.1rem;
    }

    .trainings-container .hero-section .hero-left .checkout img {
        width: 2em;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        top: 4.2em;
        right: -3.3em;
        width: 23em;
    }

    .trainings-container .hero-section .hero-right .bottom-line {
        top: 25em;
    }

    .trainings-container .courses-section .courses-nav .search-div input {
        width: 11.5rem;
    }

    .trainings-container .courses-section .courses-nav .search-div input {
        font-size: .85rem;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-icon {
        width: .95em;
    }


    .trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li img {
        width: .9em;
    }

    .trainings-container .courses-section .courses-nav .courses-nav-ul {
        gap: 3em;
    }


    .trainings-container .help-section .help-h1 {
        font-size: 1.6rem;
    }

    .trainings-container .help-section .help-p {
        margin: 1.1rem 0;
        font-size: 1.1rem;
    }

    .trainings-container .help-section .enquire-btn {
        font-size: 1rem;
    }
}


@media(min-width: 700px) {

    .trainings-container .hero-section {
        padding: 11em 1.875em 3em 1.875em;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        top: 5.2em;
    }

    .trainings-container .hero-section .hero-left .hero-p {
        font-size: 1.05rem;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        width: 25em;
    }

    .trainings-container .hero-section .hero-right .bottom-line {
        top: 27.7em;
    }

    .trainings-container .courses-section {
        padding: 3.4em 1.875em 0 1.875em;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list a .single-search .coursename {
        font-size: .9rem;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list a .single-search .arrow-right {
        width: 1em;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-courses .single-cartegory-course .course-title {
        font-size: .8rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .cartegory-title {
        font-size: .9rem;
    }

}

@media(min-width: 800px) {

    .trainings-container .appear-right {
        transform: translateX(90px);
    }

    .trainings-container .hero-section {
        padding: 12em 3.125em 5em 3.125em;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        top: 6em;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        width: 29em;
        top: 6em;
        right: -4.3em;
    }

    .trainings-container .hero-section .hero-right .bottom-line {
        top: 32.3em;
        height: .4em;
    }

    .trainings-container .hero-section .hero-left .hero-title {
        font-size: 2.5rem;
        line-height: 2.5rem;
    }

    .trainings-container .hero-section .hero-left .hero-p {
        font-size: 1.1rem;
    }

    .trainings-container .hero-section .hero-left .checkout span {
        font-size: 1.2rem;
    }

    .trainings-container .hero-section .hero-left .checkout img {
        width: 2.1em;
    }

    .trainings-container .courses-section {
        padding: 3.4em 3.125em 0 3.125em;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list a {
        margin-bottom: .8em;
    }

    .trainings-container .hero-section .hero-left .checkout {
        gap: .7em;
    }

    .trainings-container .courses-section .courses-nav .search-div input {
        width: 14.5rem;
        font-size: 1rem;
    }


    .trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li {
        width: 8em;
        height: 1.5em;
    }

    .trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li span {
        display: block;
        cursor: pointer;
    }

    .trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li img {
        width: 1.1em;
        right: 0;
    }

    .trainings-container .help-section {
        padding: 7em 3.125em 1em 3.125em;
    }

}


@media(min-width: 850px) {
    .trainings-container .hero-section .hero-left .hero-title {
        font-size: 2.7rem;
    }

    .trainings-container .courses-section .paginate-ul .previous a, 
    .trainings-container .courses-section .paginate-ul .next a {
        font-size: 1.5rem;
    }

    .trainings-container .courses-section .paginate-ul .previous, 
    .trainings-container .courses-section .paginate-ul .next {
        width: 2em;
        height: 2em;
    }

    .trainings-container .help-section .help-h1 {
        font-size: 1.8rem;
    }

    .trainings-container .help-section .help-p {
        margin: 1.2rem 0;
    }

    .trainings-container .help-section .enquire-btn {
        padding: .375em 3.125em;
    }
}

@media(min-width: 900px) {
    .trainings-container .courses-section .courses-nav .search-div .search-list {
        width: 60vw;
    }
}

@media(min-width: 950px) {
    

    .trainings-container .courses-section .courses-nav .search-div .search-icon {
        width: 1em;
    }

    .trainings-container .courses-section .courses-wrapper {
        gap: 2em;
        margin: 1.5em 0 2em 0;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .cartegory-title {
        font-size: 1rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .arrow {
        font-size: 1.7em;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-courses .single-cartegory-course .course-title {
        font-size: .9rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div.active .cartegory-courses {
        gap: 1em;
    }

    .trainings-container .help-section {
        padding: 8em 3.125em 1em 3.125em;
    }


}

@media(min-width: 1000px) {
    .trainings-container .hero-section .hero-left .hero-title {
        font-size: 3rem;
        line-height: 3rem;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        width: 33.5em;
        right: -3.3em;
    }

    .trainings-container .hero-section .hero-right .bottom-line {
        top: 36.3em;
    }

    .trainings-container .hero-section {
        padding: 14.1em 5em 6em 5em;
    }

    .trainings-container .hero-section .hero-left .hero-p {
        font-size: 1.2rem;
        margin: 2rem 0;
    }

    .trainings-container .hero-section .hero-left .checkout {
        gap: .9em;
    }

    .trainings-container .hero-section .hero-left .checkout span {
        font-size: 1.4rem;
    }

    .trainings-container .hero-section .hero-left .checkout img {
        width: 2.4em;
    }

    .trainings-container .courses-section {
        padding: 3.4em 5em 0 5em;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list .no-match p {
        font-size: 1rem;
    }

    .trainings-container .help-section {
        padding: 8em 5em 1em 5em;
    }

    .trainings-container .help-section .help-h1 {
        font-size: 2.1rem;
    }

    .trainings-container .help-section .help-p {
        font-size: 1.2rem;
        margin: 1.5rem 0;
    }

    .trainings-container .help-section .enquire-btn {
        padding: .375em 3.9em;
    }
    
}

@media(min-width: 1100px) {

    .trainings-container .hero-section {
        padding: 15.1em 5em 6em 5em;
    }

    .trainings-container .hero-section .hero-right .bottom-line {
        top: 37.3em;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        top: 7em;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list {
        padding: 1.5em;
        width: 50vw;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list a {
        margin-bottom: 1em;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list a .single-search .coursename {
        font-size: 1rem;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list a .single-search .arrow-right {
        width: 1.1em;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div {
        padding: 1.5em;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div.active .cartegory-courses {
        padding: 1.5em;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .arrow {
        font-size: 2em;
    }
}


@media(min-width: 1150px) {

    .trainings-container .hero-section {
        padding: 15em 5em 7em 5em;
    }

    .trainings-container .hero-section .hero-left .hero-title {
        font-size: 3.7rem;
        line-height: 3.5rem;
    }

    .trainings-container .hero-section .hero-left .hero-p {
        font-size: 1.35rem;
        margin: 3rem 0 1.9rem;
    }

    .trainings-container .hero-section .hero-left .checkout span {
        font-size: 1.55rem;
    }

    .trainings-container .hero-section .hero-left .checkout img {
        width: 2.8em;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        width: 37.5em;
        right: -5.3em;
    }
    
    .trainings-container .hero-section .hero-right .bottom-line {
        top: 40.9em;
    }

    .trainings-container .courses-section {
        padding: 3em 5em 0 5em;
    }

    .trainings-container .courses-section .courses-nav .search-div input {
        width: 16.5rem;
        font-size: 1.2rem;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-icon {
        width: 1.3em;
    }

    .trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li {
        width: 9.5em;
        height: 1.8em;
    }

    .trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li img {
        width: 1.2em;
    }

    .trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li span {
        font-size: 1.2rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-courses .single-cartegory-course .course-title {
        font-size: .95rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .cartegory-title {
        font-size: 1.1rem;
    }

    .trainings-container .help-section {
        padding: 9em 5em 1em 5em;
    }

    .trainings-container .help-section .help-h1 {
        font-size: 2.5rem;
    }

    .trainings-container .help-section .help-p {
        font-size: 1.4rem;
        margin: 1.8rem 0;
    }

    .trainings-container .help-section .enquire-btn {
        font-size: 1.25rem;
    }
}


@media(min-width: 1200px) {
    .trainings-container .hero-section {
        padding: 16em 6.25em 7em 6.25em;
    }

    .trainings-container .courses-section {
        padding: 3em 6.25em 1em 6.25em;
    }

    .trainings-container .courses-section .courses-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5em;
        margin-top: 2.5em;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div.active .cartegory-courses {
        gap: 1.5em;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-courses .single-cartegory-course .course-title {
        font-size: .8rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .cartegory-title {
        font-size: .95rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .arrows {
        width: 1.5em;
        height: 1.5em;
    }

    .trainings-container .help-section {
        padding: 9em 6.25em 1em 6.25em;
    }
}

@media(min-width: 1300px) {


    .trainings-container .hero-section {
        padding: 17em 6.25em 7em 6.25em;
    }

    .trainings-container .hero-section .hero-right .bottom-line {
        top: 42.9em;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        top: 9em;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .cartegory-title {
        font-size: 1.1rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-courses .single-cartegory-course .course-title {
        font-size: 1rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div.active .cartegory-courses .single-cartegory-course .arrow-right {
        width: 1.3em;
    }

    .trainings-container .help-section {
        padding: 10em 6.25em 1em 6.25em;
    }
}

@media(min-width: 1350px) {

    .trainings-container .hero-section {
        padding: 18em 6.25em 7em 6.25em;
    }

    .trainings-container .hero-section .hero-right .bottom-line {
        top: 45.6em;
    }

    .trainings-container .hero-section .hero-left .hero-title {
        font-size: 4rem;
        line-height: 3.8rem;
    }

    .trainings-container .hero-section .hero-left .hero-p {
        font-size: 1.5rem;
    }

    .trainings-container .hero-section .hero-left .hero-title .scribble {
        top: 1.8em;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        width: 41.5em;
        right: -2.3em;
        top: 8em;
    }

    .trainings-container .courses-section {
        padding: 4em 6.25em 1em 6.25em;
    }

    .trainings-container .courses-section .courses-nav .search-div input {
        font-size: 1.4rem;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-icon {
        width: 1.5em;
    }


    .trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li span {
        font-size: 1.4rem;
    }

    .trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li {
        width: 11em;
        height: 1.8em;
    }

    .trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li img {
        width: 1.4em;
    }

}

@media(min-width: 1400px) {

    .trainings-container .courses-section .courses-nav .search-div .search-list .no-match {
        gap: 1em;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list .no-match img {
        width: 4.5em;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list .no-match p {
        font-size: 1.2rem;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list a .single-search .coursename {
        font-size: 1.2rem;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list a .single-search .arrow-right {
        width: 1.3em;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .cartegory-title {
        font-size: 1.25rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-courses .single-cartegory-course .course-title {
        font-size: 1.15rem;
    }

    .trainings-container .help-section {
        padding: 13em 6.25em 1em 6.25em;
    }
}



@media(min-width: 1451px) {
    .trainings-container .hero-section {
        padding: 18em 9.375em 7em 9.375em;
    }

    .trainings-container .courses-section {
        padding: 4em 9.375em 1em 9.375em;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div.active .cartegory-courses {
        gap: 1.5em;
    }

    .trainings-container .help-section {
        padding: 13em 9.375em 1em 9.375em;
    }
}

@media(min-width: 1600px) {

    .trainings-container .courses-section .courses-nav .search-div .search-list .no-match img {
        width: 5.3em;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list .no-match p {
        font-size: 1.35rem;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list a .single-search .coursename {
        font-size: 1.3rem;
    }

    .trainings-container .courses-section .courses-nav .search-div .search-list a .single-search .arrow-right {
        width: 1.5em;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-top .cartegory-title {
        font-size: 1.4rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div .cartegory-courses .single-cartegory-course .course-title {
        font-size: 1.3rem;
    }

    .trainings-container .courses-section .courses-wrapper .cartegory-div.active .cartegory-courses .single-cartegory-course .arrow-right {
        width: 1.5em;
    }
}


@media(min-width: 1650px) {

    .trainings-container .hero-section {
        padding: 21em 9.375em 7em 9.375em;
    }


    .trainings-container .hero-section .hero-left .hero-title {
        font-size: 5rem;
        line-height: 4.7rem;
    }

    .trainings-container .hero-section .hero-left .hero-p {
        font-size: 2rem;
        margin: 4rem 0 3rem 0;
    }

    .trainings-container .hero-section .hero-left .checkout span {
        font-size: 2.3rem;
    }

    .trainings-container .hero-section .hero-left .checkout img {
        width: 3.8em;
    }

    .trainings-container .hero-section .hero-left .checkout {
        gap: 1.1em;
    }

    .trainings-container .hero-section .hero-right .hero-img-container {
        width: 47em;
        top: 11em;
    }

    .trainings-container .hero-section .hero-right .bottom-line {
        top: 53.6em;
    }
    
}

/* MEDIA QUERIES END */