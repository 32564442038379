@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}

.blogpage-container {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    color: var(--black);
}


/* Fade In Css */

.blogpage-container .blog-title {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.blogpage-container .blog-title.active {
    opacity: 1;
    transform: translateX(0px);
}

.blogpage-container .author-date {
    opacity: 0;
    transform: translateX(100px);
    transition: all 1s ease-in-out;
}

.blogpage-container .author-date.active {
    opacity: 1;
    transform: translateX(0px);
}

.blogpage-container .form-container form {
    opacity: 0;
    transform: translateX(100px);
    transition: all 1s ease-in-out;
}

.blogpage-container .form-container form.active {
    opacity: 1;
    transform: translateX(0px);
}

.blogpage-container .title-socials .socials {
    opacity: 0;
    transform: translateX(100px);
    transition: all 1s ease-in-out;
}

.blogpage-container .title-socials .socials.active {
    opacity: 1;
    transform: translateX(0px);
}

.blogpage-container .img-container {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.blogpage-container .img-container.active {
    opacity: 1;
    transform: translateX(0px);
}

.blogpage-container .share-div {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.blogpage-container .share-div.active {
    opacity: 1;
    transform: translateX(0px);
}

.blogpage-container .blog-li {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 1s ease-in-out;
}

.blogpage-container .blog-li.active {
    opacity: 1;
    transform: translateY(0px);
}


.blogpage-container .section-title {
    opacity: 0;
    transform: translateX(51px);
    transition: all 1s ease-in-out;
}

.blogpage-container .section-title.active {
    opacity: 1;
    transform: translateX(0px);
}

.blogpage-container .blog-p {
    opacity: 0;
    transform: translateX(-107px);
    transition: all 1s ease-in-out;
}

.blogpage-container .blog-p.active {
    opacity: 1;
    transform: translateX(0px);
}

.blogpage-container .appear-left-hero {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.blogpage-container .appear-left-hero.active {
    opacity: 1;
    transform: translateX(0px);
}

/* Fade In Css End */

.blogpage-container .blog-section {
    padding: 8em 1em 1em 1em;
}

.blogpage-container .blog-section .blog-top  {
    margin-bottom: 1em;
}

.blogpage-container .blog-section .blog-top .title-socials .blog-title {
    font-size: 1.3rem;
    text-align: left;
    color: var(--black);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    line-height: 1.5rem;
    font-family: 'Montserrat', sans-serif;
}

.blogpage-container .blog-section .blog-top .title-socials .socials {
    display: flex;
    gap: .5em;
    margin: 1em 0;
}

.blogpage-container .blog-section .blog-top .title-socials .socials svg {
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.blogpage-container .share-div .socials svg {
    cursor: pointer;
    transition: all .5s ease-in-out;

}

.blogpage-container .blog-section .blog-top .title-socials .socials svg:hover {
    transform: scale(.95);
}

.blogpage-container .share-div .socials svg:hover {
    transform: scale(.95);
}


.blogpage-container .blog-section .blog-top .author-date {
    display: flex;
    justify-content: space-between;
}

.blogpage-container .blog-section .blog-top .author-date p{
    font-size: .8rem;
}

.blogpage-container .blog-section .blog-body .blog-left .img-container {
    width: 100%;
    height: 10em;
    margin-bottom: .5em;
}

.blogpage-container .blog-section .blog-body .blog-left .img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.blogpage-container .blog-p {
    font-size: .65rem;
    letter-spacing: .01rem;
    color: #4b4b4b;
    line-height: 1.1rem;
    margin-top: .4rem;
}

.blogpage-container .section-title {
    font-size: 1.1rem;
    font-family: 'Montserrat', sans-serif;
    color: var(--black);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    line-height: 1.2rem;
    text-align: left;
}

.blogpage-container .blog-div {
    margin-top: 1em;
}

.blogpage-container .blog-div .cta-btn {
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-weight: 600;
    transition: all .5s ease-in-out;
}

.blogpage-container .blog-div .cta-btn:hover {
    transform: scale(.98);
}

.blogpage-container .blog-ul {
    list-style-type: decimal;
    margin: .7em 0 0 1em;
}

.blogpage-container .blog-ul .blog-li {
    font-size: .65rem;
    letter-spacing: .01rem;
    color: #4b4b4b;
    line-height: 1.1rem;
}

.blogpage-container .share-div {
    margin: 2em 0 1.5em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blogpage-container .share-div .share-p {
    font-size: 1rem;
    font-weight: 500;
}

.blogpage-container .share-div .socials {
    display: flex;
    gap: .5em;
    align-items: center;
}

.blogpage-container .blog-right .form-container {
    margin-bottom: 1em;
}

.blogpage-container .blog-right .form-container form {
    display: flex;
    flex-direction: column;
    gap: .5em;
    margin-top: .7em;
}

.blogpage-container .blog-right .form-container form input {
    font-family: "Poppins", sans-serif;
    margin-top: .3em;
}

.blogpage-container .blog-right .form-container form textarea {
    font-family: "Poppins", sans-serif;
    margin-top: .3em;
}

.blogpage-container .blog-right .form-container form .MuiButtonBase-root {
    text-transform: capitalize;
    font-weight: 600;
}

.blogpage-container .blog-right .form-container form .MuiButtonBase-root.Mui-disabled {
    background: #ddd;
}

.blogpage-container .blog-right .form-container form .MuiButtonBase-root .MuiLoadingButton-loadingIndicator {
    position: relative;
    margin-left: 2em;
}

.blogpage-container .blog-right .form-container form .MuiButtonBase-root svg {
    transition: all .5s ease-in-out;
}

.blogpage-container .blog-right .form-container form .MuiButtonBase-root:hover svg {
    transform: translateX(.1em);
}
.blogpage-container .blog-right .ad {
    margin: 1.5em 0;
}

.blogpage-container .blog-right .ad img{
    width: 100%;
}


/* MEDIA QUERIES */

@media(min-width: 400px) {
    .blogpage-container .blog-section {
        padding: 9em 1.5em 1em 1.5em;
    }

    .blogpage-container .blog-section .blog-top .title-socials .blog-title {
        font-size: 1.5rem;
        line-height: 1.6rem;
    }

    .blogpage-container .blog-section .blog-top .author-date p {
        font-size: .85rem;
    }

    .blogpage-container .blog-section .blog-top .author-date p {
        font-size: .85rem;
    }

    .blogpage-container .blog-p {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .blogpage-container .section-title {
        font-size: 1.2rem;
    }

    .blogpage-container .blog-ul .blog-li {
        font-size: .7rem;
        line-height: 1.3rem;
    }
}

@media(min-width: 500px) {
    .blogpage-container .blog-section .blog-body .blog-left .img-container {
        height: 13em;
    }

    .blogpage-container .blog-div .cta-btn {
        font-size: 1rem;
    }
}

@media(min-width: 600px) {
    .blogpage-container .blog-section {
        padding: 10em 1.875em 1em 1.875em;
    }

    .blogpage-container .blog-section .blog-top .title-socials .blog-title {
        font-size: 1.7rem;
        line-height: 1.7rem;
    }

    .blogpage-container .blog-section .blog-top .title-socials .socials {
        gap: 2em;
    }

    .blogpage-container .blog-section .blog-top .author-date p {
        font-size: .9rem;
    }

    .blogpage-container .blog-section .blog-top .author-date {
        margin-bottom: 1.5em;
    }

    .blogpage-container .blog-p {
        line-height: 1.5rem;
    }

    .blogpage-container .section-title {
        font-size: 1.4rem;
    }

    .blogpage-container .blog-ul .blog-li {
        line-height: 1.5rem;
    }

    .blogpage-container .blog-section .blog-body .blog-left .img-container {
        margin-bottom: .8em;
    }

    .blogpage-container .share-div {
        justify-content: unset;
        gap: 1em;
    }

    .blogpage-container .share-div .socials {
        gap: 1.5em;
    }

    .blogpage-container .blog-section .blog-body .blog-right {
        margin-top: 3em;
    }

    .blogpage-container .blog-section .blog-body .blog-left .img-container {
        height: 16em;
    }
}

@media(min-width: 650px) {
    .blogpage-container .blog-p {
        font-size: .75rem;
    }

    .blogpage-container .blog-ul .blog-li {
        font-size: .75rem;
        margin-bottom: .4rem;
    }
}

@media(min-width: 700px) {

    .blogpage-container .blog-section {
        padding: 11em 1.875em 1em 1.875em;
    }

    .blogpage-container .blog-section .blog-body {
        display: flex;
        gap: 2em;
        margin-top: 2em;
    }

    .blogpage-container .blog-section .blog-body .blog-right {
        margin-top: unset;
    }
}

@media(min-width: 800px) {
    .blogpage-container .blog-section {
        padding: 12em 3.125em 1em 3.125em;
    }

    .blogpage-container .blog-section .blog-top .title-socials {
        display: flex;
        margin-bottom: 1.5em;
        justify-content: space-between;
    }

    .blogpage-container .blog-section .blog-top .title-socials .blog-title {
        width: 70%;
    }

    .blogpage-container .blog-section .blog-top .title-socials .socials {
        margin: unset;
    }

    .blogpage-container .blog-div .cta-btn {
        padding: .3rem 2rem;
    }

}

@media(min-width: 850px) {
    .blogpage-container .blog-section .blog-top .title-socials .socials svg {
        font-size: 2em;
    }
}

@media(min-width: 900px) {
    .blogpage-container .blog-section .blog-top .title-socials .blog-title {
        font-size: 2.3rem;
        line-height: 2.3rem;
    }

    .blogpage-container .blog-section .blog-top .author-date p {
        font-size: 1rem;
    }

    .blogpage-container .blog-section .blog-body .blog-left {
        width: 90%;
    }

    .blogpage-container .section-title {
        font-size: 2rem;
        line-height: 2rem;
    }

    .blogpage-container .blog-p {
        font-size: 1rem;
        letter-spacing: .015rem;
        line-height: 2rem;
        margin-top: .6rem;
    }

    .blogpage-container .blog-ul {
        margin: .7em 0 0 1.5em;
    }

    .blogpage-container .blog-ul .blog-li {
        font-size: 1rem;
        letter-spacing: .015rem;
        line-height: 2rem;
    }

    .blogpage-container .share-div .share-p {
        font-size: 1.3rem;
    }

    .blogpage-container .share-div .socials svg {
        font-size: 2em;
    }
}

@media(min-width: 1000px) {
    .blogpage-container .blog-section {
        padding: 13em 5em 1em 5em;
    }

    .blogpage-container .blog-section .blog-top .title-socials {
        margin-bottom: 2em;
    }

    .blogpage-container .blog-div {
        margin-top: 2em;
    }

    .blogpage-container .blog-right .form-container form {
        margin-top: 1em;
    }

    .blogpage-container .blog-section .blog-body .blog-left .img-container {
        height: 20em;
    }

    .blogpage-container .blog-div .cta-btn {
        font-size: 1.1rem;
    }
}

@media(min-width: 1100px) {

    .blogpage-container .blog-section {
        padding: 15em 5em 1em 5em;
    }

    .blogpage-container .blog-section .blog-top .title-socials .blog-title {
        font-size: 2.7rem;
        line-height: 2.7rem;
        font-weight: 900;
    }

    .blogpage-container .blog-section .blog-top .title-socials .socials svg {
        font-size: 2.4em;
    }

    .blogpage-container .blog-section .blog-top .author-date p {
        font-size: 1.2rem;
    }

    .blogpage-container .section-title {
        font-size: 2.3rem;
        line-height: 2.3rem;
        font-weight: 900;
    }

    .blogpage-container .blog-p {
        font-size: 1.15rem;
    }

    .blogpage-container .blog-ul .blog-li {
        font-size: 1.15rem;
        margin-bottom: .5rem;
    }

    .blogpage-container .blog-right .form-container form input {
        font-size: 1rem;
    }

    .blogpage-container .blog-right .form-container form textarea {
        font-size: 1rem;
    }

    .blogpage-container .blog-right .form-container form .css-sghohy-MuiButtonBase-root-MuiButton-root {
        margin-top: .5em;
    }

    .blogpage-container .blog-right .form-container form .MuiButtonBase-root span{
        font-size: 1.1rem;
    }

    .blogpage-container .blog-right .ad {
        margin: 2.5em 0;
    }

    .blogpage-container .blog-div .cta-btn {
        padding: .3rem 2rem;
        font-size: 1.3rem;
    }

    .blogpage-container .share-div {
        gap: 2em;
    }

    .blogpage-container .share-div .share-p {
        font-size: 1.6rem;
    }

    .blogpage-container .share-div .socials svg {
        font-size: 2.4em;
    }
}

@media(min-width: 1150px) {
    .blogpage-container .blog-section .blog-body .blog-left .img-container {
        height: 24em;
        margin-bottom: 1em;
    }
}

@media(min-width: 1200px) {
    .blogpage-container .blog-section {
        padding: 15em 6.25em 1em 6.25em;
    }

}

@media(min-width: 1300px) {
    .blogpage-container .blog-div .cta-btn {
        padding: .4rem 3rem;
        font-size: 1.5rem;
    }
}

@media(min-width: 1350px) {
    .blogpage-container .blog-section .blog-body .blog-left {
        width: 70%;
    }
}

@media(min-width: 1400px) {

    .blogpage-container .blog-section {
        padding: 17em 6.25em 1em 6.25em;
    }

    .blogpage-container .blog-section .blog-top .title-socials .blog-title {
        font-size: 3rem;
        line-height: 3rem;
    }

    .blogpage-container .blog-section .blog-top .title-socials .socials {
        gap: 3em;
    }

    .blogpage-container .blog-section .blog-top .title-socials .socials svg {
        font-size: 2.7em;
    }

    .blogpage-container .blog-section .blog-top .author-date p {
        font-size: 1.4rem;
    }

    .blogpage-container .section-title {
        font-size: 2.5rem;
        line-height: 2.5rem;
    }
    
    .blogpage-container .blog-p {
        font-size: 1.25rem;
        line-height: 2.3rem;
    }

    .blogpage-container .blog-ul {
        margin: 1.7em 0 0 1.5em;
    }

    .blogpage-container .blog-ul .blog-li {
        font-size: 1.25rem;
        line-height: 2.3rem;
    }

    .blogpage-container .share-div .socials  {
        gap: 3em;
    }

    .blogpage-container .share-div .socials svg {
        font-size: 2.7em;
    }

    .blogpage-container .blog-section .blog-body .blog-left .img-container {
        height: 27em;
    }
}

@media(min-width: 1451px) {
    .blogpage-container .blog-section {
        padding: 17em 9.375em 1em 9.375em;
    }
}

@media(min-width: 1500px) {
    .blogpage-container .blog-div .cta-btn {
        padding: .4rem 4rem;
    }
}

@media(min-width: 1550px) {

    .blogpage-container .blog-section {
        padding: 18em 9.375em 1em 9.375em;
    }

    .blogpage-container .blog-right .form-container form input {
        font-size: 1.3rem;
    }
    
    .blogpage-container .blog-right .form-container form textarea {
        font-size: 1.3rem;
    }

    .blogpage-container .blog-right .form-container form .MuiButtonBase-root span {
        font-size: 1.3rem;
    }
}

@media(min-width: 1600px) {

    .blogpage-container .blog-section {
        padding: 19em 9.375em 1em 9.375em;
    }

    .blogpage-container .blog-section .blog-body .blog-left .img-container {
        height: 30em;
    }
}

@media(min-width: 1650px) {
    .blogpage-container .blog-section .blog-top .title-socials .blog-title {
        font-size: 3.5rem;
        line-height: 3.5rem;
    }

    .blogpage-container .blog-section .blog-top .title-socials .socials svg {
        font-size: 3em;
    }

    .blogpage-container .blog-section .blog-top .author-date p {
        font-size: 1.6rem;
    }

    .blogpage-container .blog-section .blog-body .blog-left {
        width: 63%;
    }

    .blogpage-container .blog-p {
        font-size: 1.35rem;
        line-height: 2.5rem;
        margin-bottom: .8rem;
    }

    .blogpage-container .blog-ul .blog-li {
        font-size: 1.35rem;
        line-height: 2.5rem;
        margin-bottom: 1rem;
    }
}
/* MEDIA QUERIES END */