@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}


/* LOADER CSS */

.loader-wrapper {
    position: fixed;
    inset: 0;
    height: 100vh;
    width: 100vw;
    display: grid;
    place-items: center;
    background: #ffffff;
    z-index: 50;
}

.loader-wrapper.disappear {
    animation: vanish 1s forwards;
}

.loader-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35em;
}


@keyframes vanish {
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

/* LOADER CSS END */



/* Fade In Css */

.home-container .appear-up {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-in-out;
}

.home-container .appear-up.active {
    opacity: 1;
    transform: translateX(0px);
}

.home-container .appear-down {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 1s ease-in-out;
}

.home-container .appear-down.active {
    opacity: 1;
    transform: translateY(0px);
}


.home-container .appear-right {
    opacity: 0;
    transform: translateX(25px);
    transition: all 1s ease-in-out;
}

.home-container .appear-right.active {
    opacity: 1;
    transform: translateX(0px);
}

.home-container .appear-left {
    opacity: 0;
    transform: translateX(-160px);
    transition: all 1s ease-in-out;
}

.home-container .appear-left-btn {
    opacity: 0;
    transform: translateX(-70px);
    transition: all 1s ease-in-out;
}

.home-container .appear-left.active {
    opacity: 1;
    transform: translateX(0px);
}

.home-container .appear-left-btn.active {
    opacity: 1;
    transform: translateX(0px);
}

.home-container .appear-left-soc {
    opacity: 0;
    transform: translateX(-30px);
    transition: all 1s ease-in-out;
}

.home-container .appear-left-soc.active {
    opacity: 1;
    transform: translateX(0px);
}

.home-container .appear-rocket {
    opacity: 0;
    transform: translate(-2em, 2em);
}

.home-container .appear-rocket.active {
    transform: translate(0);
    opacity: 1;
}



/* Fade In Css End */

html {
    scroll-behavior: smooth;
}


/* LAZY LOAD BLUR */

.blur-div {
    position: relative;
}

.blur-div::before {
    content: '';
    position: absolute;
    inset: 0;
    animation: pulse 5s infinite;
}

.blur-div.loaded::before {
    content: none;
}

.blur-div img {
    opacity: 0;
    transition: all .5s ease-in-out;
}

.blur-div.loaded img {
    opacity: 1;
}

/* LAZY LOAD BLUR END*/

@keyframes pulse {
    0% {
        background: rgba(255, 255, 255, 0);
    }

    50% {
        background: rgba(255, 255, 255, 0.3);
    }

    100% {
        background: rgba(255, 255, 255, 0);
    }
}



.home-container {
    color: var(--black);
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
}

.home-container .section-title {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: normal;
}


.home-container .hero-section {
    position: relative;
    padding: 7em 1em 5em 1em;
    height: 58vh;
}

.home-container .hero-section .fill-space {
    display: none;
}

.home-container .hero-section .triangle {
    width: 10em;
    height: 10em;
    transform: rotate(-46.897deg);
    flex-shrink: 0;
    border-radius: 2.36356rem;
    background: rgba(39, 170, 225, 0.51);
    position: absolute;
    top: 7em;
    left: -7em;
    z-index: -1;
}


.home-container .hero-section .hero-left .marker-container {
    display: flex;
    gap: 0.3em;
    margin-bottom: 0.5em;
}

.home-container .hero-section .hero-left .marker-container .marker {
    height: 0.2em;
    width: 1em;
    background: #D7D7D7;
    transition: all 0.4s ease-in-out;
}

.home-container .hero-section .hero-left .marker-container .marker.active {
    background: var(--primary);
}

.home-container .hero-section .hero-left .solutions-title-div .slider-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    position: relative;
    width: fit-content;
    line-height: 1.4rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.20);
}

.home-container .hero-section .hero-left .solutions-title-div .slider-title span.highlight {
    color: var(--secondary);
}

.home-container .hero-section .hero-left .slides-container .slider1 .solutions-title-div .slider-title .hero-line {
    position: absolute;
    right: 0em;
    width: 6.5em;
    top: 1em;
}

.home-container .hero-section .hero-left .slides-container .slider2 .solutions-title-div .slider-title .hero-line {
    position: absolute;
    right: 0.7em;
    width: 6.9em;
    top: 1em;
}

.home-container .hero-section .hero-left .slides-container .slider3 .solutions-title-div .slider-title .hero-line {
    position: absolute;
    right: 0;
    width: 6.5em;
    top: 1em;
}

.home-container .hero-section .hero-left .slides-container .slider4 .solutions-title-div .slider-title .hero-line {
    position: absolute;
    right: 0;
    width: 6.5em;
    top: 1em;
}

.home-container .hero-section .hero-left .slides-container .slider5 .solutions-title-div .slider-title .hero-line {
    position: absolute;
    left: 0em;
    width: 7.5em;
    top: 2em;
}

.home-container .hero-section .hero-left .solutions-p {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 1rem;
    width: 90%;
    color: #4b4b4b;
}

.home-container .hero-section .hero-left .learn-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    margin-top: 0.6em;
    cursor: pointer;
    transition: all .5s ease-in-out;
    background: var(--primary);
    box-shadow: -0.4em 0.4em 0 var(--secondary);
    border-radius: 0.3em;
    padding: 0.5em 1em;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
}



.home-container .hero-section .hero-left .learn-btn span {
    font-size: 0.7rem;
    font-weight: 600;
    text-transform: capitalize;
}

.home-container .hero-section .hero-left .learn-btn img {
    width: 0.35em;
    transition: all .5s ease-in-out;
}


.home-container .hero-section .hero-left .learn-btn:hover img {
    transform: translateX(.3em);
}

.home-container .hero-section .hero-right .hero-img-container {
    display: none;
}

.home-container .hero-section .hero-right .watermark1 {
    width: 18em;
    position: absolute;
    top: 4em;
    z-index: -1;
    right: -5em;
}

.home-container .hero-section .hero-left .hero-circles-div {
    display: none;
}

.home-container .hero-section .hero-left .hero-circles-div .hero-circles {
    opacity: .6;
}

/* SLIDER CSS */

.home-container .hero-section .hero-left .slides-container {
    display: flex;
    position: relative;
}

.home-container .hero-section .hero-left .slides-container .slider {
    opacity: 0;
    z-index: -1;
    transition: all .3s ease-in-out;
    position: absolute;
    inset: 0;

}

.home-container .hero-section .hero-left .slides-container .slider.active {
    opacity: 1;
    width: 100%;
    height: fit-content;
    z-index: 1;

}

/* SLIDER CSS END */


.home-container .clients-section {
    padding: 1em 0;
}

.home-container .clients-section .clients-h1 {
    color: var(--black, #011B25);
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 1.1rem;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.03rem;
    margin: 0 1em;
}


.home-container .clients-section .clients-p {
    color: var(--black, #011B25);
    text-align: center;
    font-family: Montserrat;
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.0125rem;
    margin: 1em;
}

.home-container .clients-section .logos {
    white-space: nowrap;
    display: flex;
    position: relative;
}

.home-container .clients-section .logos .logos-slide {
    display: flex;
    align-items: center;
    animation: 30s scroll-anim infinite linear;
}

@keyframes scroll-anim {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}


.home-container .clients-section .logos .logos-slide img {
    width: 4em;
    margin: 0 0.5em;
}

.home-container .clients-section .logos .logos-slide img.kra {
    width: 5.8em;
}

.home-container .clients-section .logos .logos-slide img.ksc {
    width: 3em;
}

.home-container .clients-section .logos .logos-slide img.kerra {
    width: 3.2em;
}

.home-container .clients-section .logos .logos-slide img.kengen {
    width: 5em;
}



.home-container .growth-section {
    display: flex;
    position: relative;
    margin-top: 1em;
}

.home-container .growth-section .content {
    width: 70vw;
    margin-left: 1em;
}

.home-container .growth-section .content .growth-p {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 0.5rem;
    line-height: 1.1rem;
    color: #4b4b4b;
}

.home-container .growth-section .right-growth img {
    position: absolute;
    top: -2em;
    right: -10.5em;
    width: 13.5em;
}

.home-container .soc-section {
    display: flex;
    position: relative;
    margin: 4em 1em 1em 0;
}

.home-container .soc-section img {
    width: 10em;
    border-radius: 1em;
    position: absolute;
    height: 100%;
    object-fit: cover;
    left: -5em;
}

.home-container .soc-section .content {
    margin-left: 6em;
}

.home-container .soc-section .content p {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 0.5rem;
    line-height: 1.1rem;
    margin: 0.5rem 0;
    color: #4b4b4b;
}

.home-container .soc-section .content .learn-span {
    color: var(--secondary, #2E3092);
    text-align: right;
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 700;
    letter-spacing: 0.01rem;
    text-decoration-line: underline;
    cursor: pointer;
}

.home-container .skills-section {
    background: var(--secondary);
    color: #ffffff;
    position: relative;
    padding: 2em 0 2em 1em;
    margin-top: 3.5em;
}

.home-container .skills-section .content .skills-title {
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 1.1rem;
    font-weight: 700;
}

.home-container .skills-section .skills-img {
    position: absolute;
    right: -9.1em;
    width: 18em;
    top: 2em;

}

.home-container .skills-section .content {
    width: 72vw;
}

.home-container .skills-section .content .skills-p {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    margin-top: 0.5rem;
    line-height: 1.1rem;
    margin: 0.5rem 0;
}

.home-container .skills-section .content .skills-btn {
    display: inline-flex;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    background: var(--secondary, #2E3092);
    box-shadow: 0px 3px 16px 2px #6d8996de;
    color: #ffffff;
    text-decoration: none;
    font-size: 0.6rem;
    font-family: Poppins, sans-serif;
    font-weight: 700;
}

.home-container .testimonials-section {
    margin-top: 4em;
    padding: 0 1em;
}

.home-container .testimonials-section .testimonials-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    margin-top: 2em;
}

.home-container .testimonials-section .testimonials-wrapper .single-testimonial {
    display: flex;
    gap: 0.6em;
}

.home-container .testimonials-section .testimonials-wrapper .single-testimonial .video {
    position: relative;
    width: 5em;
    height: 5em;
    border-radius: 0.5em;
    overflow: hidden;
}

.home-container .testimonials-section .testimonials-wrapper .single-testimonial .video .overlay {
    position: absolute;
    inset: 0;
    background: #00000069;
}

.home-container .testimonials-section .testimonials-wrapper .single-testimonial .video .person-img {
    width: 5em;
    height: 5em;
    object-fit: cover;
    object-position: center;
}

.home-container .testimonials-section .testimonials-wrapper .single-testimonial .video .play-btn {
    font-size: 2.5em;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home-container .testimonials-section .testimonials-wrapper .single-testimonial .content {
    width: 51vw;
}

.home-container .testimonials-section .testimonials-wrapper .single-testimonial .content .test-p {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    line-height: 1.1rem;
    margin-bottom: 0.5rem;
}

.home-container .testimonials-section .testimonials-wrapper .single-testimonial .content .name-p {
    color: var(--black);
    font-family: 'Poppins', sans-serif;
    font-size: 0.65rem;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0.01125rem;
}

.home-container .informed-section {
    padding: 0 1em;
    margin-top: 2.5em;
}

.home-container .informed-section .blogs-wrapper {
    margin-top: 1.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.home-container .informed-section .blogs-wrapper a {
    text-decoration: none;
    color: var(--black);
}

.home-container .informed-section .blogs-wrapper .single-blog {
    display: flex;
    gap: 0.6em;
    transition: all .5s ease-in-out;
}

.home-container .informed-section .blogs-wrapper .single-blog .img-container {
    width: 5em;
    height: 5em;
    overflow: hidden;
    border-radius: 0.5em;
    position: relative;
}

.home-container .informed-section .blogs-wrapper .single-blog .img-container img {
    width: 5em;
    height: 5em;
    object-fit: cover;
    transition: all .5s ease-in-out;
}

.home-container .informed-section .blogs-wrapper .single-blog:hover .img-container img {
    transform: scale(1.1);
}

.home-container .informed-section .blogs-wrapper .single-blog .img-container .overlay {
    position: absolute;
    inset: 0;
    background: #00000069;
}

.home-container .informed-section .blogs-wrapper .single-blog .content {
    width: 51vw;
}

.home-container .informed-section .blogs-wrapper .single-blog .content .date-p {
    color: var(--black);
    font-family: 'Poppins', sans-serif;
    font-size: 0.65rem;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0.01rem;
}

.home-container .informed-section .blogs-wrapper .single-blog .content .blog-title {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1rem;
    margin: 0.2rem 0;
}

.home-container .informed-section .blogs-wrapper .single-blog .content .blog-p {
    font-size: 0.65rem;
    letter-spacing: 0.01rem;
    line-height: 1.1rem;
    margin-bottom: 0.5rem;
}



/* MEDIA QUERIES */

@media(min-width: 281px) {
    .home-container .hero-section .hero-left .slides-container .slider3 .solutions-title-div .slider-title .hero-line {
        right: 0;
        left: unset;
        top: 0.8em;
    }
}

@media(min-width: 291px) {
    .home-container .hero-section .hero-left .slides-container .slider5 .solutions-title-div .slider-title .hero-line {
        right: 0;
        right: 0;
        left: unset;
        top: 1em;
    }
}

@media(min-width: 300px) {

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .content {
        width: 60vw;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content {
        width: 60vw;
    }
}

@media(min-width: 400px) {
    .home-container .hero-section {
        padding: 8em 1.5em 5em 1.5em;
    }

    .home-container .section-title {
        font-size: 1.2rem;
    }

    .home-container .hero-section .triangle {
        top: 8em;
        left: -6.5em;
    }

    .home-container .hero-section .hero-right .watermark1 {
        width: 21em;
        top: 3em;
        right: -1em;
    }

    .home-container .hero-section .hero-left .solutions-title-div .slider-title {
        font-size: 1.4rem;
    }

    .home-container .hero-section .hero-left .solutions-p {
        font-size: .7rem;
    }

    .home-container .hero-section .hero-left .learn-btn span {
        font-size: .8rem;
    }

    .home-container .hero-section .hero-left .learn-btn img {
        width: .43em;
    }

    .home-container .clients-section {
        padding: 2em 0 1em 0;
    }

    .home-container .clients-section .clients-h1 {
        margin: 0 1.5em;
    }

    .home-container .clients-section .clients-p {
        margin: 1em 1.5em;
    }

    .home-container .clients-section .logos .logos-slide img.ksc {
        width: 3.4em;
    }

    .home-container .clients-section .logos .logos-slide img.kerra {
        width: 3.5em;
    }

    .home-container .clients-section .logos .logos-slide img.kemri {
        width: 2.5em;
    }

    .home-container .growth-section .content {
        width: 59vw;
        margin-left: 1.5em;
    }

    .home-container .growth-section .content .growth-p {
        font-size: .7rem;
        margin-top: .6rem;
        line-height: 1.3rem;
    }

    .home-container .growth-section .right-growth {
        position: relative;
        width: 41vw;

    }

    .home-container .growth-section .right-growth img {
        right: -3.5em;
        top: -1em;
    }



    .home-container .growth-section .right-growth .squares-div .square {
        width: 10em;
        height: 10em;
        border-radius: 2em;
        background: rgba(39, 170, 225, 0.51);
        position: absolute;
    }



    .home-container .growth-section .right-growth img {
        right: 0;
        top: 0;
        z-index: 2;
    }

    .home-container .growth-section .right-growth .squares-div .square.square1 {
        right: -1em;
        top: 2em;
    }

    .home-container .growth-section .right-growth .squares-div .square.square2 {
        right: -4em;
    }

    .home-container .soc-section {
        margin: 5em 1.5em 1em 0;
    }

    .home-container .soc-section img {
        width: 10em;
        height: 100%;
        left: -3em;
    }

    .home-container .soc-section .content {
        margin-left: 8em;
    }

    .home-container .soc-section .content p {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .home-container .skills-section {
        padding: 2em 0 2em 1.5em;
    }

    .home-container .skills-section .content {
        width: 70vw;
    }

    .home-container .skills-section .content .skills-title {
        font-size: 1.2rem;
    }

    .home-container .skills-section .content .skills-p {
        font-size: .7rem;
        line-height: 1.3rem;
        margin: 0.7rem 0;
    }

    .home-container .skills-section .skills-img {
        width: 20em;
        right: -8em;
        top: 1em;
    }

    .home-container .testimonials-section {
        margin-top: 5em;
        padding: 0 1.5em;
    }

    .home-container .testimonials-section .testimonials-wrapper {
        gap: 2em;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .content .test-p {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .video {
        width: 8em;
        height: unset;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .video .person-img {
        width: 100%;
        height: 100%;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .content .name-p {
        font-size: .7rem;
    }

    .home-container .informed-section {
        margin-top: 3.5em;
        padding: 0 1.5em;
    }

    .home-container .informed-section .blogs-wrapper {
        margin-top: 2em;
        gap: 2em;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .img-container {
        width: 8em;
        height: unset;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .img-container img {
        height: 100%;
        width: 100%;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .date-p {
        font-size: .7rem;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .blog-title {
        font-size: 1rem;
        line-height: 1.3rem;
        margin: 0.3rem 0;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .blog-p {
        font-size: .7rem;
        line-height: 1.3rem;
    }

}


@media(min-width: 500px) {
    .home-container .hero-section .triangle {
        top: 7em;
    }

    .home-container .clients-section .logos .logos-slide img.kengen {
        width: 6em;
    }

    .home-container .clients-section .logos .logos-slide img.kemri {
        width: 3em;
    }
}

@media(min-width: 550px) {
    .home-container .hero-section {
        display: flex;
        height: 63vh;
    }

    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 1.4rem;
    }

    .home-container .hero-section .triangle {
        width: 29em;
        height: 29em;
        top: 10em;
        left: -16.5em;
    }

    .home-container .hero-section .hero-left {
        width: 60vw;
        position: relative;
    }

    .home-container .hero-section .hero-left::after {
        content: '';
        height: 60%;
        width: 7em;
        background: #ffffff;
        display: block;
        position: absolute;
        top: 0;
        right: 2em;
    }

    .home-container .hero-section .hero-left.active::after {
        opacity: 0;
    }

    .home-container .hero-section .hero-right {
        position: relative;
    }

    .home-container .hero-section .hero-right .watermark {
        display: none;
    }

    .home-container .hero-section .hero-right .hero-img-container {
        display: block;
        width: 13em;
        height: 13em;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: -1.5em;
        left: -1.8em;
    }

    .home-container .hero-section .hero-right .hero-img-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .home-container .hero-section .hero-left .solutions-title-div .slider-title {
        font-size: 1.5rem;
    }

    .home-container .hero-section .hero-left .solutions-p {
        margin-top: unset;
        margin: 1.5rem 0;
        font-size: .75rem;
    }

    .home-container .hero-section .hero-left .learn-btn {
        margin-top: unset;
        background: var(--primary);
        color: #ffffff;
        padding: 0.5em 1em;
        border-radius: 0.3em;
        box-shadow: -0.4em 0.4em 0 var(--secondary);
        gap: .8em;
    }

    .home-container .hero-section .hero-left .learn-btn .right-arrow-white {
        display: block;
    }

    .home-container .hero-section .hero-left .learn-btn .right-arrow {
        display: none;
    }

    .home-container .hero-section .hero-left .learn-btn:hover {
        box-shadow: -0.5em 0.5em 0 var(--secondary);
    }


    .home-container .hero-section .hero-left .hero-circles-div {
        position: absolute;
        width: 20em;
        right: -14em;
        top: -5em;
        display: block;
    }

    .home-container .hero-section .hero-left .hero-circles-div .hero-circles {
        width: 100%;
        height: 100%;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle {
        width: 0.7em;
        position: absolute;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle1 {
        top: 4.3em;
        left: 1.2em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle2 {
        top: 11.35em;
        left: -0.1em;
    }

    .home-container .clients-section {
        padding: 5em 0 1em 0;
    }

    .home-container .clients-section .logos .logos-slide {
        gap: 1em;
        margin-left: 1em;
    }

    .home-container .clients-section .logos .logos-slide img {
        width: 6em;
    }

    .home-container .clients-section .logos .logos-slide img.kra {
        width: 7.5em;
    }

    .home-container .growth-section .right-growth img {
        width: 17em;
        top: -3em;
    }

}

@media(min-width: 585px) {
    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 1.5rem;
    }
}


@media(min-width: 600px) {

    .home-container .hero-section .triangle {
        top: 12em;
    }


    .home-container .section-title {
        font-size: 1.4rem;
    }

    .home-container .appear-right {
        transform: translateX(100px);
    }

    .home-container .hero-section {
        padding: 9.5em 1.875em 5em 1.875em;
    }

    .home-container .hero-section .hero-right .hero-img-container {
        width: 14em;
        height: 14em;
        transform: unset;
        top: -2.5em;
        left: -2em;
    }

    .home-container .hero-section .hero-left .marker-container {
        margin-bottom: 1em;
        gap: 0.35em;
    }

    .home-container .hero-section .hero-left .marker-container .marker {
        height: 0.21em;
        width: 1.7em;
    }

    .home-container .hero-section .hero-left .solutions-title-div .slider-title {
        font-size: 1.6rem;
    }

    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 1.5rem;
    }

    .home-container .hero-section .hero-left .solutions-p {
        font-size: .8rem;
        margin: 1.3rem 0;
    }

    .home-container .hero-section .hero-left .learn-btn span {
        font-size: .9rem;
    }

    .home-container .hero-section .hero-left .learn-btn img {
        width: .5em;
    }

    .home-container .hero-section .hero-left .hero-circles-div {
        width: 22em;
        right: -15em;
        top: -6em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle1 {
        top: 5.3em;
        left: 1.1em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle2 {
        top: 13.85em;
        left: 0.1em;
    }


    .home-container .clients-section .clients-p {
        margin: 1em 1.5em 0 1.5em;
    }

    .home-container .clients-section .logos .logos-slide img.ksc {
        width: 3.5em;
    }

    .home-container .clients-section .logos .logos-slide img.kerra {
        width: 4em;
    }

    .home-container .growth-section {
        margin-top: 3em;
    }

    .home-container .growth-section .content {
        margin-left: 1.875em;
    }

    .home-container .growth-section .content .growth-p {
        line-height: 1.5rem;
    }

    .home-container .growth-section .right-growth .squares-div .square {
        width: 12em;
        height: 12em;
    }

    .home-container .growth-section .right-growth img {
        width: 19em;
    }

    .home-container .growth-section .right-growth .squares-div .square.square2 {
        top: -2em;
    }

    .home-container .soc-section {
        margin: 9em 1.875em 1em 0;
    }

    .home-container .soc-section .content {
        margin-left: 14em;
    }

    .home-container .soc-section .content p {
        line-height: 1.5rem;
    }

    .home-container .soc-section img {
        width: 15em;
    }

    .home-container .soc-section .content .learn-span {
        font-size: .85rem;
    }

    .home-container .skills-section {
        padding: 2em 0 2em 1.875em;
        margin-top: 4.5em;
    }

    .home-container .skills-section .content {
        width: 64vw;
    }

    .home-container .skills-section .content .skills-p {
        line-height: 1.5rem;
    }

    .home-container .skills-section .content .skills-title {
        font-size: 1.4rem;
    }

    .home-container .skills-section .content .skills-btn {
        font-size: .7rem;
    }

    .home-container .skills-section .skills-img {
        width: 25em;
        top: -3em;
    }

    .home-container .testimonials-section {
        margin-top: 6em;
        padding: 0 1.875em;
    }

    .home-container .testimonials-section .testimonials-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial {
        flex-direction: column;
    }


    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .video {
        width: 100%;
        height: 9em;
        border-radius: 0.5em 0.5em 0 0;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .video .play-btn {
        font-size: 4em;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .content {
        width: unset;
    }

    .home-container .informed-section {
        padding: 0 1.875em;
    }

    .home-container .informed-section .blogs-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .home-container .informed-section .blogs-wrapper .single-blog {
        flex-direction: column;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content {
        width: unset;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .img-container {
        width: 100%;
        height: 8em;
    }

}

@media(min-width: 640px) {
    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 1.6rem;
    }
}

@media(min-width: 650px) {

    .home-container .hero-section {
        padding: 11.5em 1.875em 5em 1.875em;
    }

    .home-container .hero-section .hero-left .hero-circles-div {
        width: 21em;
        right: -14em;
        top: -7em;
    }

    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 1.53rem;
    }

    .home-container .hero-section .hero-right .hero-img-container {
        top: -4em;
        left: -3em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle1 {
        top: 6.32em;
        left: 0.5em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle2 {
        top: 12.85em;
    }

    .home-container .clients-section .clients-h1 {
        font-size: 1.2rem;
    }

    .home-container .clients-section .clients-p {
        font-size: .85rem;
    }

    .home-container .soc-section .content {
        margin-left: 15em;
    }

    .home-container .growth-section .content .growth-p {
        font-size: .75rem;
        margin-top: .9rem;
        line-height: 1.5rem;
    }


    .home-container .soc-section .content p {
        font-size: .75rem;
        line-height: 1.5rem;
        margin: 0.8rem 0;
    }

    .home-container .soc-section img {
        width: 16em;
    }

    .home-container .skills-section .content .skills-p {
        font-size: .75rem;
        line-height: 1.5rem;
        margin: 0.8rem 0;
    }

    .home-container .skills-section .skills-img {
        width: 27em;
        right: -6em;
        top: -5em;
    }

    .home-container .testimonials-section .testimonials-wrapper {
        margin-top: 2.5em;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .content .test-p {
        font-size: .75rem;
        line-height: 1.5rem;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .content .name-p {
        font-size: .8rem;
    }

    .home-container .testimonials-section {
        margin-top: 5em;
    }

    .home-container .informed-section .blogs-wrapper {
        margin-top: 2.5em;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .date-p {
        font-size: .75rem;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .blog-title {
        font-size: 1.1rem;
        line-height: 1.4rem;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .blog-p {
        font-size: .75rem;
        line-height: 1.5rem;
    }

}

@media(min-width: 670px) {
    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 1.6rem;
    }
}

@media(min-width: 700px) {

    .home-container .testimonials-section .testimonials-wrapper {
        grid-gap: 4em;
    }

    .home-container .hero-section .hero-left .solutions-p {
        max-width: 70%;
    }

    .home-container .hero-section .hero-left .hero-circles-div {
        width: 22em;
    }

    .home-container .hero-section .hero-right .hero-img-container {
        width: 15em;
        height: 15em;
        left: -3.2em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle2 {
        left: -0.1em;
    }

    .home-container .clients-section {
        padding: 6em 0 1em 0;
    }

    .home-container .clients-section .clients-h1 {
        font-size: 1.4rem;
    }

    .home-container .clients-section .clients-p {
        font-size: .9rem;
    }

    .home-container .clients-section .logos .logos-slide img.kengen {
        width: 7em;
    }

    .home-container .clients-section .logos .logos-slide img.ksc {
        width: 4em;
    }

    .home-container .clients-section .logos .logos-slide img.kemri {
        width: 3.3em;
    }

    .home-container .growth-section .right-growth img {
        width: 22em;
        top: -5em;
    }
}

@media(min-width: 750px) {

    .home-container .hero-section {
        padding: 11.5em 1.875em 5em 1.875em;
    }

    .home-container .clients-section {
        padding: 8em 0 1em 0;
    }

    .home-container .hero-section .triangle {
        width: 32em;
        height: 32em;
        top: 12em;
        left: -18.5em;
    }

    .home-container .hero-section .hero-left .solutions-title-div .slider-title {
        font-size: 1.rem;
    }

    .home-container .clients-section .logos .logos-slide {
        gap: 2em;
        margin-left: 2em;
    }

    .home-container .growth-section .content {
        margin-top: 2em;
    }

    .home-container .growth-section .right-growth img {
        width: 24em;
    }

    .home-container .soc-section img {
        width: 19em;
    }

    .home-container .soc-section .content {
        margin-left: 19em;
    }

    .home-container .skills-section .content {
        width: 60vw;
    }

    .home-container .skills-section .skills-img {
        width: 30em;
        right: -6em;
        top: -7em;
    }

    .home-container .skills-section {
        margin-top: 5.5em;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .img-container {
        height: 11em;
    }
}

@media(min-width: 800px) {
    .home-container .hero-section {
        padding: 11.5em 3.125em 5em 3.125em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle2 {
        top: 11.85em;
        left: -0.3em;
    }

    .home-container .hero-section .hero-left .solutions-title-div .slider-title {
        font-size: 1.8rem;
    }

    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 1.75rem;
    }

    .home-container .hero-section .hero-left::after {
        width: 14em;
    }

    .home-container .hero-section .hero-right .hero-img-container {
        width: 17em;
        height: 17em;
        left: -6em;
    }

    .home-container .hero-section .hero-left .hero-circles-div {
        width: 24em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle1 {
        left: 1em;
    }

    .home-container .growth-section .content {
        margin-left: 3.125em;
    }

    .home-container .soc-section {
        margin: 9em 3.125em 1em 0;
    }

    .home-container .soc-section img {
        width: 22em;
    }

    .home-container .appear-left-soc {
        transform: translateX(-70px);
    }

    .home-container .soc-section .content {
        margin-left: 22em;
    }

    .home-container .skills-section {
        padding: 3em 0 3em 3.125em;
    }

    .home-container .skills-section .content .skills-p {
        margin: 1rem 0;
    }

    .home-container .testimonials-section {
        padding: 0 3.125em;
    }

    .home-container .informed-section {
        padding: 0 3.125em;
    }

}

@media(min-width: 830px) {
    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 1.8rem;
    }
}


@media(min-width: 850px) {

    .home-container .clients-section {
        padding: 9em 0 1em 0;
    }

    .home-container .clients-section .clients-h1 {
        margin: 0 3.125rem;
    }

    .home-container .testimonials-section .testimonials-wrapper {
        display: flex;
        flex-direction: row;
        grid-gap: unset;
        gap: 1em;
        margin-top: 3em;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial {
        width: 33%;
    }
}

@media(min-width: 900px) {

    .home-container .section-title {
        font-size: 2rem;
    }

    .home-container .hero-section {
        padding: 12.5em 3.125em 5em 3.125em;
    }

    .home-container .hero-section .triangle {
        width: 35em;
        height: 35em;
        top: 13em;
        left: -19.5em;
    }


    .home-container .hero-section .hero-left .solutions-title-div .slider-title {
        font-size: 2.3rem;
    }

    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 2.15rem;
    }

    .home-container .hero-section .hero-left .marker-container {
        transform: translateY(-1em);
    }

    .home-container .hero-section .hero-left::after {
        width: 19em;
        height: 5em;
    }

    .home-container .hero-section .hero-left .solutions-p {
        margin: 3rem 0;
        font-size: 1rem;
    }


    .home-container .hero-section .hero-left .hero-circles-div {
        width: 25em;
        right: -15em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle2 {
        top: 12em;
    }


    .home-container .hero-section .hero-left .learn-btn span {
        font-size: 1.1rem;
    }

    .home-container .hero-section .hero-right .hero-img-container {
        left: -4em;
    }

    .home-container .hero-section .hero-left .learn-btn img {
        width: .65em;
    }

    .home-container .clients-section {
        padding: 15em 0 1em 0;
    }

    .home-container .clients-section .clients-h1 {
        font-size: 1.7rem;
    }

    .home-container .clients-section .clients-p {
        font-size: 1.1rem;
        margin: 1.2em 1.5em 0 1.5em;
    }

    .home-container .clients-section .logos .logos-slide {
        gap: 3em;
        margin-left: 3em;
    }

    .home-container .clients-section .logos .logos-slide img {
        width: 7em;
    }

    .home-container .clients-section .logos .logos-slide img.kra {
        width: 8.5em;
    }

    .home-container .clients-section .logos .logos-slide img.kemri {
        width: 3.7em;
    }

    .home-container .clients-section .logos .logos-slide img.ksc {
        width: 4.5em;
    }

    .home-container .clients-section .logos .logos-slide img.kengen {
        width: 8em;
    }

    .home-container .growth-section .content .growth-p {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: .015rem;
    }

    .home-container .soc-section .content p {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: .015rem;
    }

    .home-container .soc-section .content .learn-span {
        font-size: 1.3rem;
    }

    .home-container .skills-section .content .skills-title {
        font-size: 2rem;
    }

    .home-container .skills-section .content .skills-p {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: .015rem;
        margin: 1.3rem 0;
    }

    .home-container .skills-section .content .skills-btn {
        font-size: 1rem;
        padding: .8rem;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .content .test-p {
        font-size: .9rem;
        line-height: 1.7rem;
        letter-spacing: .02rem;
        margin-bottom: .7rem;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .content .name-p {
        font-size: .9rem;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .blog-title {
        font-size: 1.3rem;
        margin: .5rem 0;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .date-p {
        font-size: .9rem;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .blog-p {
        font-size: .9rem;
        line-height: 1.7rem;
    }

}


@media(min-width:950px) {

    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 2.3rem;
    }

    .home-container .growth-section .right-growth img {
        width: 28em;
    }

    .home-container .growth-section .right-growth .squares-div .square {
        width: 13em;
        height: 13em;
    }

    .home-container .growth-section .right-growth .squares-div .square.square1 {
        right: 1em;
    }

    .home-container .growth-section .right-growth .squares-div .square.square2 {
        right: -2em;
    }

    .home-container .soc-section img {
        width: 25em;
    }

    .home-container .soc-section .content {
        margin-left: 24em;
    }
}

@media(min-width: 1000px) {
    .home-container .hero-section {
        padding: 12.5em 5em 5em 5em;
    }

    .loader-wrapper img {
        width: 40em;
    }

    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 2.2rem;
    }

    .home-container .appear-right {
        transform: translateX(160px);
    }

    .home-container .appear-left-btn {
        transform: translateX(-100px);
    }

    .home-container .hero-section .hero-left .hero-circles-div {
        width: 27em;
    }

    .home-container .hero-section .hero-right .hero-img-container {
        width: 19em;
        height: 19em;
        left: -7em;
    }

    .home-container .hero-section .hero-left::after {
        height: 8em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle1 {
        left: 1.5em;
        top: 6.3em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle2 {
        top: 14.99em;
        left: -0.2em;
    }

    .home-container .clients-section {
        padding: 17em 0 1em 0;
    }

    .home-container .clients-section .logos .logos-slide img.kerra {
        width: 4.3em;
    }

    .home-container .growth-section .content {
        margin-left: 5em;
    }

    .home-container .soc-section {
        margin: 9em 5em 1em 0;
    }

    .home-container .soc-section img {
        width: 27em;
    }

    .home-container .soc-section .content {
        margin-left: 26em;
    }

    .home-container .skills-section {
        padding: 3em 0 3em 5em;
    }

    .home-container .skills-section .skills-img {
        width: 40em;
        right: -11em;
        top: -7em;
    }

    .home-container .testimonials-section {
        padding: 0 5em;
    }

    .home-container .informed-section {
        padding: 0 5em;
    }
}

@media(min-width: 1030px) {
    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 2.3rem;
    }
}


@media(min-width: 1050px) {
    .home-container .hero-section .hero-left .solutions-title-div .slider-title {
        font-size: 2.5rem;
    }

    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 2.35rem;
    }

    .home-container .hero-section .hero-left .solutions-p {
        font-size: 1.1rem;
    }

    .home-container .testimonials-section .testimonials-wrapper {
        gap: 1em;
    }

    .home-container .informed-section .blogs-wrapper {
        gap: 3em;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .img-container {
        height: 14em;
    }
}

@media(min-width: 1100px) {

    .home-container .hero-section {
        padding: 14.5em 5em 5em 5em;
    }

    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 2.5rem;
    }

    .home-container .section-title {
        font-size: 2.3em;
        font-weight: 900;
    }

    .home-container .hero-section .triangle {
        width: 38em;
        height: 38em;
        top: 16em;
    }

    .home-container .hero-section .fill-space {
        display: block;
    }

    .home-container .hero-section .fill-space .rocket-3d {
        position: absolute;
        top: 28em;
        left: 24em;
        width: 10em;
        animation: rocket-anim 1.5s infinite alternate;
        transform-origin: bottom left;
        transition: all .5s ease-in-out;

    }

    @keyframes rocket-anim {
        from {
            transform: translate(0);
        }

        to {
            transform: translate(1em, -1em);
        }
    }

    .home-container .clients-section {
        padding: 18em 0 1em 0;
    }

    .home-container .clients-section .clients-h1 {
        font-size: 1.9rem;
    }

    .home-container .clients-section .logos .logos-slide {
        gap: 4em;
        margin-left: 4em;
    }

    .home-container .clients-section .logos .logos-slide img {
        width: 8em;
    }

    .home-container .clients-section .logos .logos-slide img.kra {
        width: 9.5em;
    }

    .home-container .clients-section .logos .logos-slide img.ksc {
        width: 5.5em;
    }

    .home-container .clients-section .logos .logos-slide img.kerra {
        width: 4.7em;
    }

    .home-container .clients-section .logos .logos-slide img.kemri {
        width: 4.3em;
    }

    .home-container .clients-section .clients-p {
        font-size: 1.25rem;
    }

    .home-container .growth-section .content .growth-p {
        font-size: 1.15rem;
    }

    .home-container .soc-section .content p {
        font-size: 1.15rem;
    }

    .home-container .skills-section .content .skills-title {
        font-size: 2.3rem;
    }

    .home-container .skills-section .content .skills-p {
        font-size: 1.15rem;
        margin: 1.5rem 0;
    }

    .home-container .skills-section .skills-img {
        width: 40em;
    }

    .home-container .informed-section {
        margin-top: 4.5em;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .date-p {
        font-size: 1rem;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .blog-title {
        font-size: 1.5rem;
        line-height: 1.8rem;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .blog-p {
        font-size: 1rem;
    }
}

@media(min-width: 1150px) {
    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .video {
        height: 12em;
    }

    .home-container .testimonials-section .testimonials-wrapper {
        margin-top: 3.5em;
    }
}

@media(min-width: 1200px) {
    .home-container .hero-section {
        padding: 14.5em 6.25em 11em 6.25em;
    }

    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 2.7rem;
    }

    .home-container .hero-section .fill-space .rocket-3d {
        top: 30em;
        left: 26em;
        width: 12em;
    }

    .home-container .hero-section .hero-left .solutions-title-div .slider-title {
        font-size: 2.9rem;
        font-weight: 900;
    }

    .home-container .hero-section .hero-left .solutions-p {
        font-size: 1.3rem;
    }

    .home-container .hero-section .hero-left .learn-btn {
        box-shadow: -0.6em 0.6em 0 var(--secondary);
    }

    .home-container .hero-section .hero-left .learn-btn:hover {
        box-shadow: -0.7em 0.7em 0 var(--secondary);
    }

    .home-container .hero-section .hero-left .learn-btn span {
        font-size: 1.3rem;
    }

    .home-container .hero-section .hero-left .learn-btn img {
        width: .7em;
    }

    .home-container .hero-section .hero-left .learn-btn {
        padding: 0.7em 1.5em;
    }

    .home-container .hero-section .hero-left .hero-circles-div {
        width: 29em;
        top: -8em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle1 {
        left: 1.4em;
        top: 7.3em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle2 {
        top: 15.99em;
    }

    .home-container .hero-section .hero-right .hero-img-container {
        width: 20em;
        height: 20em;
        left: -6em;
    }

    .home-container .hero-section .hero-left .hero-circles-div {
        right: -18em;
    }

    .home-container .hero-section .hero-left .solutions-p {
        margin: 4rem 0;
    }

    .home-container .hero-section .hero-left .marker-container .marker {
        height: .35em;
        width: 2em;
    }

    .home-container .clients-section {
        padding: 16em 0 1em 0;
    }


    .home-container .clients-section .clients-h1 {
        margin: 0 5rem;
    }

    .home-container .clients-section .logos .logos-slide img.ksc {
        width: 6.5em;
    }

    .home-container .clients-section .logos .logos-slide img.kerra {
        width: 5.3em;
    }

    .home-container .clients-section .logos .logos-slide img.kengen {
        width: 10em;
    }

    .home-container .clients-section .logos .logos-slide img.kemri {
        width: 4.8em;
    }

    .home-container .growth-section .content {
        margin-left: 6.25em;
    }

    .home-container .growth-section .right-growth img {
        width: 31em;
    }

    .home-container .growth-section .right-growth .squares-div .square {
        width: 16em;
        height: 16em;
    }

    .home-container .soc-section {
        margin: 10em 6.25em 1em 0;
    }

    .home-container .soc-section img {
        width: 31em;
    }

    .home-container .soc-section .content {
        margin-left: 31em;
    }

    .home-container .skills-section {
        padding: 3em 0 3em 6.25em;
        margin-top: 6.5em;
    }

    .home-container .skills-section .skills-img {
        width: 42em;
        top: -9em;
    }

    .home-container .testimonials-section {
        padding: 0 6.25em;
        margin-top: 6em;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .content .test-p {
        font-size: 1rem;
        line-height: 1.9rem;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .content .name-p {
        font-size: 1rem;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .img-container {
        height: 16em;
    }

    .home-container .testimonials-section .testimonials-wrapper {
        gap: 3em;
    }

    .home-container .informed-section {
        padding: 0 6.25em;
    }

    .home-container .informed-section .blogs-wrapper {
        gap: 4em;
    }

}

@media(min-width: 1250px) {
    .home-container .growth-section .right-growth img {
        width: 33em;
        top: -8em;
    }
}

@media(min-width: 1270px) {
    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 2.9rem;
    }
}

@media(min-width: 1300px) {

    .loader-wrapper img {
        width: 45em;
    }

    .home-container .hero-section .triangle {
        width: 42em;
        height: 42em;
        top: 18em;
        left: -21em;
    }

    .home-container .hero-section .hero-left .hero-circles-div {
        width: 32em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle1 {
        left: 2.1em;
    }

    .home-container .hero-section .hero-right .hero-img-container {
        width: 22em;
        height: 22em;
        left: -8em;
    }


    .home-container .clients-section .clients-h1 {
        margin: 0 6.25rem;
        font-size: 2.3rem;
    }

    .home-container .clients-section .clients-p {
        font-size: 1.5rem;
    }

    .home-container .clients-section .logos .logos-slide {
        gap: 5em;
        margin-left: 5em;
    }

    .home-container .clients-section .logos .logos-slide img {
        width: 9em;
    }

    .home-container .clients-section .logos .logos-slide img.kerra {
        width: 5.6em;
    }

    .home-container .clients-section .logos .logos-slide img.kemri {
        width: 5em;
    }

    .home-container .soc-section .content {
        margin-left: 31em;
    }

    .home-container .soc-section .content .learn-span {
        font-size: 1.6rem;
    }

    .home-container .soc-section img {
        width: 31em;
    }

}

@media(min-width: 1400px) {

    .home-container .hero-section {
        padding: 16.5em 6.25em 11em 6.25em;
    }

    .home-container .section-title {
        font-size: 2.8rem;
    }

    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 3.15rem;
    }

    .home-container .hero-section .triangle {
        top: 19em;
    }

    .home-container .hero-section .fill-space .rocket-3d {
        width: 15em;
        top: 32em;
        left: 28em;
    }

    .home-container .hero-section .hero-left .solutions-title-div .slider-title {
        font-size: 3.3rem;
    }

    .home-container .clients-section .clients-h1 {
        font-size: 2.5rem;
    }

    .home-container .clients-section .clients-p {
        font-size: 1.8rem;
    }

    .home-container .clients-section .logos .logos-slide img.kerra {
        width: 6.3em;
    }

    .home-container .clients-section .logos .logos-slide img.ksc {
        width: 7.5em;
    }   

    .home-container .clients-section .logos .logos-slide img.kengen {
        width: 11em;
    }

    .home-container .clients-section .logos .logos-slide img.kemri {
        width: 5.5em;
    }

    .home-container .growth-section .content .growth-p {
        font-size: 1.3rem;
        line-height: 2.7rem;
    }

    .home-container .soc-section .content p {
        font-size: 1.3rem;
        line-height: 2.7rem;
    }

    .home-container .skills-section {
        padding: 4em 0 4em 6.25em;
    }

    .home-container .skills-section .content .skills-title {
        font-size: 2.8rem;
    }

    .home-container .skills-section .content .skills-p {
        font-size: 1.3rem;
        line-height: 2.7rem;
    }

    .home-container .skills-section .content .skills-btn {
        font-size: 1.3rem;
        padding: .8rem 1.5rem;
    }

    .home-container .skills-section .skills-img {
        width: 48em;
    }

    .home-container .testimonials-section .testimonials-wrapper {
        margin-top: 4.5em;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .video .play-btn {
        font-size: 5em;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .content .test-p {
        font-size: 1.2rem;
        line-height: 2.5rem;
    }

    .home-container .testimonials-section .testimonials-wrapper .single-testimonial .content .name-p {
        font-size: 1.3rem;
    }

    .home-container .informed-section .blogs-wrapper {
        margin-top: 4em;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .date-p {
        font-size: 1.2rem;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .blog-title {
        font-size: 1.9rem;
        line-height: 2rem;
        margin: .8rem 0;
    }

    .home-container .informed-section .blogs-wrapper .single-blog .content .blog-p {
        font-size: 1.3rem;
        line-height: 2.5rem;
    }

}

@media(min-width: 1451px) {
    .home-container .hero-section {
        padding: 17.5em 9.375em 14em 9.375em;
    }

    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 3.1rem;
    }

    .home-container .hero-section .triangle {
        width: 47em;
        height: 47em;
        top: 19em;
        left: -23em;
    }

    .home-container .hero-section .hero-left .solutions-title-div .slider-title {
        font-size: 3.3rem;
    }

    .home-container .hero-section .hero-left .hero-circles-div {
        width: 33em;
    }

    .home-container .hero-section .hero-right .hero-img-container {
        width: 23em;
        height: 23em;
        left: -11em;
    }

    .home-container .hero-section .hero-left .hero-circles-div .small-circle.small-circle1 {
        left: 2.2em;
    }
}

@media(min-width: 1520px) {
    .home-container .hero-section .hero-left .slider5 .solutions-title-div .slider-title {
        font-size: 3.3rem;
    }
}

@media(min-width: 1600px) {

    .loader-wrapper img {
        width: 50em;
    }

    .home-container .hero-section {
        padding: 20.5em 9.375em 14em 9.375em;
    }

    .home-container .clients-section .logos .logos-slide img.kengen {
        width: 12em;
    }

    .home-container .clients-section .logos .logos-slide img.kemri {
        width: 6.5em;
    }

    .home-container .hero-section .triangle {
        top: 21em;
    }
}

/* MEDIA QUERIES END */