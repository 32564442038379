@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}

.footer-container {
    font-family: 'Poppins', sans-serif;
    margin-top: 3em;
}

.footer-container .footer-wrapper {
    padding: 1.5em 1em;
    background: var(--primary);
    color: #ffffff;
}

.footer-container .footer-wrapper .footer-title {
    font-family: 'Poppins', sans-serif;
    font-size: .95rem;
    width: fit-content;
}

.footer-container .footer-wrapper .about-ict .footer-head {
    color: var(--black);
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 700;
}

.footer-container .footer-wrapper .about-ict .footer-head .mentors-span {
    color: #ffffff;
}

.footer-container .footer-wrapper .about-ict .footer-head .solutions-span {
    color: var(--secondary);
}

.footer-container .footer-wrapper .about-ict .about-p {
    font-size: 0.75rem;
    margin-top: 0.45rem;
    letter-spacing: 0.01rem;
    font-weight: 600;
}

.footer-container .footer-wrapper .services-div {
    margin-top: 2em;
}

.footer-container .footer-wrapper .services-div .services-wrapper {
    margin-top: 0.3em;
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    width: fit-content;
}

.footer-container .footer-wrapper .services-div .services-wrapper .single-service {
    display: flex;
    gap: .2em;
    align-items: baseline;
    width: fit-content;

}


.footer-container .footer-wrapper .services-div .services-wrapper .single-service img {
    width: .4em;
}

.footer-container .footer-wrapper .services-div .services-wrapper .single-service .service-link {
    text-decoration: none;
    color: #ffffff;
    font-size: .6rem;
    letter-spacing: 0.01rem;
    transition: all .5s ease-in-out;
}

.footer-container .footer-wrapper .services-div .services-wrapper .single-service:hover .service-link {
    transform: translateX(0.3em);
}

.footer-container .footer-wrapper .reach-div {
    margin-top: 1.3em;
}

.footer-container .footer-wrapper .reach-div .reach-wrapper {
    margin-top: 0.3em;
    display: flex;
    flex-direction: column;
    gap: 0.6em;
}

.footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact {
    display: flex;
    align-items: center;
    gap: 0.3em;
}

.footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact img {
    width: .8em;
}

.footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact img.phone {
    width: 0.95em;
}

.footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact img.mail {
    width: .95em;
}

.footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact .contact-p {
    font-size: .6rem;
    letter-spacing: .01rem;
}

.footer-container .footer-wrapper .extras-div {
    margin-top: 1.5em;
    display: flex;
    flex-direction: column;
    gap: 0.3em;
}

.footer-container .footer-wrapper .extras-div .single-extra {
    display: flex;
    gap: .3em;
    align-items: center;
}

.footer-container .footer-wrapper .extras-div .single-extra img {
    width: .4em;

}

.footer-container .footer-wrapper .extras-div .single-extra .extra-p {
    font-size: .6rem;
    letter-spacing: .01rem;
    transition: all .5s ease-in-out;
    text-decoration: none;
    color: #ffffff;
}

.footer-container .footer-wrapper .extras-div .single-extra:hover .extra-p {
    transform: translateX(.3em);
}

.footer-container .footer-ribbon {
    position: relative;
    background: var(--secondary);
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    color: #ffffff;
}

.footer-container .footer-ribbon .footer-img {
    position: absolute;
    top: -7em;
    right: -4em;
    width: 9em;
}

.footer-container .footer-ribbon .copyright {
    font-size: .6rem;
}

.footer-container .footer-ribbon .socials {
    display: flex;
    gap: 0.8em;
    margin-top: .4em;
}

.footer-container .footer-ribbon .socials img {
    width: .8em;
}


/* MEDIA QUERIES */

@media(min-width: 400px) {
    .footer-container .footer-wrapper {
        padding: 2em 1.5em 1.5em 1.5em;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 1fr);

    }

    .footer-container .footer-wrapper .about-ict {
        grid-column: 1;
        grid-row: 1;
    }

    .footer-container .footer-wrapper .about-ict .footer-head {
        font-size: 1.1rem;
    }

    .footer-container .footer-wrapper .services-div {
        margin-top: unset;
        grid-row: 1 /span 4;
        grid-column: 2;
    }

    .footer-container .footer-wrapper .services-div .services-wrapper {
        margin-top: .5em;
    }

    .footer-container .footer-wrapper .services-div .services-wrapper .single-service {
        gap: .3em;
    }

    .footer-container .footer-wrapper .reach-div {
        grid-column: 1;
        grid-row: 3 /span 5;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper {
        margin-top: .5em;
    }

    .footer-container .footer-wrapper .extras-div {
        grid-column: 2;
        grid-row: 5;
    }

    .footer-container .footer-ribbon .footer-img {
        top: -11em;
        right: unset;
        width: 11em;
        left: -3em;
    }

    .footer-container .footer-ribbon {
        padding: 1em 1.5em;
    }
}

@media(min-width: 550px) {
    .footer-container .footer-wrapper {
        grid-gap : 1em;
    }

    .footer-container .footer-ribbon {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .footer-container .footer-ribbon .socials {
        margin-top: unset;

    }

    .footer-container .footer-ribbon .footer-img {
        top: -10em;
        width: 12em;
        left: -3.5em;
    }

    .footer-container .footer-wrapper .about-ict .about-p {
        width: 65%;
    }
}

@media(min-width: 600px) {
    .footer-container {
        margin-top: 4em;
    }
    .footer-container .footer-wrapper {
        padding: 2em 1.875em 1.5em 1.875em;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: unset;
    }

    .footer-container .footer-ribbon {
        padding: 1em 1.875em;
    }
}

@media(min-width: 650px) {
    .footer-container .footer-ribbon .copyright {
        font-size: .65rem;
    }

    .footer-container .footer-ribbon .socials {
        gap: 2em;
    }

    .footer-container .footer-ribbon .socials img {
        width: 1em;
    }
}

@media(min-width: 800px) {

    .footer-container .footer-wrapper {
        padding: 3em 3.125em 1.5em 3.125em;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2em;
    }

    .footer-container .footer-wrapper .about-ict .footer-head {
        font-size: 1.3rem;
    }

    .footer-container .footer-wrapper .about-ict .about-p {
        width: unset;
        margin-top: .8rem;
        font-size: .8rem;
    }

    .footer-container .footer-wrapper .services-div .services-wrapper {
        gap: 0.5em;
    }

    .footer-container .footer-wrapper .services-div .services-wrapper .single-service .service-link {
        font-size: .75rem;
    }

    .footer-container .footer-wrapper .extras-div .single-extra .extra-p {
        font-size: .75rem;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact .contact-p {
        font-size: .75rem;
    }

    .footer-container .footer-wrapper .footer-title {
        font-size: 1.1rem;
    }

    .footer-container .footer-wrapper .reach-div {
        grid-column: 3;
        grid-row: 1;
        margin-top: unset;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper {
        gap: .7em;
    }

    .footer-container .footer-wrapper .extras-div {
        grid-column: 3;
        grid-row: 2;
        margin-top: 3em;
    }

    .footer-container .footer-wrapper .services-div {
        width: fit-content;
        transform: translateX(-3em);
    }

    .footer-container .footer-ribbon .footer-img {
        width: 14em;
        top: -13em;
    }

    .footer-container .footer-ribbon .copyright {
        font-size: .75rem;
        letter-spacing: .01rem;
    }

    .footer-container .footer-ribbon {
        padding: 1em 3.125em;
    }

    .footer-container .footer-ribbon .socials {
        gap: 2.5em;
    }

    .footer-container .footer-ribbon .socials img {
        width: 1.15em;
    }   
}

@media(min-width: 900px) {
    .footer-container .footer-wrapper .about-ict {
        max-width: 14em;
    }
}

@media(min-width: 1000px) {
    .footer-container .footer-wrapper {
        padding: 3em 5em 1.5em 5em;
    }

    .footer-container .footer-wrapper .about-ict .footer-head {
        font-size: 1.5rem;
    }

    .footer-container .footer-wrapper .about-ict .about-p {
        font-size: .9rem;
    }

    .footer-container .footer-wrapper .footer-title {
        font-size: 1.4rem;
    }

    .footer-container .footer-wrapper .services-div .services-wrapper {
        margin-top: .8em;
    }

    .footer-container .footer-wrapper .services-div .services-wrapper .single-service .service-link {
        font-size: .85rem;
    }

    .footer-container .footer-wrapper .extras-div .single-extra .extra-p {
        font-size: .85rem;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact {
        gap: .5em;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact .contact-p {
        font-size: .85rem;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact img {
        width: 1em;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact img.phone {
        width: 1.25em;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact img.mail {
        width: 1.35em;
    }

    .footer-container .footer-ribbon .footer-img {
        width: 16em;
        top: -14.5em;
    }

    .footer-container .footer-ribbon {
        padding: 1em 5em;
    }

    .footer-container .footer-ribbon .copyright {
        font-size: .8rem;
    }
}

@media(min-width: 1200px) {
    .footer-container .footer-wrapper {
        padding: 4em 6.25em 3.5em 6.25em;
    }

    .footer-container .footer-wrapper .about-ict .footer-head {
        font-size: 1.9rem;
    }

    .footer-container .footer-wrapper .about-ict .about-p {
        font-size: 1.1rem;
    }

    .footer-container .footer-wrapper .footer-title {
        font-size: 1.6rem;
    }

    .footer-container .footer-wrapper .services-div .services-wrapper .single-service {
        gap: .5em;
    }

    .footer-container .footer-wrapper .services-div .services-wrapper .single-service .service-link {
        font-size: .9rem;
    }

    .footer-container .footer-wrapper .services-div .services-wrapper .single-service img {
        width: 0.5em;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper {
        gap: 1.3em;
        margin-top: 1em;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact img {
        width: 1.2em;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact img.phone {
        width: 1.4em;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact img.mail {
        width: 1.5em;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact {
        gap: .9em;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact .contact-p {
        font-size: .9rem;
    }

    .footer-container .footer-wrapper .extras-div .single-extra {
        gap: .5em;
    }

    .footer-container .footer-wrapper .extras-div .single-extra .extra-p {
        font-size: .9rem;
    }

    .footer-container .footer-wrapper .extras-div .single-extra img {
        width: .5em;
    }

    .footer-container .footer-ribbon .footer-img {
        width: 18em;
        top: -16em;
    }

    .footer-container .footer-ribbon .copyright {
        font-size: .9rem;
    }

    .footer-container .footer-ribbon {
        padding: 1.5em 6.25em;
    }

    .footer-container .footer-ribbon .socials img {
        width: 1.5em;
    }
}

@media(min-width: 1451px) {

    .footer-container .footer-wrapper {
        padding: 4em 9.375em 1.5em 9.375em;
    }

    .footer-container .footer-ribbon {
        padding: 1.5em 9.375em;
    }

    .footer-container .footer-wrapper .about-ict .footer-head {
        font-size: 2rem;
    }

    .footer-container .footer-wrapper .about-ict .about-p {
        font-size: 1.3rem;
    }

    .footer-container .footer-wrapper .footer-title {
        font-size: 2rem;
    }

    .footer-container .footer-wrapper .services-div {
        transform: translateX(-6em);
    }

    .footer-container .footer-wrapper .services-div .services-wrapper {
        margin-top: 1em;
    }

    .footer-container .footer-wrapper .services-div .services-wrapper .single-service .service-link {
        font-size: 1.2rem;
    }

    .footer-container .footer-wrapper .services-div .services-wrapper .single-service {
        gap: .8em;
    }

    .footer-container .footer-wrapper .services-div .services-wrapper .single-service img {
        width: .65em;
    }

    .footer-container .footer-wrapper .extras-div .single-extra img {
        width: .65em;
    }

    .footer-container .footer-wrapper .extras-div .single-extra {
        gap: .8em;
    }

    .footer-container .footer-wrapper .extras-div .single-extra .extra-p {
        font-size: 1.2rem;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact .contact-p {
        font-size: 1.2rem;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact img {
        width: 1.5em;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact img.phone {
        width: 1.8em;
    }

    .footer-container .footer-wrapper .reach-div .reach-wrapper .single-contact img.mail {
        width: 1.9em;
    }

    .footer-container .footer-ribbon .copyright {
        font-size: 1.1rem;
    }

    .footer-container .footer-ribbon .socials img {
        width: 1.8em;
    }
}

@media(min-width: 1500px) {
    .footer-container .footer-wrapper .about-ict {
        max-width: 18em;
    }
}

/* MEDIA QUERIES END */