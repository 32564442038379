@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}

.singlesolution-container {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    color: var(--black);
}

/* Fade In Css */

/* LAZY LOAD BLUR */

.blur-div {
    position: relative;   
}

.blur-div::before {
    content: '';
    position: absolute;
    inset: 0;
    animation: pulse 5s infinite;
}

.blur-div.loaded::before {
    content: none;
}

.blur-div img {
    opacity: 0;
    transition: all .5s ease-in-out;
}

.blur-div.loaded {
    background: none !important;
}

.blur-div.loaded img {
    opacity: 1;
}

/* LAZY LOAD BLUR END*/


.singlesolution-container .appear-right {
    opacity: 0;
    transform: translateX(51px);
    transition: all 1s ease-in-out;
}

.singlesolution-container .appear-right.active {
    opacity: 1;
    transform: translateX(0px);
}

.singlesolution-container .appear-left {
    opacity: 0;
    transform: translateX(-107px);
    transition: all 1s ease-in-out;
}

.singlesolution-container .appear-left.active {
    opacity: 1;
    transform: translateX(0px);
}


/* Fade in CSS End */




.singlesolution-container .solution-left {
    padding: 9em 1em 1em 1em;
}

.singlesolution-container .solution-right {
    display: none;
}

.singlesolution-container .solution-left .section-title {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: normal;
}

.singlesolution-container .solution-left .solution-left-top {
    position: relative;

}

.singlesolution-container .solution-left .solution-left-top .hero-small {
    position: absolute;
    width: 3.5em;
    right: -.8em;
    top: 90%;
}

.singlesolution-container .solution-p {
    font-size: .65rem;
    letter-spacing: .01rem;
    color: #4b4b4b;
    line-height: 1.1rem;
    margin-top: .7em;
}

.singlesolution-container .solution-left .solution-left-value {
    margin-top: 1.5em;
}

.singlesolution-container .solution-left .solution-left-value .values-ul {
    margin-left: 1em;
    margin-top: .7em;
}


.singlesolution-container .solution-left .solution-left-value .values-ul .values-li {
    font-size: .65rem;
    letter-spacing: .01rem;
    color: #4b4b4b;
    line-height: 1.1rem;   
    margin-bottom: .3em;
}


.singlesolution-container .solution-left .solution-left-value .reach-btn {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: .8rem;
    border: 2px solid var(--secondary);
    width: 100%;
    margin: 1.5em 0;
}

.singlesolution-container .solution-right .img-container {
    display: none;
}


/* MEDIA QUERIES */

@media(min-width: 400px) {
    .singlesolution-container .solution-left {
        padding: 9em 1.5em 1em 1.5em;
    }

    .singlesolution-container .solution-p {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .singlesolution-container .solution-left .solution-left-value .values-ul .values-li {
        font-size: .7rem;
        line-height: 1.3rem;
    }

    .singlesolution-container .solution-left .solution-left-top .hero-small {
        width: 4em;
        right: -3em;
        top: 98%;
    }
}

@media(min-width: 450px) {
    .singlesolution-container .solution-left {
        padding: 10em 1.5em 1em 1.5em;
    }
}

@media(min-width: 500px) {
    .singlesolution-container .solution-left .solution-left-top .hero-small {
        width: 5em;
        top: 83%;
    }
}

@media(min-width: 600px) {

    .singlesolution-container .solution-left {
        width: 66vw;
        padding: 10em 1.5em 1em 1.875em;
    }

    .singlesolution-container .solution-left .section-title {
        font-size: 1.5rem;
        font-weight: 900;
    }

    .singlesolution-container .solution-left .solution-left-top .hero-small {
        display: none;
    }

    .singlesolution-container .solution-wrapper {
        display: flex;
        gap: 1em;
    }

    .singlesolution-container .solution-right {
        display: block;
    }

    .singlesolution-container .solution-wrapper .solution-right {
        position: relative;
        padding-top: 7em;
        width: 34vw;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div {
        display: flex;
        flex-direction: column;
        gap: 5em;
        position: absolute;
        right: -5em;
        top: 9em;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div .square {
        width: 10em;
        height: 10em;
        transform: rotate(-45deg);
        border-radius: 1em;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div .square.primary {
        background: var(--primary);
        z-index: 1;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div .square.secondary {
        background: var(--secondary);
        z-index: 1;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div {
        position: absolute;
        top: 2em;
        right: 13em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img{
        width: 10em;
        height: 10em;
        transform: rotate(-45deg);
        border-radius: 1em;
        overflow: hidden;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    /* SQUARE IMGS POSITIONING */

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img1 {
        position: absolute;
        top: 14.5em;
        border: .1875em solid var(--primary);
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img .square-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    /* .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img1 .square-wrapper::before {
        content: '';
        display: block;
        background: linear-gradient(90deg, #27AAE1, #2E3092);
        height: 100%;
        position: absolute;
        inset: 0;
        border-radius: 1em;
        animation: travel-line 7s infinite alternate .5s;
        opacity: .9;
    } */

    @keyframes travel-line {
        0% {
            width: 0;
        }

        50% {
            width: 100%;
            transform: translateX(100%);
        }

        100% {
            width: 0;
            transform: translateX(0);
        }


    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square {
        width: 10em;
        height: 10em;
        transform: rotate(-45deg);
        border-radius: 1em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.primary {
        border: .1875em solid var(--primary);
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.secondary {
        border: .1875em solid var(--secondary);
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img2 {
        position: absolute;
        top: 29.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img3 {
        position: absolute;
        top: 44.5em;
        border: .1875em solid var(--primary);
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img4 {
        position: absolute;
        top: 59.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img5 {
        position: absolute;
        top: 74.5em;
        border: .1875em solid var(--primary);
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img6 {
        position: absolute;
        top: 89.5em;
    }


    /* SQUARE IMGS POSITIONING END */



    .singlesolution-container .solution-p {
        line-height: 1.5rem;
    }
    
}

@media(min-width: 650px) {
    .singlesolution-container .solution-p {
        font-size: .75rem;
        line-height: 1.5rem;
    }

    .singlesolution-container .solution-left .solution-left-value .values-ul .values-li {
        font-size: .75rem;
        line-height: 1.5rem; 
    }
}

@media(min-width: 700px) {
    .singlesolution-container .solution-left {
        padding: 11em 1.5em 1em 1.875em;
    }
}

@media(min-width: 800px) {

    .singlesolution-container .solution-left {
        padding: 12em 1.5em 1em 3.125em;
        width: 75vw;
    }
    
}

@media(min-width: 900px) {

    .singlesolution-container .solution-left {
        padding: 13em 1.5em 1em 3.125em;
    }

    .singlesolution-container .solution-left .section-title {
        font-size: 2rem;
    }

    .singlesolution-container .solution-p {
        font-size: 1rem;
        letter-spacing: .015rem;
        line-height: 2rem;
        margin-top: .7em;
    }

    .singlesolution-container .solution-left .solution-left-value .values-ul {
        margin-top: .7em;
    }

    .singlesolution-container .solution-left .solution-left-value .values-ul .values-li {
        font-size: 1rem;
        letter-spacing: .015rem;
        line-height: 2rem;
    }

    .singlesolution-container .solution-left .solution-left-value .reach-btn {
        font-weight: 900;
        font-size: 1.1rem;
        border: 4px solid var(--secondary);
        width: 50%;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div .square {
        width: 12em;
        height: 12em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img {
        width: 12em;
        height: 12em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square {
        width: 12em;
        height: 12em;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div {
        right: -6em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div {
        right: 16em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img1 {
        top: 15.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img2 {
        top: 32.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img3 {
        top: 49.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img4 {
        top: 66.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img5 {
        top: 83.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img6 {
        top: 100.5em;
    }
}


@media(min-width: 1000px) {
    .singlesolution-container .solution-left {
        padding: 13em 1.5em 1em 5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div {
        top: 11em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div {
        top: 4em;
    }

    .singlesolution-container .solution-left .solution-left-value {
        margin-top: 3.5em;
    }

}


@media(min-width: 1100px) {

    .singlesolution-container .solution-wrapper .solution-right .squares-div {
        top: 13em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div {
        top: 6em;
    }

    .singlesolution-container .solution-left {
        padding: 15em 1.5em 1em 5em;
    }

    .singlesolution-container .solution-left .section-title {
        font-size: 2rem;
    }

    .singlesolution-container .solution-p {
        font-size: 1rem;
    }

    .singlesolution-container .solution-left {
        width: 80vw;
    }

    .singlesolution-container .solution-left .solution-left-value .values-ul .values-li {
        font-size: 1rem;
    }

    .singlesolution-container .solution-left .solution-left-value .reach-btn {
        font-size: 1.3rem;
    }
}

@media(min-width: 1200px) {
    .singlesolution-container .solution-left {
        padding: 15em 1.5em 1em 6.25em;
        width: 96vw;
    }

    .singlesolution-container .solution-left .section-title {
        font-size: 2.2rem;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div {
        right: -7em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div {
        right: 16.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div .square {
        width: 13em;
        height: 13em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img {
        width: 13em;
        height: 13em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square {
        width: 13em;
        height: 13em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img1 {
        top: 16em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img2 {
        top: 34em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img3 {
        top: 52em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img4 {
        top: 70em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img5 {
        top: 88em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img6 {
        top: 106em;
    }
}

@media(min-width: 1300px) {

    .singlesolution-container .solution-wrapper .solution-right .squares-div {
        gap: 7em;
        right: -8em;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div .square {
        width: 15em;
        height: 15em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div {
        right: 18.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img {
        width: 15em;
        height: 15em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square {
        width: 15em;
        height: 15em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img1 {
        top: 18em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img2 {
        top: 40em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img3 {
        top: 62em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img4 {
        top: 84em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img5 {
        top: 106em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img6 {
        top: 128em;
    }
}

@media(min-width: 1350px) {
    .singlesolution-container .solution-left {
        padding: 17em 1.5em 1em 6.25em;
        width: 96vw;
    }
    
}

@media(min-width: 1400px) {
    .singlesolution-container .solution-left {
        padding: 17em 1.5em 1em 6.25em;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div {
        top: 15em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div {
        top: 8em;
    }

}

@media(min-width: 1451px) {
    .singlesolution-container .solution-left {
        padding: 16em 1.5em 1em 9.375em;
    }
}

@media(min-width: 1500px) {

    .singlesolution-container .solution-left {
        padding: 18em 1.5em 1em 9.375em;
    }

    .singlesolution-container .solution-left .section-title {
        font-size: 2.5rem
    }

    .singlesolution-container .solution-p {
        font-size: 1.3rem;
        line-height: 2.5rem;
    }

    .singlesolution-container .solution-left .solution-left-value .values-ul .values-li {
        font-size: 1.3rem;
        line-height: 2.5rem;
    }

    .singlesolution-container .solution-left .solution-left-value .reach-btn {
        font-size: 1.6rem;
        width: 60%;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div {
        gap: 8em;
        right: -9em;
    }

    .singlesolution-container .solution-wrapper .solution-right .squares-div .square {
        width: 17em;
        height: 17em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div {
        right: 21.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img {
        width: 17em;
        height: 17em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square {
        width: 17em;
        height: 17em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img1 {
        top: 19.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img2 {
        top: 44.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img3 {
        top: 69.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img4 {
        top: 94.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square-img.img5 {
        top: 119.5em;
    }

    .singlesolution-container .solution-wrapper .solution-right .imgs-div .square.img6 {
        top: 144.5em;
    }
}

@media(min-width: 1600px) {
    .singlesolution-container .solution-left {
        padding: 19em 1.5em 1em 9.375em;
    }
}

/* MEDIA QUERIES END */