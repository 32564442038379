@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}

.contact-container {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    color: var(--black);
}


/* Fade In Css */


.contact-container .appear-right {
    opacity: 0;
    transform: translateX(51px);
    transition: all 1s ease-in-out;
}

.contact-container .appear-right.active {
    opacity: 1;
    transform: translateX(0px);
}

.contact-container .appear-left {
    opacity: 0;
    transform: translateX(-107px);
    transition: all 1s ease-in-out;
}

.contact-container .appear-left.active {
    opacity: 1;
    transform: translateX(0px);
}


/* Fade in CSS End */






.contact-container .contact-wrapper {
    padding: 9em 1em 1em 1em;
}

.contact-container .contact-wrapper .single-card {
    background: rgba( 255, 253, 253, 0.5 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 10.5px );
    -webkit-backdrop-filter: blur( 7.5px );
    z-index: 1;
    position: relative;
    border-radius: 0.5em;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    margin-bottom: 6em;
    padding: 6em 1em 2em 1em;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}



.contact-container .contact-wrapper .contact-left .cards-wrapper {
    margin-top: 6em;
}

.contact-container .contact-wrapper .contact-left .contact-title {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 1.8rem;
    font-weight: 900;
    line-height: normal;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .staff-img {
    width: 9em;
    position: absolute;
    top: -4em;
    left: 50%;
    transform: translateX(-50%);
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .card-p {
    font-size: .75rem;
    text-align: center;
    letter-spacing: .01rem;
    margin: 0.4em 0 0.7em 0;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn {
    margin-top: unset;
    background: var(--primary);
    color: #ffffff;
    padding: 0.5em 1em;
    border-radius: 0.3em;
    box-shadow: -0.4em 0.4em 0 var(--secondary);
    gap: .8em;
    border: none;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn.Mui-disabled {
    background: #ddd;
    box-shadow: none;
} 

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn.Mui-disabled .MuiLoadingButton-endIconLoadingEnd {
    display: none;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn.Mui-disabled:hover {
    box-shadow: none;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn .MuiLoadingButton-loadingIndicator {
    position: relative;
    margin-left: 2em;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn span {
    font-size: .8rem;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn:hover {
    box-shadow: -0.6em 0.6em 0 var(--secondary);
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn img {
    width: 1.25em;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn .send-icon {
    font-size: 1.6em;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper {
    display: flex;
    width: 100%;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .form-field {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    outline: none;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .formsubmit {
    margin-top: unset;
    background: var(--primary);
    color: #ffffff;
    padding: 0.5em 1em;
    border-radius: 0.3em;
    box-shadow: -0.4em 0.4em 0 var(--secondary);
    gap: .8em;
    border: none;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    cursor: pointer;
    transition: all .5s ease-in-out;
    width: 100%;
    justify-content: center;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .5s ease-in-out;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div.div1 {
    transition: all .5s ease-in-out;
    width: 100%;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div.div1.deactivate {
    opacity: 0;
    width: 0;
    z-index: -1;
    height: 0;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 {
    width: 0;
    opacity: 0;
    z-index: -1;
    height: 0;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2.activate {
    width: 100%;
    opacity: 1;
    z-index: 1;
    height: 100%;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 form {
    display: flex;
    flex-direction: column;
    gap: .7em;
    width: 100%;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 .form-title {
    margin-bottom: 1em;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 form .form-input {
    padding: 1em;
    font-family: 'Poppins', sans-serif;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 form .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-family: 'Poppins', sans-serif;
    font-size: .9rem;
}

.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 p.disclaimer {
    margin-top: 1.5rem;
    font-size: .65rem;
    color: #757575;
    text-align: center;
    letter-spacing: .01rem;
}


.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card2 button img {
    width: 1.6em;
}

.contact-container .contact-wrapper .contact-right img {
    display: none;
}

.contact-container .copyright-p {
    font-size: .75rem;
    margin: 1em;
}

.contact-container .additionals {
    padding: 1.5em 1em;
    background: var(--primary);
    color: #ffffff;
    position: relative;
}

.contact-container .additionals p{
    font-size: .75rem;
    letter-spacing: .01rem;
    line-height: 1.1rem;
    color: #ffffff;
}  

.contact-container .additionals .location {
    display: flex;
    gap: .5em;
    align-items: center;
}

.contact-container .additionals .location svg {
    font-size: 1.5rem;
    color: #ffffff;
}

.contact-container .additionals .phone {
    display: flex;
    gap: .5em;
    align-items: center;
    margin-top: 1em;
}

.contact-container .additionals .phone svg {
    font-size: 1.5rem;
}

.contact-container .additionals .socials {
    display: flex;
    gap: 1em;
    align-items: center;
    margin-top: 1em;
}

.contact-container .additionals .socials a {
    text-decoration: none;
    color: var(--black);
}

.contact-container .additionals .socials svg {
    transition: all .3s ease-in-out;
    color: #ffffff;
}

.contact-container .additionals .socials a:hover svg {
    transform: scale(.95);
}

.contact-container .additionals .placeholder {
    width: 4em;
    position: absolute;
    top: 49%;
    right: 1em;
}

/* MEDIA QUERIES */

@media(min-width: 400px) {
    .contact-container .contact-wrapper {
        padding: 8em 1.5em 1em 1.5em;
    }

    .contact-container .copyright-p {
        margin: 1em 1.5em;
    }

    .contact-container .additionals {
        padding: 2em 1.5em;
    }

    .contact-container .additionals .placeholder {
        width: 5em;
        top: 36%;
        right: 3em;
    }
}

@media(min-width: 450px) {
    .contact-container .contact-wrapper {
        padding: 9em 1.5em 1em 1.5em;
    }
}

@media(min-width: 500px) {

    .contact-container .contact-wrapper .contact-right .img-container{
        position: absolute;
        top: 12em;
        right: 0;
        width: 50vw;
        height: 100%;
        overflow: hidden;
        border-radius: 1em 0 0 1em;
    }

    .contact-container .contact-wrapper .contact-right img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 p.disclaimer {
        font-size: .75rem;
    }

    .contact-container .additionals .socials {
        gap: 2em;
    }

}

@media(min-width: 600px) {

    .contact-container .contact-wrapper {
        padding: 11em 1.875em 1em 1.875em;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper {
        display: flex;
        gap: 1em;
        margin-top: 8em;
    }

    .contact-container .contact-wrapper .single-card {
        width: 50%;
        height: fit-content;
        margin-bottom: unset;
    }

    .contact-container .copyright-p {
        margin: 5em 1.875em 1em 1.875em;
    }

    .contact-container .contact-wrapper .contact-left .contact-title {
        font-size: 2rem;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 p.disclaimer {
        font-size: .7rem;
    }

    .contact-container .additionals {
        margin-top: 6em;
        padding: 2em 1.875em;
    }

    .contact-container .additionals .placeholder {
        width: 8em;
    }

    .contact-container .additionals {
        border-radius: 4em;
        margin-left: 1em;
        margin-right: 1em;
    }

}

@media(min-width: 700px) {
    .contact-container .contact-wrapper .contact-left .contact-title {
        font-size: 2.4rem;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .staff-img {
        width: 10em;
        top: -5em;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn {
        gap: 1em;
    }
}

@media(min-width: 800px) {
    .contact-container .contact-wrapper {
        padding: 11em 3.125em 1em 3.125em;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper {
        gap: 2em;
    }

    .contact-container .contact-wrapper .single-card {
        padding: 6em 1.5em 2em 1.5em;
    }

    .contact-container .contact-wrapper .contact-right .img-container {
        width: 45vw;
    }

    .contact-container .additionals {
        padding: 2em 3.125em;
        border-radius: 5em;
    }

    .contact-container .copyright-p {
        margin: 5em 3.125em 1em 3.125em;
    }

    .contact-container .additionals .placeholder {
        width: 10em;
        top: -12%;
        right: 7em;
    }


}

@media(min-width: 900px) {
    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .card-p {
        font-size: 1rem;
        letter-spacing: .015rem;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn span {
        font-size: 1rem;
    }

    .contact-container .copyright-p {
        font-size: .8rem;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn img {
        width: 1.5em;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card2 button img {
        width: 1.7em;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 p.disclaimer {
        font-size: .75rem;
    }

    .contact-container .additionals p {
        font-size: 1rem;
    }

    .contact-container .additionals .location svg {
        font-size: 2rem;
    }

    .contact-container .additionals .phone {
        margin-top: 1.5em;
    }

    .contact-container .additionals .phone svg {
        font-size: 2rem;
    }

    .contact-container .additionals .socials {
        margin-top: 1.5em;
    }

    .contact-container .additionals .socials svg {
        font-size: 2rem;
    }

    .contact-container .additionals .placeholder {
        top: 4%;
    }
}

@media(min-width: 1000px) {
    
    .contact-container .contact-wrapper {
        padding: 11em 5em 1em 5em;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper {
        margin-top: 10em;
    }

    .contact-container .additionals {
        padding: 2.5em 5em;
    }

    .contact-container .copyright-p {
        margin: 5em 5em 1em 5em;
    }

    .contact-container .additionals {
        border-radius: 6em;
        margin-left: 1em;
        margin-right: 1em;
    }
}

@media(min-width: 1050px) {
    .contact-container .contact-wrapper .single-card {
        max-width: 22em;
    }
}

@media(min-width: 1100px) {

    .contact-container .contact-wrapper .contact-left .cards-wrapper {
        margin-top: 8em;
    }


    .contact-container .additionals .location {
        gap: 1em;
    }

    .contact-container .additionals .phone {
        gap: 1em;
    }

    .contact-container .additionals .placeholder {
        width: 13em;
    }
}

@media(min-width: 1150px) {
    .contact-container .contact-wrapper .contact-left .cards-wrapper {
        gap: 3em;
    }
}

@media(min-width: 1200px) {

    .contact-container .contact-wrapper {
        padding: 13em 6.25em 1em 6.25em;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper {
        margin-top: 11em;
    }

    .contact-container .copyright-p {
        margin: 5em 6.25em 1em 6.25em;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper {
        gap: 4em;
    }

    .contact-container .contact-wrapper .single-card {
        max-width: 25em;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .staff-img {
        width: 14em;
        top: -9em;
    }

    .contact-container .contact-wrapper .contact-left .contact-title {
        font-size: 2.8rem;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 .form-title {
        font-size: 1.6rem;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 p.disclaimer {
        font-size: .8rem;
    }

    .contact-container .additionals {
        padding: 3em 6.25em;
        margin-top: 8em;
    }

    .contact-container .additionals p {
        font-size: 1.2rem;
    }

    .contact-container .copyright-p {
        font-size: .9rem;
    }
}


@media(min-width: 1300px) {

    .contact-container .contact-wrapper {
        padding: 15em 6.25em 1em 6.25em;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .staff-img {
        width: 15em;
        top: -10em;
    }

    .contact-container .additionals {
        margin-top: 12em;
    }

    .contact-container .additionals .location svg {
        font-size: 3rem;
    }

    .contact-container .additionals .phone {
        margin-top: 2em;
    }

    .contact-container .additionals .phone svg {
        font-size: 3rem;
    }

    .contact-container .additionals .socials {
        margin-top: 2em;
        gap: 3em;
    }

    .contact-container .additionals .socials svg {
        font-size: 3rem;
    }

    .contact-container .additionals .placeholder {
        width: 18em;
    }
}

@media(min-width: 1400px) {
    .contact-container .contact-wrapper .single-card {
        max-width: 29em;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .card-p {
        font-size: 1.2rem;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .contact-btn span {
        font-size: 1.2rem;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 .form-title {
        font-size: 1.8rem;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 form .form-input {
        font-size: 1.2rem;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 form .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 1.2rem;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .div-wrapper .div2 p.disclaimer {
        font-size: 1rem;
    }

    .contact-container .additionals p {
        font-size: 1.4rem;
    }

}


@media(min-width: 1451px) {
    .contact-container .contact-wrapper {
        padding: 16em 9.375em 1em 9.35em;
    }

    .contact-container .contact-wrapper .contact-right .img-container {
        top: 14em;
    }

    .contact-container .copyright-p {
        margin: 5em 9.375em 1em 9.375em;
    }

    .contact-container .contact-wrapper .contact-left .cards-wrapper {
        margin-top: 15em;
    }

    .contact-container .additionals {
        padding: 3em 9.375em;
    }

}

@media(min-width: 1500px) {
    .contact-container .contact-wrapper .contact-left .cards-wrapper .single-card .staff-img {
        width: 17em;
        top: -12em;
    }
}

@media(min-width:1600px) {
    .contact-container .contact-wrapper .single-card {
        max-width: 32em;
    }

    .contact-container .additionals {
        margin-top: 16em;
    }

    .contact-container .additionals .placeholder {
        width: 23em;
        right: 12em;
    }
}

@media(min-width: 1650px) {
    .contact-container .contact-wrapper .contact-right .img-container {
        top: 15em;
    }
}

@media(min-width: 1750px) {
    .contact-container .contact-wrapper {
        padding: 18em 9.375em 1em 9.35em;
    }
}
/* MEDIA QUERIES END */






















