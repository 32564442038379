@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



:root {
    --primary: #27AAE1; /* Pincton blue  */
    --secondary: #2e3092; /* Jacksons purple */
    --black: #011B25;
}

.solutions-container {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    color: var(--black);
}


/* Fade In Css */

.solutions-container .appear-up {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-in-out;
}

.solutions-container .appear-up.active {
    opacity: 1;
    transform: translateX(0px);
}

.solutions-container .appear-down {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 1s ease-in-out;
}

.solutions-container .appear-down.active {
    opacity: 1;
    transform: translate(0px);
}


.solutions-container .appear-right {
    opacity: 0;
    transform: translateX(51px);
    transition: all 1s ease-in-out;
}

.solutions-container .appear-right.active {
    opacity: 1;
    transform: translateX(0px);
}

.solutions-container .appear-left {
    opacity: 0;
    transform: translateX(-107px);
    transition: all 1s ease-in-out;
}

.solutions-container .appear-left.active {
    opacity: 1;
    transform: translateX(0px);
}

.solutions-container .appear-left-hero {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.solutions-container .appear-left-hero.active {
    opacity: 1;
    transform: translateX(0px);
}




/* Fade In Css End */




.solutions-container .hero-section {
    padding: 8em 1em 3em 1em;
    display: flex;
    position: relative;
}

.solutions-container .hero-section .planets {
    display: none;
    user-select: none;
}

.solutions-container .hero-section .satelite {
    display: none;
    user-select: none;
}

.solutions-container .hero-section .hero-right .img-container {
    display: none;
}

.solutions-container .hero-section .hero-left {
    z-index: 1;
}

.solutions-container .hero-section .hero-left .hero-title {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.6rem;
    position: relative;
}

.solutions-container .hero-section .hero-left .hero-title span {
    color: var(--secondary);
}

.solutions-container .hero-section .hero-left .hero-p {
    margin: 1rem 0 1rem 0;
    font-size: .9rem;
    width: 70%;
    letter-spacing: .01rem;
    color: #4b4b4b;
}

.solutions-container .hero-section .hero-left .checkout {
    display: flex;
    align-items: center;  
    gap: .4em; 
    cursor: pointer;
    transition: transform .5s ease-in-out;
    transform-origin: center;
}

.solutions-container .hero-section .hero-left .checkout:hover {
    transform: scale(.98);
}


.solutions-container .hero-section .hero-left .checkout span {
    font-size: .95rem;
    font-weight: 700;
}

.solutions-container .hero-section .hero-left .checkout img {
    width: 1.57em;
}


.solutions-container .hero-section .hero-left .hero-title .scribble {
    position: absolute;
    width: 3.7em;
    top: 2em;
    right: 0.5em;
}

.solutions-container .hero-section .hero-right .hero-small {
    width: 8em;
    position: absolute;
    top: 12em;
    right: -3em;
}

.solutions-container hr {
    border: 0.3px solid #ACACAC;
}

.solutions-container .solutions-section {
    position: relative;
    padding: 1.5em 1em 1em 1em;
}

.solutions-container .solutions-section .baloon {
    position: absolute;
    width: 3em;
    top: -1.5em;
    user-select: none;
}

.solutions-container .solutions-section .abstract {
    display: none;
}

.solutions-container .solutions-section .solutions-wrapper {
    margin-top: 2em;
}


/* PAGINATION STUFF */

.solutions-container .paginate-ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    list-style-type: none;
}

.solutions-container .paginate-ul li {
    transition: all 0.3s ease-in-out;
    position: relative;
}

.solutions-container .paginate-ul li::after {
    content: '';
    position: absolute;
    bottom: -0.2em;
    left: 50%;
    transform: translateX(-50%);
    height: 0.2em;
    background: #565656;
    transition: all 0.8s ease-in-out;
    border-radius: 1em;
}

.solutions-container .paginate-ul li.selected::after {
    width: 100%;
}

.solutions-container .paginate-ul li a {
    cursor: pointer;
}

.solutions-container .paginate-ul .previous,
.solutions-container .paginate-ul .next {
    border: 1px solid #a9a9a9;
    width: 1.5em;
    height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.solutions-container .paginate-ul .previous a,
.solutions-container .paginate-ul .next a{
    transition: transform 0.3s ease-in-out;
}

.solutions-container .paginate-ul .previous:hover a {
    transform: translateX(-0.1em);
}

.solutions-container .paginate-ul .next:hover a {
    transform: translateX(0.1em);
}

/* PAGINATION STUFF END */



/* MEDIA QUERIES */

@media(min-width: 350px) {
    .solutions-container .hero-section .hero-left .hero-title .scribble {
        right: unset;
        left: 0;
    }

    .solutions-container .hero-section .hero-left .hero-p {
        width: 72%;
        margin: 1.5rem 0 1rem 0;
    }
}


@media(min-width: 400px) {
    .solutions-container .hero-section {
        padding: 9.5em 1.5em 4em 1.5em;
    }

    .solutions-container .solutions-section {
        padding: 1.5em 1.5em 1em 1.5em;
    }

    .solutions-container .hero-section .hero-left .hero-title {
        font-size: 1.7rem;
    }

    .solutions-container .hero-section .hero-left .hero-title .scribble {
        left: 1em;
    }

    .solutions-container .hero-section .hero-right .hero-small {
        width: 9em;
        right: -1em;
    }

    .solutions-container .solutions-section .baloon {
        width: 4em;
        top: -2.5em;
    }
}

@media(min-width: 450px) {
    .solutions-container .hero-section .hero-left .hero-title .scribble {
        left: 0;
    }
}


@media(min-width: 500px) {

    .solutions-container .appear-right {
        transform: translateX(80px);
    }

    .solutions-container .hero-section {
        padding: 9.5em 1.5em 5em 1.5em;
    }
    
    .solutions-container .hero-section .hero-left .hero-title {
        width: 80%;
        line-height: 1.8rem;
    }

    .solutions-container .hero-section .hero-left .hero-title .scribble {
        top: 2.1em;
    }

    .solutions-container .hero-section .hero-right .hero-small {
        width: 10em;
    }
}

@media(min-width: 600px) {
    .solutions-container .hero-section {
        padding: 10em 1.7em 5.3em 1.7em;
    }

    .solutions-container .hero-section .hero-left .hero-title {
        font-size: 2rem;
        line-height: 2rem;
    }

    .solutions-container .hero-section .hero-left .hero-p {
        margin: 2rem 0 1.5rem 0;
        font-size: 1rem;
    }

    .solutions-container .hero-section .hero-left .checkout {
        gap: .6em;
    }

    .solutions-container .hero-section .hero-left .checkout span {
        font-size: 1.2rem;
    }

    .solutions-container .hero-section .hero-left .checkout img {
        width: 1.9em;
    }

    .solutions-container .hero-section .hero-right .hero-small {
        width: 12em;
    }

    .solutions-container .solutions-section {
        padding: 1.5em 1.875em 1em 1.875em;
    }

    .solutions-container .solutions-section .baloon {
        width: 5em;
        top: -3.5em;
    }

    .solutions-container .solutions-section .solutions-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5em;
    }

    .solutions-container .paginate-ul {
        margin: 2em 0 1.5em 0;
    }
}

@media(min-width: 700px) {

    .solutions-container .hero-section {
        padding: 11em 1.7em 5.3em 1.7em;
    }

    .solutions-container .hero-section .hero-right .hero-small {
        display: none;
    }

    .solutions-container .hero-section .hero-right .img-container {
        display: block;
        position: absolute;
        right: 0;
        width: 50vw;
        height: 100%;
    }

    .solutions-container .appear-right {
        transform: translateX(100px);
    }

    .solutions-container .hero-section .hero-right .img-container img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .solutions-container .nav2-container .top-nav .soc-btn {
        background: transparent;
        color: var(--secondary);
        border: 2px solid var(--secondary);
    }

    .solutions-container .nav2-container .ribbon::after {
        display: none;
    }

    .solutions-container .hero-section .hero-left .hero-title {
        font-size: 2.8rem;
        line-height: 2.53rem;
        text-align: center;
        width: 100%;
    }

    .solutions-container .hero-section .hero-left .hero-title .scribble {
        left: 50%;
        transform: translateX(-50%);
        width: 4.5em;
        top: 1.9em;
    }

    .solutions-container .hero-section .hero-left .hero-p {
        margin: 3rem auto 1rem auto;
        font-size: 1rem;
        text-align: center;
    }

    .solutions-container .hero-section .hero-left .checkout {
        justify-content: center;
    }

    .solutions-container .solutions-section .baloon {
        width: 7em;
        top: -5.5em;
    }

    .solutions-container .solutions-section {
        padding: 2em 1.875em 1em 1.875em;
    }


    .solutions-container .solutions-section .abstract {
        display: block;
        position: absolute;
        width: 13em;
    }

    .solutions-container .solutions-section .abstract.abstract1 {
        top: 13.5em;
        right: 0;
    }

    .solutions-container .solutions-section .abstract.abstract2 {
        top: 31.5em;
        left: 0;
    }
}

@media(min-width: 800px) {
    .solutions-container .hero-section {
        padding: 11em 3.125em 5.3em 3.125em;
    }

    .solutions-container .solutions-section {
        padding: 3em 3.125em 1em 3.125em;
    }

    .solutions-container .solutions-section .abstract {
        width: 14em;
    }

    .solutions-container .solutions-section .abstract.abstract1 {
        top: 14em;
    }

    .solutions-container .solutions-section .abstract.abstract2 {
        top: 32em;
    }
}

@media(min-width: 850px) {


    .solutions-container .hero-section {
        padding: 12em 3.125em 5.3em 3.125em;
    }

    .solutions-container .paginate-ul .previous a, 
    .solutions-container .paginate-ul .next a {
        font-size: 1.5rem;
    }

    .solutions-container .paginate-ul .previous, 
    .solutions-container .paginate-ul .next {
        width: 2em;
        height: 2em;
    }
}

@media(min-width:900px) {
    .solutions-container .hero-section {
        padding: 12em 3.125em 6.3em 3.125em;
    }

}

@media(min-width: 950px) {
    .solutions-container .solutions-section .solutions-wrapper {
        gap: 2.5em;
    }
}

@media(min-width: 1000px) {
    .solutions-container .hero-section {
        padding: 13em 5em 7em 5em;
    }

    .solutions-container .solutions-section .baloon {
        width: 9em;
        top: -12em;
    }

    .solutions-container .solutions-section {
        padding: 2em 5em 1em 5em;
    }

    .solutions-container .solutions-section .abstract {
        width: 16em ;
    }

    .solutions-container .solutions-section .abstract.abstract1 {
        top: 13em;
    }

    .solutions-container .solutions-section .abstract.abstract2 {
        top: 31em;
    }
}

@media(min-width: 1050px) {

    .solutions-container .hero-section {
        padding: 14em 9em 7em 9em;
    }

    .solutions-container .hero-section .hero-left {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .solutions-container .hero-section .hero-left .hero-p {
        width: 60%;
        margin: 3.5rem auto 1.5rem auto;
    }

    .solutions-container .hero-section .hero-left .hero-title {
        font-size: 3rem;
    }

    .solutions-container .hero-section .hero-left .hero-title .scribble {
        top: 1.8em;
    }

    .solutions-container .hero-section .hero-left .checkout span {
        font-size: 1.4rem;
    }

    .solutions-container .hero-section .hero-left .checkout img {
        width: 2.2em;
    }

    .solutions-container .solutions-section .abstract.abstract1 {
        top: 12em;
    }

    .solutions-container .solutions-section .abstract.abstract2 {
        top: 30em;
    }
    
}


@media(min-width: 1100px) {

    .solutions-container .hero-section .hero-left .hero-title {
        font-weight: 900;
        line-height: 2.8rem;
    }

    .solutions-container .hero-section .hero-left .hero-title .scribble {
        top: 1.95em;
    }

    .solutions-container .hero-section .planets {
        display: block;
        position: absolute;
        width: 10em;
        top: 21em;
        left: 5em;
        animation: planets-anim 2s infinite alternate 0.5s;
    }

    .solutions-container .hero-section .satelite {
        display: block;
        position: absolute;
        right: 2em;
        top: 8em;
        z-index: 1;
        width: 6em;
        animation: satelite-anim 1.9s infinite alternate ease-in-out 0.9s;
    }

    .solutions-container .solutions-section .baloon {
        width: 9em;
        top: -8em;
        right: 8em;
    }


    @keyframes planets-anim {
        from{
            transform: translateY(0);
        }

        to {
            transform: translateY(-1em);
        }
    }

    @keyframes satelite-anim {
        from{
            transform: translateY(0);
        }

        to {
            transform: translateY(0.8em);
        }
    }
}


@media(min-width: 1150px) {
    .solutions-container .paginate-ul .previous, .solutions-container .paginate-ul .next {
        width: 3em;
        height: 3em;
    }

    .solutions-container .paginate-ul .previous a, .solutions-container .paginate-ul .next a {
        font-size: 1.9rem;
    }

    .solutions-container .paginate-ul li a {
        font-size: 1.5rem;
    }

}

@media(min-width: 1200px) {
    .solutions-container .hero-section {
        padding: 16em 14em 8em 14em;
    }

    .solutions-container .hero-section .hero-left .hero-title {
        font-size: 3.5rem;
        line-height: 3.3rem;
    }
    
    .solutions-container .hero-section .hero-left .hero-title .scribble {
        top: 1.9em;
    }

    .solutions-container .hero-section .hero-left .hero-p {
        width: 65%;
        font-size: 1.1rem;
        margin: 4rem auto 2rem auto;
    }

    .solutions-container .hero-section .hero-left .checkout span {
        font-size: 1.6rem;
    }

    .solutions-container .hero-section .hero-left .checkout img {
        width: 2.4em;
    }

    .solutions-container .hero-section .hero-right .img-container img {
        object-position: bottom;
    }

    .solutions-container .solutions-section {
        padding: 2em 6.25em 1em 6.25em;
    }

    .solutions-container .solutions-section .abstract.abstract1 {
        top: 13em;
    }

    .solutions-container .solutions-section .abstract.abstract2 {
        top: 32em;
    }
}

@media(min-width: 1400px) {
    .solutions-container .hero-section {
        padding: 18em 14em 8em 14em;
    }

    .solutions-container .hero-section .hero-left .hero-p {
        font-size: 1.2rem;
    }
}


@media(min-width: 1451px) {
    .solutions-container .hero-section {
        padding: 18em 18em 10em 18em;
    }

    .solutions-container .hero-section .hero-left .hero-title {
        font-size: 4rem;
        line-height: 3.8rem;
    }

    .solutions-container .hero-section .hero-left .hero-p {
        font-size: 1.3rem;
        margin: 5rem auto 3rem auto;
    }

    .solutions-container .hero-section .hero-left .checkout {
        gap: .9em;
    }

    .solutions-container .hero-section .hero-left .checkout span {
        font-size: 1.9rem;
    }

    .solutions-container .hero-section .hero-left .checkout img {
        width: 3em;
    }

    .solutions-container .hero-section .planets {
        width: 12em;
        top: 25em;
    }

    .solutions-container .hero-section .satelite {
        width: 8em;
        right: 4em;
    }

    .solutions-container .solutions-section .baloon {
        width: 11em;
        top: -12em;
        right: 11em;
    }
}

@media(min-width: 1600px) {
    .solutions-container .hero-section {
        padding: 19em 18em 10em 18em;
    }
}

/* MEDIA QUERIES END */