@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}

.work-container {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    color: var(--black);
}

/* Fade In Css */

.work-container .appear-up {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-in-out;
}

.work-container .appear-up.active {
    opacity: 1;
    transform: translateX(0px);
}

.work-container .appear-down {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 1s ease-in-out;
}

.work-container .appear-down.active {
    opacity: 1;
    transform: translateY(0px);
}


.work-container .appear-right {
    opacity: 0;
    transform: translateX(52px);
    transition: all 1s ease-in-out;
}

.work-container .appear-right.active {
    opacity: 1;
    transform: translateX(0px);
}

.work-container .appear-left {
    opacity: 0;
    transform: translateX(-107px);
    transition: all 1s ease-in-out;
}

.work-container .appear-left.active {
    opacity: 1;
    transform: translateX(0px);
}

.work-container .appear-left-hero {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.work-container .appear-left-hero.active {
    opacity: 1;
    transform: translateX(0px);
}


/* Fade In Css End */


.work-container .hero-section {
    padding: 9em 1em 4em 1em;
    background: linear-gradient(247deg, #FFF 50%, #28AAE1 325.45%, #27AAE1 325.47%);
    position: relative;
}

.work-container .hero-section .hero-right {
    display: none;
}

.work-container .hero-section .hero-left .placeholder {
    position: absolute;
    top: 71%;
    width: 6em;
    right: 0;
}

.work-container .hero-section .hero-left .hero-title {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    font-weight: 900;
    line-height: normal;
}

.work-container .hero-section .hero-left .hero-title .dot-span {
    color: var(--secondary);
}

.work-container .hero-section .hero-left .hero-p {
    font-family: 'Poppins', sans-serif;
    font-size: .75rem;
    color: #4b4b4b;
    letter-spacing: .01rem;
    line-height: 1.2rem;
    margin-top: .3rem;
}

.work-container .testimonials-section {
    margin-top: 4em;
}

.work-container .testimonials-section .testimonial-left .test-small-h1 {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    font-weight: 900;
    line-height: 1.3rem;
    margin: 0 1rem;
}

.work-container .testimonials-section .testimonial-left .swiper {
    margin-top: 1.5em;
}

.work-container .testimonials-section .testimonial-left .swiper .swiper-slide {
    height: 0;
}

.work-container .testimonials-section .testimonial-left .swiper .swiper-slide.swiper-slide-active {
    height: 100%;
}

.work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container {
    display: flex;
    gap: 1em;
}

.work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
    width: 5em;
    height: 100%;
    border-radius: 0 .5rem 0 0;
    overflow: hidden;
}

.work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right {
    margin-bottom: 2.5em;
    padding-right: 1em;
}

.work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .stars svg {
    color: #FFD700;
    font-size: 1.2rem;
}

.work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
}

.work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p {
    font-family: 'Poppins', sans-serif;
    font-size: .75rem;
    color: #4b4b4b;
    letter-spacing: .01rem;
    line-height: 1.2rem;
    margin-top: .3rem;
}

.work-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
    width: fit-content;
    left: 6em;
    bottom: -0.1em;
}

.work-container .testimonials-section .testimonial-right {
    display: none;
}

.work-container .opportunities-section {
    padding: 0 1em;
    margin-top: 3em;
    position: relative;
}

.work-container .opportunities-section::before {
    content: '';
    position: absolute;
    top: -1em;
    left: 0;
    right: 0;
    height: 65%;
    display: block;
    background: #f5f3f3;
}

.work-container .opportunities-section .opportunities-h1 {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    font-weight: 900;
    line-height: 1.3rem;
    position: relative;
    z-index: 2;
}

.work-container .opportunities-section .opportunities-wrapper {
    margin-top: 1.5em;
}

.work-container .opportunities-section .opportunities-wrapper .opportunity {
    background: transparent;
    border: 1px solid var(--black);
    border-radius: .3em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5em;
    justify-content: space-between;
    height: 100%;
}

.work-container .opportunities-section .opportunities-wrapper .opportunity .opp-title {
    font-family: 'Montserrat', sans-serif;
    color: var(--black);
    font-weight: 600;
    text-align: center;
}

.work-container .opportunities-section .opportunities-wrapper .opportunity .opp-p {
    font-family: 'Poppins', sans-serif;
    font-size: .75rem;
    color: #4b4b4b;
    letter-spacing: .01rem;
    line-height: 1.2rem;
    text-align: center;
}

.work-container .opportunities-section .opportunities-wrapper .opportunity .opp-btn {
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    border-radius: 0;
    border: .1rem solid var(--black);
    font-size: .7rem;
}

.work-container .opportunities-section .opportunities-wrapper .swiper {
    overflow: unset;
    overflow-x: clip;
}

.work-container .opportunities-section .opportunities-wrapper .swiper .swiper-slide {
    height: auto;
}

.work-container .opportunities-section .opportunities-wrapper .swiper .swiper-pagination {
    bottom: -2em;
}

.work-container .why-section {
    margin-top: 2em;
    padding: 1em;
}

.work-container .why-section .why-h1 {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    font-weight: 900;
    line-height: 1.3rem;
}

.work-container .why-section .why-wrapper {
    margin-top: 1.5em;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.work-container .why-section .why-wrapper .reason {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.work-container .why-section .why-wrapper .reason .reason-left img {
    width: 8em;
}

.work-container .why-section .why-wrapper .reason.reason2 .reason-left img {
    width: 9em;
}

.work-container .why-section .why-wrapper .reason .reason-right .reason-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: var(--black);
    text-align: center;

}

.work-container .why-section .why-wrapper .reason .reason-right .reason-p {
    font-family: 'Poppins', sans-serif;
    font-size: .75rem;
    color: #4b4b4b;
    letter-spacing: .01rem;
    line-height: 1.2rem;
    margin-top: .3rem;
    text-align: center;
}

.work-container .nav2-container .top-nav .soc-btn:hover span {
    color: var(--secondary);
}


@media(min-width: 400px) {
    .work-container .hero-section {
        padding: 9em 1.5em 4em 1.5em;
    }

    .work-container .hero-section .hero-left .placeholder {
        width: 8em;
        right: 1em;
        top: 64%;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right {
        padding-right: 1.5em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 7em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 8em;
    }

    .work-container .opportunities-section {
        padding: 0 1.5em;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity {
        padding: 1em 1.5em;
    }

    .work-container .why-section {
        padding: 1em 1.5em;
    }

}

@media(min-width: 450px) {
    .work-container .hero-section .hero-left .placeholder {
        top: 69%;
    }
}

@media(min-width: 500px) {

    .work-container .nav2-container .ribbon::after {
        display: none;
    }

    .work-container .nav2-container .top-nav .soc-btn {
        background: transparent;
        font-weight: 700;
    }

    .work-container .hero-section {
        display: flex;
        gap: 1em;
    }

    .work-container .hero-section .hero-left .placeholder {
        display: none;
    }

    .work-container .hero-section .hero-left {
        width: 55%;
    }

    .work-container .hero-section .hero-right {
        display: unset;
        width: 45%;
    }

    .work-container .hero-section .hero-right .img-container {
        display: flex;
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .work-container .hero-section .hero-right .img-container img {
        height: 100%;
        object-fit: cover;
    }

    .work-container .why-section {
        margin-top: 2.5em;
    }

    .work-container .why-section .why-wrapper .reason {
        flex-direction: row;
        gap: 1em;
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-title {
        text-align: left;
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-p {
        text-align: left;
        line-height: 1.4rem;
    }

    .work-container .why-section .why-wrapper .reason.reason2 .reason-left img {
        width: 8em;
    }

}

@media(min-width: 600px) {

    .work-container .hero-section {
        padding: 10em 1.875em 5em 1.875em;
    }

    .work-container .hero-section .hero-left .hero-title {
        font-size: 2.6rem;
        line-height: 2.5rem;
        width: 5em;
    }

    .work-container .hero-section .hero-left .hero-p {
        font-size: .8rem;
        font-weight: 400;
        width: 18rem;
        margin-top: .5rem;
    }

    .work-container .testimonials-section {
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 6em;
    }

    .work-container .testimonials-section::before {
        content: '';
        width: 35%;
        height: .25em;
        background: var(--black);
        position: absolute;
        right: 0;
        top: 0;
    }

    .work-container .testimonials-section::after {
        content: '';
        width: 100%;
        height: .25em;
        background: var(--black);
        position: absolute;
        right: 0;
        top: calc(100% - .25em);
    }

    .work-container .testimonials-section .testimonial-left {
        width: 65%;
    }

    .work-container .testimonials-section .testimonial-left .test-small-h1 {
        display: none;
    }


    .work-container .testimonials-section .testimonial-left .swiper {
        margin-top: unset;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 8em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .stars svg {
        font-size: 1.3rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
        font-size: 1.2rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 9em;
    }

    .work-container .testimonials-section .testimonial-right {
        display: unset;
    }

    .work-container .testimonials-section .testimonial-right .test-right-h1 {
        color: var(--black, #011B25);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Montserrat', sans-serif;
        font-size: 2.6rem;
        font-weight: 900;
        line-height: 2.5rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        bottom: .5em;
    }

    .work-container .opportunities-section {
        padding: 0 1.875em;
    }

    .work-container .opportunities-section::before {
        height: 10em;
        top: calc(100% - 7em);
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-btn {
        font-size: .75rem;
    }

    .work-container .why-section {
        padding: 1em 1.875em;
        margin-top: 3em;
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-title {
        font-size: 1.1rem;
    }

}

@media(min-width: 700px) {
    .work-container .hero-section .hero-left {
        width: 50%;
    }

    .work-container .hero-section .hero-right {
        width: 50%;
    }
}

@media(min-width: 750px) {
    .work-container .hero-section {
        padding: 11em 1.875em 5em 1.875em;
    }
}

@media(min-width: 800px) {
    .work-container .hero-section {
        padding: 12em 3.125em 6em 3.125em;
    }

    .work-container .hero-section .hero-left .hero-title {
        font-size: 2.9rem;
        line-height: 2.7rem;
    }

    .work-container .hero-section .hero-left .hero-p {
        font-size: .85rem;
        width: 19rem;
        line-height: 1.4rem;
        margin-top: .7rem;
    }

    .work-container .testimonials-section {
        margin-top: 7em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 10em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 11em;
        bottom: 1em;
    }

    .work-container .testimonials-section .testimonial-right .test-right-h1 {
        font-size: 2.9rem;
        line-height: 2.7rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
        font-size: 1.4rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p {
        font-size: .8rem;
        line-height: 1.35rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p.test-p2 {
        margin-bottom: 1.5rem;
    }

    .work-container .opportunities-section {
        padding: 0 3.125em;
        margin-top: 4em;
    }

    .work-container .opportunities-section .opportunities-h1 {
        font-size: 1.6rem;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-title {
        font-size: 1.3rem;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-p {
        font-size: .8rem;
        line-height: 1.35rem;
    }

    .work-container .opportunities-section .opportunities-wrapper {
        margin-top: 2em;
    }

    .work-container .why-section {
        padding: 1em 3.125em;
        margin-top: 3em;
    }

    .work-container .why-section .why-h1 {
        font-size: 1.6rem;
    }

    .work-container .why-section .why-wrapper .reason {
        gap: 2em;
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-title {
        font-size: 1.3rem;
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-p {
        font-size: .8rem;
        line-height: 1.4rem;
        margin-top: .5rem;
    }

    .work-container .why-section .why-wrapper .reason .reason-left img {
        width: 10em;
    }

    .work-container .why-section .why-wrapper .reason.reason2 .reason-left img {
        width: 10em;
    }
}

@media(min-width: 900px) {
    .work-container .hero-section .hero-left .hero-title {
        font-size: 3.5rem;
        line-height: 3.2rem;
    }

    .work-container .hero-section .hero-left .hero-p {
        font-size: .9rem;
        line-height: 1.6rem;
        margin-top: 1rem;
        width: 26rem;
    }

    .work-container .testimonials-section .testimonial-left {
        width: 60%;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 12em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 13em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .stars svg {
        font-size: 1.8rem;
    }

    .work-container .testimonials-section::before {
        width: 40%;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
        font-size: 1.6rem;
    }

    .work-container .testimonials-section .testimonial-right .test-right-h1 {
        font-size: 3.5rem;
        line-height: 3.2rem;
        padding: 2rem 2rem 2rem 0;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p {
        font-size: .9rem;
        line-height: 1.6rem;
        margin-top: .5rem;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-p {
        font-size: .9rem;
        line-height: 1.6rem;
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-title {
        font-size: 1.4rem;
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-p {
        font-size: .9rem;
        line-height: 1.6rem;
    }

}

@media(min-width: 1000px) {
    .work-container .hero-section {
        padding: 13em 5em 7em 5em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p.test-p2 {
        margin-bottom: 2rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 13em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 14em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right {
        padding-right: 3em;
    }

    .work-container .opportunities-section {
        padding: 0 5em;
    }

    .work-container .why-section {
        padding: 1em 5em;
    }
}

@media(min-width: 1100px) {

    .work-container .hero-section {
        padding: 15em 5em 7em 5em;
    }


    .work-container .hero-section .hero-left .hero-title {
        font-size: 5rem;
        line-height: 4.3rem;
    }

    .work-container .hero-section .hero-left .hero-p {
        font-size: 1.1rem;
        line-height: 1.9rem;
    }

    .work-container .testimonials-section {
        margin-top: 9em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container {
        gap: 2em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .stars svg {
        font-size: 2rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
        font-size: 2rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p {
        font-size: 1.1rem;
        line-height: 1.9rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p.test-p2 {
        margin-bottom: 3rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 15em;
        bottom: 1.2em;
    }

    .work-container .testimonials-section .testimonial-right .test-right-h1 {
        font-size: 4rem;
        line-height: 3.7rem;
    }

    .work-container .opportunities-section {
        margin-top: 5em;
    }

    .work-container .opportunities-section::before {
        height: 14em;
        top: calc(100% - 10em);
    }

    .work-container .opportunities-section .opportunities-h1 {
        font-size: 2.2rem;
    }

    .work-container .opportunities-section .opportunities-wrapper {
        margin-top: 3em;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-title {
        font-size: 1.5rem;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-p {
        font-size: 1.1rem;
        line-height: 1.9rem;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-btn {
        font-size: 1.1rem;
    }

    .work-container .why-section {
        margin-top: 5.5em;
    }

    .work-container .why-section .why-h1 {
        font-size: 2.2rem;
    }

    .work-container .why-section .why-wrapper .reason {
        gap: 3em;
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-title {
        font-size: 1.7rem;
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-p {
        font-size: 1.1rem;
        line-height: 1.9rem;
    }

    .work-container .why-section .why-wrapper .reason .reason-left img {
        width: 13em;
    }

    .work-container .why-section .why-wrapper .reason.reason2 .reason-left img {
        width: 13em;
    }
    
}

@media(min-width: 1200px) {
    .work-container .hero-section {
        padding: 16em 6.25em 8em 6.25em;
    }

    .work-container .hero-section .hero-left .hero-p {
        width: 28rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 14em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 16em;
    }

    .work-container .opportunities-section {
        padding: 0 6.25em;
    }

    .work-container .why-section {
        padding: 1em 6.25em;
        margin-top: 5em;
    }
}

@media(min-width: 1451px) {
    .work-container .hero-section {
        padding: 18em 9.375em 9em 9.375em;
    }

    .work-container .hero-section .hero-left .hero-title {
        font-size: 6rem;
        line-height: 5.3rem;
    }

    .work-container .hero-section .hero-left .hero-p {
        font-size: 1.3rem;
        line-height: 2.2rem;
        letter-spacing: .015rem;
        width: 35rem;
        margin-top: 1.2rem;
    }

    .work-container .testimonials-section {
        margin-top: 9em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
        font-size: 2.4rem;
    }

    .work-container .testimonials-section .testimonial-right .test-right-h1 {
        font-size: 5rem;
        line-height: 4.1rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p {
        font-size: 1.3rem;
        line-height: 2.2rem;
        letter-spacing: .015rem;
        margin-top: .5rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p.test-p2 {
        margin-bottom: 4rem;
    }
    

    .work-container .opportunities-section {
        padding: 0 9.375em;
        margin-top: 6em;
    }

    .work-container .opportunities-section .opportunities-h1 {
        font-size: 2.6rem;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity {
        padding: 2em 1.5em;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-title {
        font-size: 1.8rem;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-p {
        font-size: 1.3rem;
        line-height: 2.2rem;
        letter-spacing: .015rem;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-btn {
        font-size: 1.3rem;
    }

    .work-container .why-section {
        margin-top: 6em;
        padding: 1em 9.375em;
    }

    .work-container .why-section .why-h1 {
        font-size: 2.6rem;
    }

    .work-container .why-section .why-wrapper .reason {
        gap: 4em;
    }

    .work-container .why-section .why-wrapper .reason .reason-left img {
        width: 18em;   
    }

    .work-container .why-section .why-wrapper .reason.reason2 .reason-left img {
        width: 18em;   
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-title {
        font-size: 2.2rem;
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-p {
        font-size: 1.3rem;
        line-height: 2.2rem;
        letter-spacing: .015rem;
    }
}

@media(min-width: 1500px) {
    .work-container .testimonials-section {
        margin-top: 10em;
    }
}

@media(min-width: 1700px) {

    .work-container .hero-section {
        padding: 19em 9.375em 10em 9.375em;
    }

    .work-container .hero-section .hero-left .hero-title {
        font-size: 7rem;
        line-height: 6.3rem;
    }

    .work-container .hero-section .hero-left .hero-p {
        font-size: 1.5rem;
        line-height: 2.5rem;
        width: 40rem;
        margin-top: 1.5rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 16em;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .stars svg {
        font-size: 3rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
        font-size: 2.6rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }

    .work-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 18em;
    }

    .work-container .testimonials-section .testimonial-right .test-right-h1 {
        font-size: 6rem;
        line-height: 5rem;
    }

    .work-container .opportunities-section .opportunities-h1 {
        font-size: 3rem;
    }

    .work-container .opportunities-section .opportunities-wrapper {
        margin-top: 4em;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-title {
        font-size: 2rem;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-p {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }

    .work-container .opportunities-section .opportunities-wrapper .opportunity .opp-btn {
        font-size: 1.5rem;
    }

    .work-container .why-section .why-h1 {
        font-size: 3rem;
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-title {
        font-size: 2.5rem;
    }

    .work-container .why-section .why-wrapper .reason .reason-right .reason-p {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }
}