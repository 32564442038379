@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');



* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    --primary: #27AAE1;
    --secondary: #2E3092;
    --black: #011B25;
}

.itilfoundation-land-container {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    color: var(--black);
}

/* Fade In Css */

.itilfoundation-land-container .appear-up {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-in-out;
}

.itilfoundation-land-container .appear-up.active {
    opacity: 1;
    transform: translateX(0px);
}

.itilfoundation-land-container .appear-down {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 1s ease-in-out;
}

.itilfoundation-land-container .appear-down.active {
    opacity: 1;
    transform: translateY(0px);
}


.itilfoundation-land-container .appear-right {
    opacity: 0;
    transform: translateX(52px);
    transition: all 1s ease-in-out;
}

.itilfoundation-land-container .appear-right.active {
    opacity: 1;
    transform: translateX(0px);
}

.itilfoundation-land-container .appear-left {
    opacity: 0;
    transform: translateX(-107px);
    transition: all 1s ease-in-out;
}

.itilfoundation-land-container .appear-left.active {
    opacity: 1;
    transform: translateX(0px);
}

.itilfoundation-land-container .appear-left-hero {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 1s ease-in-out;
}

.itilfoundation-land-container .appear-left-hero.active {
    opacity: 1;
    transform: translateX(0px);
}


/* Fade In Css End */


/* Navigation Bar */

.itilfoundation-land-container .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1em 0 1em;
    background: transparent;
}

.itilfoundation-land-container .nav-container.active {
    padding: 0.7em 0.5em;
    background: #ffffff;
}

.itilfoundation-land-container .nav-container .logo {
    width: 4.9em;
}

.itilfoundation-land-container .nav-container .land-cta-btn {
    font-size: .6rem;
    border-radius: 1rem;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    border: 2px solid var(--primary);
}

/* Navigation Bar End */

/* CALENDLY Booking */

.itilfoundation-land-container .calendly-container {
    height: 0;
    transition: .6s ease-in-out;
}

.itilfoundation-land-container .calendly-container .calendly-inline-widget {
    min-width: unset !important;
    height: 0 !important;
    transition: .6s ease-in-out;
}

.itilfoundation-land-container .calendly-container.active {
    height: 100%;
    margin-top: 2em;
}

.itilfoundation-land-container .calendly-container.active .calendly-inline-widget {
    height: 55em !important;
    width: 100%;
}

.itilfoundation-land-container .calendly-container.active .calendly-inline-widget iframe {
    border-radius: .5em;
}


/* CALENDLY Booking End */

/* Hero Section */

.itilfoundation-land-container .hero-section {
    padding: 6em 1em 0em 1em;
}

.itilfoundation-land-container .hero-section .hero-left {
    display: flex;
    flex-direction: column;
    gap: 1em;
    position: relative;
}

.itilfoundation-land-container .hero-section .hero-left .hero-title {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 900;
    line-height: 1.5rem;
    position: relative;
}

.itilfoundation-land-container .hero-section .hero-left .hero-p {
    font-size: 0.75rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.01rem;
    line-height: 1.1rem;
    color: #4b4b4b;
    width: 80%;
}

.itilfoundation-land-container .cta-btn {
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    border: 2px solid var(--black);
    font-size: .75rem;
    font-weight: 700;
    letter-spacing: .03rem;
    width: 14em;
    padding: .2rem 1rem;
}

.itilfoundation-land-container .hero-section .hero-left .small-hero-img {
    position: absolute;
    top: 3em;
    right: -2em;
    width: 6em;
}

.itilfoundation-land-container .hero-section .hero-right {
    margin-top: 2em;
}

.itilfoundation-land-container .hero-section .hero-right .video-container {
    width: 100%;
    height: 8em;
    border-radius: 1em;
    overflow: hidden;
    position: relative;
}

.itilfoundation-land-container .hero-section .hero-right .video-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.itilfoundation-land-container .hero-section .hero-right .video-container .overlay {
    position: absolute;
    inset: 0;
    background: #0000009c;
    display: flex;
    justify-content: center;
    align-items: center;
}

.itilfoundation-land-container .hero-section .hero-right .video-container .overlay .play-btn {
    color: #ffffff;
    font-size: 4rem;
}

/* Hero Section End */

/* Agitate Section */

.itilfoundation-land-container .agitate-section {
    padding: 0 1em;
    margin-top: 3em;
}

.itilfoundation-land-container .agitate-section .agitate-left {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.itilfoundation-land-container .agitate-section .agitate-left .agitate-p {
    font-size: 0.75rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.01rem;
    line-height: 1.1rem;
    color: #4b4b4b;
    text-align: center;
}

.itilfoundation-land-container .agitate-section .agitate-left .agitate-arrow {
    width: 1.5em;
    margin-top: .5em;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.itilfoundation-land-container .agitate-section .agitate-left .agitate-arrow:hover {
    transform: scale(.95);
}

.itilfoundation-land-container .agitate-section .agitate-right {
    display: none;
}

/* Agitate Section End */

/* Solution Section */


.itilfoundation-land-container .solution-section {
    padding: 0 1em;
    margin-top: 3em;
}


.itilfoundation-land-container .solution-section .solution-left {
    display: none;
}

.itilfoundation-land-container .section-title {
    color: var(--black, #011B25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 900;
    line-height: 1.5rem;
}

.itilfoundation-land-container .solution-section .solution-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.itilfoundation-land-container .solution-section .solution-right .section-title {
    text-align: center;
}

.itilfoundation-land-container .solution-section .solution-right .solution-p {
    font-size: 0.75rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.01rem;
    line-height: 1.1rem;
    color: #4b4b4b;
    text-align: center;
}

.itilfoundation-land-container .dream-section {
    margin-top: 3em;
    padding: 0 1em;
}

.itilfoundation-land-container .dream-section .dream-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.itilfoundation-land-container .dream-section .dream-wrapper .dream {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.itilfoundation-land-container .dream-section .dream-wrapper .dream img {
    width: 8em;
}

.itilfoundation-land-container .dream-section .dream-wrapper .dream.dream2 img {
    width: 9em;
}

.itilfoundation-land-container .dream-section .dream-wrapper .dream .dream-h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: var(--black);
    text-align: center;

}

.itilfoundation-land-container .dream-section .dream-wrapper .dream .dream-p {
    font-size: 0.75rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.01rem;
    line-height: 1.1rem;
    color: #4b4b4b;
    margin-top: .3rem;
    text-align: center;
}

/* Solution Section End */


/* Testimonials Section */

.itilfoundation-land-container .testimonials-section {
    margin-top: 3em;
}

.itilfoundation-land-container .testimonials-section .testimonial-left .test-small-h1 {
    text-align: center;
    margin: 0 1em;
}

.itilfoundation-land-container .testimonials-section .testimonial-left .swiper {
    margin-top: 1.5em;
}

.itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide {
    height: 0;
}

.itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide.swiper-slide-active {
    height: 100%;
}

.itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container {
    display: flex;
    gap: 1em;
}

.itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
    width: 5em;
    height: 100%;
    border-radius: 0 .5rem 0 0;
    overflow: hidden;
}

.itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right {
    margin-bottom: 2.5em;
    padding-right: 1em;
}

.itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .stars svg {
    color: #FFD700;
    font-size: 1.2rem;
}

.itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
}

.itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p {
    font-family: 'Poppins', sans-serif;
    font-size: .75rem;
    color: #4b4b4b;
    letter-spacing: .01rem;
    line-height: 1.2rem;
    margin-top: .3rem;
}

.itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
    width: fit-content;
    left: 6em;
    bottom: -0.1em;
}

.itilfoundation-land-container .testimonials-section .testimonial-right {
    display: none;
}

/* Testimonials Section End */


/* Clients Section */

.itilfoundation-land-container .clients-section {
    margin-top: 3em;
}

.itilfoundation-land-container .clients-section .section-title {
    text-align: center;
    margin: 0 1em;
}

.itilfoundation-land-container .clients-section .logos {
    white-space: nowrap;
    display: flex;
    position: relative;
}

.itilfoundation-land-container .clients-section .logos .logos-slide {
    display: flex;
    align-items: center;
    animation: 30s scroll-anim infinite linear;
}

@keyframes scroll-anim {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.itilfoundation-land-container .clients-section .logos .logos-slide img {
    width: 4em;
    margin: 0 0.5em;
}

.itilfoundation-land-container .clients-section .logos .logos-slide img.kra {
    width: 5.8em;
}

.itilfoundation-land-container .clients-section .logos .logos-slide img.ksc {
    width: 3em;
}

.itilfoundation-land-container .clients-section .logos .logos-slide img.kerra {
    width: 3.2em;
}

.itilfoundation-land-container .clients-section .logos .logos-slide img.kengen {
    width: 5em;
}



/* Clients Section End */

/* Offer Section */

.itilfoundation-land-container .offer-section {
    padding: 2em 1em;
    margin: 3em 1em 0em 1em;
    background: linear-gradient(90deg, #2E3092 0.29%, #27AAE1 99.72%);
    border-radius: 1em;
}

.itilfoundation-land-container .offer-section .offer-left {
    display: none;
}

.itilfoundation-land-container .offer-section .offer-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.itilfoundation-land-container .offer-section .offer-right .offer-h2 {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-size: 1.1rem;
    color: #ffffff;
    text-shadow: -2px 4px 4px rgba(1, 27, 37, 0.62);
    font-weight: 600;
}

.itilfoundation-land-container .offer-section .offer-right .discount-h1 {
    text-shadow: -2px 4px 4px rgba(1, 27, 37, 0.62);
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    background: linear-gradient(180deg, hsl(39deg 60.92% 41.2%) 0%, #ffe700 0%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5rem;
    font-weight: 900;
}

.itilfoundation-land-container .offer-section .offer-right .offer-right-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .2em;
}

.itilfoundation-land-container .offer-section .offer-right .offer-right-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.itilfoundation-land-container .offer-section .offer-right .offer-right-bottom .notices {
    margin-top: .5em;
}

.itilfoundation-land-container .offer-section .offer-right .offer-right-bottom .notices .notice-p {
    font-size: .65rem;
    letter-spacing: .01rem;
    color: #ffffff;
}

/* Offer Section End */

/* CTA Section */

.itilfoundation-land-container .cta-section {
    padding: 0 1em;
    margin-top: 3em;
}

.itilfoundation-land-container .cta-section .cta-right {
    display: none;
}

.itilfoundation-land-container .cta-section .cta-left .section-title {
    text-align: center;
}

.itilfoundation-land-container .cta-section .cta-left .cta-p {
    font-family: 'Poppins', sans-serif;
    font-size: .75rem;
    color: #4b4b4b;
    letter-spacing: .01rem;
    line-height: 1.2rem;
    text-align: center;
    margin: 1em 0;
}

.itilfoundation-land-container .cta-section .cta-left .notices {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.itilfoundation-land-container .cta-section .cta-left .notices .notice-p {
    font-size: .6rem;
    letter-spacing: .01rem;
    color: var(--black);
}

.itilfoundation-land-container .cta-section .cta-left form {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: .5em;
}

.itilfoundation-land-container .cta-section .cta-left form .text-field {
    width: 100%;
}

.itilfoundation-land-container .cta-section .cta-left form .MuiInputLabel-root {
    font-size: .8rem;
    font-family: 'Poppins', sans-serif;
}

.itilfoundation-land-container .cta-section .cta-left form .MuiInputBase-input {
    font-family: 'Poppins', sans-serif;
    font-size: .8rem;
}

.itilfoundation-land-container .cta-section .cta-left form .cta-btn {
    width: 100%;
    padding: .4rem 1rem;
}


/* CTA Section End */

/* Warning Section */

.itilfoundation-land-container .warning {
    padding: 2em 1em;
}

.itilfoundation-land-container .warning .warning-p {
    font-family: 'Poppins', sans-serif;
    font-size: .75rem;
    color: #4b4b4b;
    letter-spacing: .01rem;
    line-height: 1.2rem;
    text-align: center;
}

/* Warning Section End */

/* Logo Section */

.itilfoundation-land-container .logo-footer {
    padding: 1em;
    display: flex;
    justify-content: center;
}

.itilfoundation-land-container .logo-footer img {
    width: 8em;
}

/* Logo Section End */


/* Testimonials Section Media Queries */

@media(min-width: 400px) {
    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right {
        padding-right: 1.5em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 7em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 8em;
    }
}


@media(min-width: 600px) {
    .itilfoundation-land-container .testimonials-section {
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 4em;
    }

    .itilfoundation-land-container .testimonials-section::before {
        content: '';
        width: 35%;
        height: .25em;
        background: var(--black);
        position: absolute;
        right: 0;
        top: 0;
    }

    .itilfoundation-land-container .testimonials-section::after {
        content: '';
        width: 100%;
        height: .25em;
        background: var(--black);
        position: absolute;
        right: 0;
        top: calc(100% - .25em);
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left {
        width: 65%;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .test-small-h1 {
        display: none;
    }


    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper {
        margin-top: unset;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 8em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .stars svg {
        font-size: 1.3rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
        font-size: 1.2rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 9em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-right {
        display: unset;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-right .test-right-h1 {
        color: var(--black, #011B25);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Montserrat', sans-serif;
        font-size: 2.3rem;
        font-weight: 900;
        line-height: 2.5rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        bottom: .5em;
    }
}

@media(min-width: 800px) {

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 10em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 11em;
        bottom: 1em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-right .test-right-h1 {
        font-size: 2.9rem;
        line-height: 2.7rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
        font-size: 1.4rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p {
        font-size: .8rem;
        line-height: 1.35rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p.test-p2 {
        margin-bottom: 1.5rem;
    }
}

@media(min-width: 900px) {
    .itilfoundation-land-container .testimonials-section .testimonial-left {
        width: 60%;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 12em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 13em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .stars svg {
        font-size: 1.8rem;
    }

    .itilfoundation-land-container .testimonials-section::before {
        width: 40%;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
        font-size: 1.6rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-right .test-right-h1 {
        font-size: 3.5rem;
        line-height: 3.2rem;
        padding: 2rem 2rem 2rem 0;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p {
        font-size: .9rem;
        line-height: 1.6rem;
        margin-top: .5rem;
    }

}

@media(min-width: 1000px) {
    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p.test-p2 {
        margin-bottom: 2rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 13em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 14em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right {
        padding-right: 3em;
    }
}

@media(min-width: 1100px) {

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container {
        gap: 2em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .stars svg {
        font-size: 2rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
        font-size: 2rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p {
        font-size: 1.1rem;
        line-height: 1.9rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p.test-p2 {
        margin-bottom: 3rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 15em;
        bottom: 1.2em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-right .test-right-h1 {
        font-size: 4rem;
        line-height: 3.7rem;
    }

}

@media(min-width: 1200px) {
    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 14em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 16em;
    }

}

@media(min-width: 1451px) {

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
        font-size: 2.4rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-right .test-right-h1 {
        font-size: 5rem;
        line-height: 4.1rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p {
        font-size: 1.3rem;
        line-height: 2.2rem;
        letter-spacing: .015rem;
        margin-top: .5rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p.test-p2 {
        margin-bottom: 4rem;
    }
}

@media(min-width: 1500px) {
    .itilfoundation-land-container .testimonials-section {
        margin-top: 10em;
    }
}

@media(min-width: 1700px) {
    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-left .img-container {
        width: 16em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .stars svg {
        font-size: 3rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-name {
        font-size: 2.6rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-slide .test-container .test-right .test-p {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .swiper .swiper-pagination {
        left: 18em;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-right .test-right-h1 {
        font-size: 6rem;
        line-height: 5rem;
    }
}

/* Testimonials Section Media Queries End */

/* Nav Logo Media queries */

@media(min-width: 400px) {
    .itilfoundation-land-container .nav-container .logo {
        width: 5.5em;
    }
}


@media(min-width: 450px) {

    .itilfoundation-land-container .nav-container .logo {
        width: 6.5em;
    }
}

@media(min-width: 800px) {
    .itilfoundation-land-container .nav-container .logo {
        width: 8.5em;
    }
}

@media(min-width: 1100px) {
    .itilfoundation-land-container .nav-container .logo {
        width: 9.5em;
    }
}

@media(min-width: 1200px) {
    .itilfoundation-land-container .nav-container .logo {
        width: 10em;
    }
}

@media(min-width: 1400px) {
    .itilfoundation-land-container .nav-container .logo {
        width: 12em;
    }
}

@media(min-width: 1451px) {
    .itilfoundation-land-container .nav-container .logo {
        width: 9em;
    }
}

@media(min-width: 1500px) {
    .itilfoundation-land-container .nav-container .logo {
        width: 12em;
    }
}

@media(min-width: 1728px) {
    .itilfoundation-land-container .nav-container .logo {
        width: 12em;
    }
}

/* Nav Logo Media queries End */


/* Client Logos Media queries */

@media(min-width: 400px) {
    .itilfoundation-land-container .clients-section .logos .logos-slide img.ksc {
        width: 3.4em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kerra {
        width: 3.5em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kemri {
        width: 2.5em;
    }
}

@media(min-width: 500px) {
    .itilfoundation-land-container .clients-section .logos .logos-slide img.kengen {
        width: 6em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kemri {
        width: 3em;
    }
}

@media(min-width: 550px) {

    .itilfoundation-land-container .clients-section .logos .logos-slide {
        gap: 1em;
        margin-left: 1em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img {
        width: 6em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kra {
        width: 7.5em;
    }
}

@media(min-width: 600px) {
    .itilfoundation-land-container .clients-section .logos .logos-slide img.ksc {
        width: 3.5em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kerra {
        width: 4em;
    }
}

@media(min-width: 700px) {
    .itilfoundation-land-container .clients-section .logos .logos-slide img.kengen {
        width: 7em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.ksc {
        width: 4em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kemri {
        width: 3.3em;
    }
}

@media(min-width: 750px) {
    .itilfoundation-land-container .clients-section .logos .logos-slide {
        gap: 2em;
        margin-left: 2em;
    }
}

@media(min-width: 900px) {
    .itilfoundation-land-container .clients-section .logos .logos-slide {
        gap: 3em;
        margin-left: 3em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img {
        width: 7em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kra {
        width: 8.5em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kemri {
        width: 3.7em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.ksc {
        width: 4.5em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kengen {
        width: 8em;
    }
}

@media(min-width: 1000px) {
    .itilfoundation-land-container .clients-section .logos .logos-slide img.kerra {
        width: 4.3em;
    }
}

@media(min-width: 1100px) {
    .itilfoundation-land-container .clients-section .logos .logos-slide {
        gap: 4em;
        margin-left: 4em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img {
        width: 8em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kra {
        width: 9.5em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.ksc {
        width: 5.5em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kerra {
        width: 4.7em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kemri {
        width: 4.3em;
    }
}

@media(min-width: 1200px) {
    .itilfoundation-land-container .clients-section .logos .logos-slide img.ksc {
        width: 6.5em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kerra {
        width: 5.3em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kengen {
        width: 10em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kemri {
        width: 4.8em;
    }
}


@media(min-width: 1300px) {
    .itilfoundation-land-container .clients-section .logos .logos-slide {
        gap: 5em;
        margin-left: 5em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img {
        width: 9em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kerra {
        width: 5.6em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kemri {
        width: 5em;
    }
}

@media(min-width: 1400px) {
    .itilfoundation-land-container .clients-section .logos .logos-slide img.kerra {
        width: 6.3em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.ksc {
        width: 7.5em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kengen {
        width: 11em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kemri {
        width: 5.5em;
    }
}

@media(min-width: 1600px) {
    .itilfoundation-land-container .clients-section .logos .logos-slide img.kengen {
        width: 12em;
    }

    .itilfoundation-land-container .clients-section .logos .logos-slide img.kemri {
        width: 6.5em;
    }
}


/* Client Logos Media queries End */



/* MEDIA QUERIES */

@media(min-width: 350px) {
    .itilfoundation-land-container .hero-section .hero-left .small-hero-img {
        width: 8em;
    }

    .itilfoundation-land-container .hero-section .hero-right .video-container {
        height: 11em;
    }
}

@media(min-width: 400px) {

    .itilfoundation-land-container .nav-container {
        padding: 1em 1.5em 0 1.5em;
    }

    .itilfoundation-land-container .nav-container.active {
        padding: 1em 1.4em 1em 1.4em;
    }

    .itilfoundation-land-container .hero-section {
        padding: 7em 1.5em 0em 1.5em;
    }

    .itilfoundation-land-container .hero-section .hero-left .hero-p {
        line-height: 1.2rem;
    }

    .itilfoundation-land-container .agitate-section {
        padding: 0 1.5em;
    }

    .itilfoundation-land-container .agitate-section .agitate-left .agitate-p {
        line-height: 1.3rem;
    }

    .itilfoundation-land-container .solution-section {
        padding: 0 1.5em;
    }

    .itilfoundation-land-container .solution-section .solution-right .solution-p {
        line-height: 1.3rem;
    }

    .itilfoundation-land-container .dream-section {
        padding: 0 1.5em;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream .dream-p {
        line-height: 1.3rem;
    }

    .itilfoundation-land-container .testimonials-section .testimonial-left .test-small-h1 {
        margin: 0 1.5em;
    }

    .itilfoundation-land-container .clients-section .section-title {
        margin: 0 1.5em;
    }

    .itilfoundation-land-container .offer-section {
        margin: 3em 1.5em 0em 1.5em;
    }

    .itilfoundation-land-container .offer-section .offer-right .offer-right-bottom .notices {
        display: flex;
        gap: 2em;
    }

    .itilfoundation-land-container .cta-section {
        padding: 0 1.5em;
    }

    .itilfoundation-land-container .warning {
        padding: 2em 1.5em;
    }
}


@media(min-width: 450px) {
    .itilfoundation-land-container .nav-container .land-cta-btn {
        font-size: .7rem;
    }

    .itilfoundation-land-container .hero-section .hero-left .hero-title {
        font-size: 1.5rem;
    }

    .itilfoundation-land-container .hero-section .hero-left .hero-p {
        font-size: .8rem;
        width: 70%;
    }

    .itilfoundation-land-container .cta-btn {
        font-size: .8rem;
    }

    .itilfoundation-land-container .hero-section .hero-left .small-hero-img {
        width: 11em;
        top: 1em;
        right: -2em;
    }
}

@media(min-width: 500px) {
    .itilfoundation-land-container .cta-section .cta-left .notices {
        justify-content: center;
        gap: 4em;
    }
}

@media(min-width: 600px) {

    .itilfoundation-land-container .nav-container {
        padding: 1em 1.875em 0 1.875em;
    }

    .itilfoundation-land-container .nav-container.active {
        border-radius: 3em;
        padding: 1em 1.0em 1em 1.0em;
    }

    .itilfoundation-land-container .nav-container .land-cta-btn {
        font-weight: 700;
    }

    .itilfoundation-land-container .hero-section {
        display: flex;
        align-items: center;
        padding: 7em 1.875em 0em 1.875em;
    }

    .itilfoundation-land-container .hero-section .hero-left {
        width: 65%;
    }

    .itilfoundation-land-container .hero-section .hero-left .small-hero-img {
        display: none;
    }

    .itilfoundation-land-container .hero-section .hero-right {
        margin-top: unset;
    }

    .itilfoundation-land-container .agitate-section {
        display: flex;
        padding: 0 1.875em;
    }

    .itilfoundation-land-container .agitate-section .agitate-left {
        width: 60%;
        align-items: unset;
    }

    .itilfoundation-land-container .agitate-section .agitate-left .agitate-p {
        line-height: 1.4rem;
    }

    .itilfoundation-land-container .agitate-section .agitate-left .agitate-p {
        text-align: left;
    }

    .itilfoundation-land-container .agitate-section .agitate-left .agitate-arrow {
        width: 2em;
        margin-top: 1em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right {
        display: unset;
        position: relative;
        width: 40%;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img {
        position: absolute;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.small-bucket {
        width: 8em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.small-bucket.small-bucket1 {
        top: -3em;
        right: 5em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.small-bucket.small-bucket2 {
        top: -1em;
        right: -1em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.big-bucket {
        width: 11em;
        top: 0em;
        right: 3em;
    }

    .itilfoundation-land-container .solution-section {
        display: flex;
        padding: 0 1.875em 0 0;
        gap: 1.5em;
        margin-top: 4em;
    }

    .itilfoundation-land-container .solution-section .solution-left {
        display: unset;
    }

    .itilfoundation-land-container .solution-section .solution-left .img-container {
        width: 40vw;
        height: 100%;
        display: flex;
        overflow: hidden;
        border-radius: 0 0.5em 0.5em 0;
        position: relative;
    }

    .itilfoundation-land-container .solution-section .solution-left .img-container .overlay {
        position: absolute;
        inset: 0;
        background: #0000004d;
    }

    .itilfoundation-land-container .solution-section .solution-left .img-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .itilfoundation-land-container .solution-section .solution-right {
        align-items: unset;
    }

    .itilfoundation-land-container .solution-section .solution-right .section-title {
        text-align: left;
    }

    .itilfoundation-land-container .solution-section .solution-right .solution-p {
        text-align: left;
        line-height: 1.4rem;
    }

    .itilfoundation-land-container .dream-section {
        padding: 0 1.875em;
        margin-top: 4em;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper {
        flex-direction: row;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream.dream2 img {
        width: unset;
        height: 9em;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream img {
        width: unset;
        height: 9em;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream .dream-h3 {
        margin: 0.5rem 0;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream .dream-p {
        margin-top: unset;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream .dream-p {
        line-height: 1.4rem;
    }

    .itilfoundation-land-container .clients-section {
        margin-top: 4em;
    }

    .itilfoundation-land-container .offer-section {
        margin: 3em 1.875em 0em 1.875em;
        display: flex;
    }

    .itilfoundation-land-container .offer-section .offer-left {
        display: unset;
        position: relative;
        width: 45%;
    }

    .itilfoundation-land-container .offer-section .offer-left img {
        position: absolute;
        width: 25em;
        left: -7em;
        top: -8em;
    }

    .itilfoundation-land-container .offer-section .offer-right .offer-h2 {
        font-size: 1.1rem;
    }

    .itilfoundation-land-container .offer-section .offer-right .offer-right-top {
        gap: .3em;
    }

    .itilfoundation-land-container .offer-section .offer-right .discount-h1 {
        font-size: 1.8rem;
    }

    .itilfoundation-land-container .offer-section .offer-right .bonuses {
        display: flex;
        gap: 1.05em;
    }

    .itilfoundation-land-container .cta-btn {
        font-size: .9rem;
    }

    .itilfoundation-land-container .offer-section .offer-right .offer-right-bottom .notices {
        gap: 1em;
        margin-top: 1em;
    }

    .itilfoundation-land-container .cta-section {
        display: flex;
        padding: 0 0 0 1.875em;
        gap: 2em;
    }

    .itilfoundation-land-container .cta-section .cta-left .notices {
        justify-content: space-between;
    }

    .itilfoundation-land-container .cta-section .cta-left .section-title {
        text-align: left;
    }

    .itilfoundation-land-container .cta-section .cta-left .cta-p {
        text-align: left;
    }

    .itilfoundation-land-container .cta-section .cta-right {
        display: unset;
        width: 45%;
        border-radius: .5em 0 0 .5em;
        overflow: hidden;
        position: relative;
    }

    .itilfoundation-land-container .cta-section .cta-right .overlay {
        position: absolute;
        inset: 0;
        background: #0000004d;
    }

    .itilfoundation-land-container .cta-section .cta-right img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .itilfoundation-land-container .warning {
        padding: 2em 1.875em;
    }

    .itilfoundation-land-container .logo-footer img {
        width: 9em;
    }

}

@media(min-width: 700px) {

    .itilfoundation-land-container .nav-container.active {
        padding: 1.2em 0.8em 1.2em 0.8em;
    }

    .itilfoundation-land-container .hero-section {
        padding: 8em 1.875em 0em 1.875em;
    }

    .itilfoundation-land-container .hero-section .hero-left {
        width: 56%;
    }

    .itilfoundation-land-container .hero-section .hero-right {
        width: 44%;
    }

    .itilfoundation-land-container .hero-section .hero-left .hero-title {
        font-size: 1.7rem;
        line-height: 1.7rem;
    }

    .itilfoundation-land-container .hero-section .hero-left .hero-p {
        font-size: .85rem;
        width: 75%;
    }

    .itilfoundation-land-container .agitate-section {
        margin-top: 4em;
    }

    .itilfoundation-land-container .agitate-section .agitate-left .agitate-p {
        font-size: .85rem;
        line-height: 1.6rem;
        letter-spacing: .012rem;
    }

    .itilfoundation-land-container .section-title {
        font-size: 1.5rem;
    }

    .itilfoundation-land-container .solution-section .solution-right .solution-p {
        font-size: .85rem;
        line-height: 1.6rem;
        letter-spacing: .012rem;
    }

    .itilfoundation-land-container .cta-btn {
        border: 3px solid var(--black);
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream.dream2 img {
        height: 10em;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream img {
        height: 10em;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream .dream-h3 {
        font-size: 1.2rem;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream .dream-p {
        font-size: .85rem;
        line-height: 1.6rem;
        letter-spacing: .012rem;
    }
}


@media(min-width: 750px) {
    .itilfoundation-land-container .nav-container .land-cta-btn {
        font-size: .8rem;
    }

    .itilfoundation-land-container .hero-section .hero-right .video-container {
        height: 14em;
    }

    .itilfoundation-land-container .agitate-section {
        margin-top: 5em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.small-bucket {
        width: 10em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.small-bucket.small-bucket1 {
        top: -5em;
        right: 7em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.small-bucket.small-bucket2 {
        top: -1em;
        right: -2em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.big-bucket {
        width: 14em;
        top: -2em;
        right: 4em;
    }

    .itilfoundation-land-container .solution-section {
        margin-top: 5em;
    }

    .itilfoundation-land-container .solution-section {
        gap: 2em;
    }

    .itilfoundation-land-container .offer-section .offer-left img {
        width: 27em;
    }

    .itilfoundation-land-container .offer-section .offer-right .discount-h1 {
        font-size: 2rem;
    }

    .itilfoundation-land-container .offer-section .offer-right .offer-right-bottom .notices .notice-p {
        font-size: .7rem;
    }

    .itilfoundation-land-container .calendly-container.active .calendly-inline-widget {
        height: 60em !important;
    }
}

@media(min-width: 800px) {

    .itilfoundation-land-container .nav-container {
        padding: 1.2em 3.125em 0 3.125em;
    }

    .itilfoundation-land-container .nav-container.active {
        padding: 1.2em 2em 1.5em 2em;
    }

    .itilfoundation-land-container .nav-container .land-cta-btn {
        font-size: .9rem;
        border-radius: 2rem;
    }

    .itilfoundation-land-container .hero-section {
        padding: 9em 3.125em 0em 3.125em;
        gap: 1em;
    }

    .itilfoundation-land-container .hero-section .hero-left {
        gap: 1.2em;
    }

    .itilfoundation-land-container .hero-section .hero-left .hero-title {
        font-size: 2rem;
        line-height: 2rem;
    }

    .itilfoundation-land-container .hero-section .hero-left .hero-p {
        font-size: .9rem;
    }

    .itilfoundation-land-container .cta-btn {
        font-size: 1rem;
    }

    .itilfoundation-land-container .agitate-section {
        padding: 0 3.125em;
    }

    .itilfoundation-land-container .agitate-section .agitate-left .agitate-p {
        font-size: .9rem;
    }

    .itilfoundation-land-container .agitate-section .agitate-left .agitate-arrow {
        width: 2.5em;
    }

    .itilfoundation-land-container .agitate-section .agitate-left {
        margin-top: 1em;
    }

    .itilfoundation-land-container .solution-section {
        padding: 0 3.125em 0 0;
    }

    .itilfoundation-land-container .dream-section {
        padding: 0 3.125em;
    }

    .itilfoundation-land-container .offer-section {
        margin: 3em 3.125em 0em 3.125em;
    }

    .itilfoundation-land-container .cta-section {
        margin-top: 4em;
        padding: 0 0 0 3.125em;
    }

    .itilfoundation-land-container .cta-section .cta-left .cta-p {
        font-size: .85rem;
    }

    .itilfoundation-land-container .cta-section .cta-left .notices .notice-p {
        font-size: .75rem;
    }

    .itilfoundation-land-container .warning {
        padding: 2em 3.125em;
    }
}

@media(min-width: 1000px) {

    .itilfoundation-land-container .nav-container {
        padding: 1.2em 5em 0 5em;
    }

    .itilfoundation-land-container .nav-container.active {
        padding: 1.2em 4em 1.5em 4em;
    }

    .itilfoundation-land-container .hero-section {
        padding: 9em 5em 0em 5em;
    }

    .itilfoundation-land-container .hero-section .hero-left .hero-title {
        font-size: 2.4rem;
        line-height: 2.4rem;
    }

    .itilfoundation-land-container .hero-section .hero-left .hero-p {
        font-size: 1rem;
        line-height: 1.6rem;
    }

    .itilfoundation-land-container .cta-btn {
        font-size: 1.1rem;
    }

    .itilfoundation-land-container .hero-section .hero-right .video-container {
        height: 17em;
    }

    .itilfoundation-land-container .agitate-section {
        padding: 0 5em;
    }

    .itilfoundation-land-container .agitate-section .agitate-left .agitate-p {
        font-size: 1rem;
        line-height: 1.9rem;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.small-bucket {
        width: 12em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.big-bucket {
        width: 16em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.small-bucket.small-bucket2 {
        top: -2em;
        right: -3em;
    }

    .itilfoundation-land-container .agitate-section .agitate-left .agitate-arrow {
        width: 3em;
        margin-top: 2em;
    }

    .itilfoundation-land-container .section-title {
        font-size: 1.9rem;
        line-height: 1.8rem;
    }

    .itilfoundation-land-container .solution-section {
        padding: 0 5em 0 0;
    }

    .itilfoundation-land-container .solution-section .solution-right .solution-p {
        font-size: 1rem;
        line-height: 1.9rem;
    }

    .itilfoundation-land-container .solution-section .solution-left .img-container {
        width: 35vw;
    }

    .itilfoundation-land-container .dream-section {
        padding: 0 5em;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream.dream2 img {
        height: 13em;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream img {
        height: 13em;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream .dream-h3 {
        font-size: 1.5rem;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream .dream-p {
        font-size: 1rem;
        line-height: 1.9rem;
    }

    .itilfoundation-land-container .offer-section {
        margin: 4em 5em 0em 5em;
        padding: 3em 2em 3em 1em;
    }

    .itilfoundation-land-container .offer-section .offer-right .offer-h2 {
        font-size: 1.4rem;
    }

    .itilfoundation-land-container .offer-section .offer-right .bonuses {
        gap: 4em;
    }

    .itilfoundation-land-container .offer-section .offer-right .discount-h1 {
        font-size: 3rem;
    }

    .itilfoundation-land-container .offer-section .offer-right .offer-right-bottom .notices {
        gap: 3em;
    }

    .itilfoundation-land-container .offer-section .offer-right .offer-right-bottom .notices .notice-p {
        font-size: .9rem;
        letter-spacing: .02rem;
    }

    .itilfoundation-land-container .offer-section .offer-left img {
        width: 35em;
        top: -12em;
    }

    .itilfoundation-land-container .cta-section {
        padding: 0 0 0 5em;
    }

    .itilfoundation-land-container .cta-section .cta-left form .MuiInputLabel-root {
        font-size: .9rem;
    }

    .itilfoundation-land-container .cta-section .cta-left form .MuiInputBase-input {
        font-size: .9rem;
    }

    .itilfoundation-land-container .cta-section .cta-left form .cta-btn {
        margin-top: 1em;
    }

    .itilfoundation-land-container .warning {
        padding: 3em 5em 2em 5em;
    }

    .itilfoundation-land-container .warning .warning-p {
        font-size: .85rem;
        line-height: 1.5rem;
    }

    .itilfoundation-land-container .logo-footer img {
        width: 11em;
    }
}

@media(min-width: 1100px) {

    .itilfoundation-land-container .nav-container .land-cta-btn {
        font-size: 1rem;
    }

    .itilfoundation-land-container .cta-section .cta-left .cta-p {
        margin: 1.5rem 0;
    }

    .itilfoundation-land-container .cta-section .cta-left form {
        margin-top: 2em;
    }
}

@media(min-width: 1200px) {

    .itilfoundation-land-container .nav-container {
        padding: 1.2em 6.25em 0 6.25em;
    }

    .itilfoundation-land-container .nav-container.active {
        padding: 1.2em 4.8em 1.5em 4.8em;
    }

    .itilfoundation-land-container .hero-section {
        padding: 11em 6.25em 0em 6.25em;
    }

    .itilfoundation-land-container .hero-section .hero-right {
        width: 47%;
    }

    .itilfoundation-land-container .hero-section .hero-left {
        gap: 1.4em;
    }

    .itilfoundation-land-container .hero-section .hero-left .hero-title {
        font-size: 3.3rem;
        line-height: 3rem;
    }

    .itilfoundation-land-container .hero-section .hero-left .hero-p {
        font-size: 1.15rem;
        letter-spacing: .02rem;
        line-height: 1.7rem;
    }

    .itilfoundation-land-container .hero-section .hero-right .video-container {
        height: 23em;
    }

    .itilfoundation-land-container .cta-btn {
        font-size: 1.3rem;
        padding: .4rem 1rem;
    }

    .itilfoundation-land-container .agitate-section {
        padding: 0 6.25em;
    }

    .itilfoundation-land-container .agitate-section .agitate-left .agitate-p {
        font-size: 1.15rem;
        letter-spacing: .02rem;
        line-height: 2.2rem;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.small-bucket {
        width: 15em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.small-bucket.small-bucket1 {
        top: -5em;
        right: 10em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.small-bucket.small-bucket2 {
        right: -7em;
    }

    .itilfoundation-land-container .agitate-section .agitate-right img.big-bucket {
        width: 20em;
        top: -4em;
        right: 1em;
    }

    .itilfoundation-land-container .solution-section {
        padding: 0 6.25em 0 0;
    }

    .itilfoundation-land-container .section-title {
        font-size: 2.3rem;
        line-height: 2.2rem;
    }

    .itilfoundation-land-container .solution-section .solution-right {
        gap: 2em;
    }

    .itilfoundation-land-container .cta-btn {
        border: 4px solid var(--black);
    }

    .itilfoundation-land-container .solution-section .solution-right .solution-p {
        font-size: 1.15rem;
        letter-spacing: .02rem;
        line-height: 2.2rem;
    }

    .itilfoundation-land-container .dream-section {
        padding: 0 6.25em;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream.dream2 img {
        height: 16em;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream img {
        height: 16em;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream .dream-h3 {
        font-size: 1.8rem;
        margin: 1rem 0;
    }

    .itilfoundation-land-container .dream-section .dream-wrapper .dream .dream-p {
        font-size: 1.15rem;
        letter-spacing: .02rem;
        line-height: 2.2rem;
    }

    .itilfoundation-land-container .offer-section {
        padding: 3em 2em 3em 1em;
        margin: 3em 5em 0em 5em;
    }

    .itilfoundation-land-container .offer-section .offer-right {
        gap: 3em;
    }

    .itilfoundation-land-container .offer-section .offer-right .offer-h2 {
        font-size: 1.7rem;
    }

    .itilfoundation-land-container .offer-section .offer-right .offer-right-bottom .notices {
        margin-top: 1.5em;
    }

    .itilfoundation-land-container .offer-section .offer-right .offer-right-bottom .notices .notice-p {
        font-size: 1.1rem;
    }

    .itilfoundation-land-container .offer-section .offer-right .discount-h1 {
        font-size: 3.5rem;
    }

    .itilfoundation-land-container .offer-section .offer-left img {
        width: 42em;
        top: -13em;
        left: -9em;
    }

    .itilfoundation-land-container .cta-section {
        padding: 0 0 0 6.25em;
        margin-top: 6em;
        gap: 4em;
    }

    .itilfoundation-land-container .cta-section .cta-left .cta-p {
        font-size: 1.15rem;
        letter-spacing: .02rem;
        line-height: 1.8rem;
    }

    .itilfoundation-land-container .cta-section .cta-left form {
        gap: .8em;
    }

    .itilfoundation-land-container .cta-section .cta-left form .MuiInputBase-input {
        font-size: 1.2rem;
    }

    .itilfoundation-land-container .cta-section .cta-left form .MuiInputLabel-root {
        font-size: 1.1rem;
    }

    .itilfoundation-land-container .cta-section .cta-left .notices .notice-p {
        font-size: 1rem;
    }

    .itilfoundation-land-container .cta-section .cta-right {
        width: 50%;
    }

    .itilfoundation-land-container .warning {
        padding: 3em 6.25em 2em 6.25em;
    }

    .itilfoundation-land-container .warning .warning-p {
        font-size: 1.15rem;
        letter-spacing: .02rem;
        line-height: 1.8rem;
    }

    .itilfoundation-land-container .logo-footer {
        padding: 2em;
    }

    .itilfoundation-land-container .logo-footer img {
        width: 13em;
    }
    
}



@media(min-width: 1440px) {
    .itilfoundation-land-container .nav-container.active {
        left: 3em;
        right: 3em;
        padding: 1.2em 3em 1.5em 3em;
    }

}

@media(min-width: 1451px) {
    .itilfoundation-land-container .nav-container.active {
        padding: 1.2em 6.3em 1.5em 6.3em;
    }
}

/* MEDIA QUERIES END */